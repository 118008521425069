<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <mat-icon fontSet="mdi" fontIcon="mdi-send"></mat-icon>
    </div>

    <h2 mat-dialog-title translate="explorer.modality_tools.sendStudies"></h2>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>
<form novalidate #form="ngForm" (submit)="submit()">
    <div mat-dialog-content fxLayout="column" fxLayoutGap="10px">

        <STRONG translate="explorer.modality_tools.sendDescription" [translateParams]="{length: resources.length}"></STRONG>
        <mat-form-field appearance="fill">
            <mat-label translate="explorer.modality_tools.selectModality"></mat-label>
            <ft-select required [placeholder]="'explorer.modality_tools.selectModality' | translate" [multiple]="true"
                       [config]="modalitiesSelectConfig" [(ngModel)]="data.remoteModalities" name="modalities">
            </ft-select>
        </mat-form-field>

        <ng-container *ngFor="let modality of data.remoteModalities">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title><strong>{{modality.AET}}</strong></mat-panel-title>
                        <mat-panel-description>
                            <strong>{{modality.Host}}:{{modality.Port}}</strong>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div fxLayout="row" fxLayoutGap="12px">
                        <mat-slide-toggle
                                [checked]="modality.AllowEcho">{{'settings.allow_echo' | translate}}</mat-slide-toggle>
                        <mat-slide-toggle
                                [checked]="modality.AllowFind">{{'settings.allow_find' | translate}}</mat-slide-toggle>
                        <mat-slide-toggle
                                [checked]="modality.AllowMove">{{'settings.allow_move' | translate}}</mat-slide-toggle>
                        <mat-slide-toggle
                                [checked]="modality.AllowStore">{{'settings.allow_store' | translate}}</mat-slide-toggle>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

        </ng-container>

    </div>
    <div mat-dialog-actions>
        <button mat-raised-button type="submit" tabindex="2" color="primary" [disabled]="form.invalid">
            <span translate="common.validate"></span>
        </button>
        <button mat-raised-button mat-dialog-close tabindex="-1" color="warn">
            <span translate="common.cancel"></span>
        </button>
    </div>
</form>


