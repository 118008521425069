<div class="viewport" #viewport oncontextmenu="return false"
     unselectable="on" onselectstart="return false;"
     onmousedown="return false;" [class.with-scrollbar]="overlayConf.stackSize > 1 && !printer">
</div>

<div *ngIf="isLoading && imageProgress < 100" class="loading-indicator" translate="viewer.loading"
     [translateParams]="{percentage: imageProgress}">
</div>

<div *ngIf="!overlayConf?.imageId && !isLoading" class="loading-indicator no-image-selected" translate="viewer.please_select_series"></div>

<ftp-viewport-scrollbar *ngIf="overlayConf.stackSize > 1 && !printer" [value]="overlayConf.imageIndex"
                        [max]="overlayConf.stackSize" (indexChange)="imageSliderChange($event)">
</ftp-viewport-scrollbar>
