import {TableColumnDefinition} from '@ft/core';
import {MODEL_GROUP_COLUMNS} from '@ft/office';

const siteColumn: TableColumnDefinition = {label: 'settings.site', key: 'site.name', type: 'text'};

export const DEPARTMENT_COLUMNS: TableColumnDefinition[] = [
    {label: 'settings.department.name', key: 'name', type: 'text'},
];

export const SERVICE_COLUMNS: TableColumnDefinition[] = [
    {label: 'settings.service.name', key: 'name', type: 'text'},
    {label: 'settings.service.department', key: 'department.name', type: 'text'},
];

export const MODALITY_COLUMNS: TableColumnDefinition[] = [
    {label: 'settings.title', key: 'AET', type: 'text'},
    {label: 'settings.host', key: 'Host', type: 'text'},
    {label: 'settings.port', key: 'Port', type: 'text'},
    {label: 'settings.allow_echo', key: 'AllowEcho', type: 'boolean'},
    {label: 'settings.allow_find', key: 'AllowFind', type: 'boolean'},
    {label: 'settings.allow_move', key: 'AllowMove', type: 'boolean'},
    {label: 'settings.allow_store', key: 'AllowStore', type: 'boolean'},
];

export const IAN_NODE_COLUMNS: TableColumnDefinition[] = [
    {label: 'settings.title', key: 'title', type: 'text'},
    {label: 'settings.host', key: 'host', type: 'text'},
    {label: 'settings.port', key: 'port', type: 'text'},
    siteColumn,
];

export const AUTO_ROUTING_COLUMNS: TableColumnDefinition[] = [
    {label: 'settings.title', key: 'title', type: 'text'},
    {label: 'settings.host', key: 'host', type: 'text'},
    {label: 'settings.port', key: 'port', type: 'text'},
    {label: 'settings.auto_routing.modalities_type', key: 'labelModalities', type: 'text'},
    {label: 'settings.auto_routing.keep_resource', key: 'keep_resource', type: 'boolean'},
    siteColumn,
];

export const MODEL_GROUP_COLUMNS_EXT: TableColumnDefinition[] = [
    ...MODEL_GROUP_COLUMNS,
    siteColumn,
];

// burner related
export const BURNER_COLUMNS: TableColumnDefinition[] = [
    {label: 'settings.burner.title', key: 'title', type: 'text'},
    {
        type: 'time',
        key: 'trigger_time',
        label: 'settings.burner.trigger_time',
        timeFormats: ['HH:mmZZ', 'HH:mm:ssZZ']
    },
    {label: 'settings.burner.local_directory', key: 'local_directory', type: 'text'},
    {label: 'settings.burner.remote_directory', key: 'remote_directory', type: 'text'},
    siteColumn,
];

export const BURNER_CURRENT_JOBS: TableColumnDefinition[] = [
    {label: 'settings.burner.burner_title', key: 'robot', type: 'text'},
    {label: 'settings.burner.job_patient', key: 'patientName', type: 'text'},
    {label: 'settings.burner.job_exam', key: 'studyDescription', type: 'text'},
    {label: 'settings.burner.job_exam_date', key: 'studyDateIso', type: 'datetime'},
    {
        label: 'settings.burner.job_status', key: 'statusTranslated',
        type: 'badge', class: item => item.status
    },
    {label: 'settings.burner.job_created_at', key: 'created_at', type: 'datetime'},
    siteColumn,
];

export const BURNER_SCP_RULES_COLUMNS: TableColumnDefinition[] = [
    {label: 'settings.burner.rule_aet', key: 'aet', type: 'text'},
    {label: 'settings.burner.burner_name', key: 'burner.title', type: 'text'},
    siteColumn,
];


// archive related
export const ARCHIVE_TASK_COLUMNS: TableColumnDefinition[] = [
    {label: 'settings.title', key: 'title', type: 'text'},
    {label: 'settings.archive.directory', key: 'directory', type: 'text'},
    {
        label: 'settings.archive.trigger_time',
        key: 'trigger_time',
        type: 'time',
        timeFormats: ['HH:mmZZ', 'HH:mm:ssZZ']
    },
    {label: 'settings.archive.is_active', key: 'is_active', type: 'boolean'},
    {label: 'settings.archive.used_space', key: 'hdd_percent', type: 'progress'},
    siteColumn,
];

export const ARCHIVE_COLUMNS: TableColumnDefinition[] = [
    {label: 'settings.archive.patient_id', key: 'patient_id', type: 'text'},
    {label: 'settings.archive.patient_name', key: 'patient_name', type: 'text'},
    {label: 'settings.archive.study_date', key: 'study_date', type: 'date'},
    {label: 'settings.archive.study_description', key: 'study_description', type: 'text'},
    {label: 'settings.title', key: 'task.title', type: 'text'},
    {label: 'settings.archive.directory', key: 'task.directory', type: 'text'},
    {label: 'settings.archive.size', key: 'file_size', type: 'file-size'},
    {label: 'settings.archive.archived_at', key: 'archived_at', type: 'datetime'},
    {label: 'settings.archive.modality', key: 'modality', type: 'badge'},
    {label: 'settings.archive.institution_name', key: 'task.site.name', type: 'text'}
];

// print related
export const PRINTER_COLUMNS: TableColumnDefinition[] = [
    {label: 'settings.printer.title', key: 'description', type: 'text'},
    {label: 'settings.printer.aeTitle', key: 'title', type: 'text'},
    {label: 'settings.printer.host', key: 'host', type: 'text'},
    {label: 'settings.printer.port', key: 'port', type: 'text'},
    siteColumn,
];

export const PRINT_SCP_RULES_COLUMNS: TableColumnDefinition[] = [
    {label: 'settings.print_scp.aet', key: 'aet', type: 'text'},
    {label: 'settings.print_scp.printer_name', key: 'printer.label', type: 'text'},
    {label: 'settings.print_scp.booklet', key: 'booklet', type: 'boolean'},
    {label: 'settings.print_scp.with_cover', key: 'with_cover', type: 'boolean'},
    siteColumn,
];
