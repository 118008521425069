import {REPORT_STATUS} from './enums';
import {ReportStatus} from './models';

export declare type EditorMode = 'view' | 'edit';

interface FieldInterface {
    name: string;
    label: string;
    type: 'text' | 'sexIcons' | 'date' | 'time' | 'number';
}

export const PATIENT_FIELDS: FieldInterface[] = [
    {label: 'explorer.study.patient_id', name: 'PatientID', type: 'text'},
    {label: 'explorer.study.patient_name', name: 'PatientName', type: 'text'},
    {label: 'explorer.study.patient_sex', name: 'PatientSex', type: 'sexIcons'},
    {label: 'explorer.study.patient_birth_date', name: 'PatientBirthDate', type: 'date'},
    {label: 'explorer.study.patient_studies', name: 'PatientStudies', type: 'number'}
];

export const STUDY_FIELDS: FieldInterface[] = [
    {label: 'explorer.study.study_id', name: 'StudyID', type: 'text'},
    {label: 'explorer.study.accession_number', name: 'AccessionNumber', type: 'text'},
    {label: 'explorer.study.study_date', name: 'StudyDate', type: 'date'},
    {label: 'explorer.study.study_time', name: 'StudyTime', type: 'time'},
    {label: 'explorer.study.referring_physician_name', name: 'ReferringPhysicianName', type: 'text'},
    {label: 'explorer.study.institution_name', name: 'InstitutionName', type: 'text'}
];

export const SERIES_FIELDS: FieldInterface[] = [
    {label: 'explorer.series.operators_name', name: 'OperatorsName', type: 'text'},
    {label: 'explorer.series.manufacturer', name: 'Manufacturer', type: 'text'},
    {label: 'explorer.series.station_name', name: 'StationName', type: 'text'},
    {label: 'explorer.series.series_number', name: 'SeriesNumber', type: 'text'},
    {label: 'explorer.series.modality', name: 'Modality', type: 'text'},
    {label: 'explorer.series.series_date', name: 'SeriesDate', type: 'date'},
    {label: 'explorer.series.series_time', name: 'SeriesTime', type: 'time'}
];

export const REPORT_STATUS_FIELDS: ReportStatus[] = [
    {
        value: REPORT_STATUS.PENDING,
        tooltip: 'explorer.report.pending',
        icon: 'mdi-file-outline',
        color: 'rgb(237 180 18)'
    },
    {
        value: REPORT_STATUS.IN_PROGRESS,
        tooltip: 'explorer.report.inProgress',
        icon: 'mdi-file-clock',
        color: 'rgb(233 123 29)'
    },
    {
        value: REPORT_STATUS.TO_VALIDATE,
        tooltip: 'explorer.report.toValidate',
        icon: 'mdi-file-search',
        color: 'rgb(37 130 223)'
    },
    {
        value: REPORT_STATUS.TO_SIGN,
        tooltip: 'explorer.report.toSign',
        icon: 'mdi-file-sign',
        color: 'rgb(230, 42, 93)'
    },
    {
        value: REPORT_STATUS.VALIDATED,
        tooltip: 'explorer.report.validated',
        icon: 'mdi-file-star',
        color: 'rgb(22, 145, 52)'
    },
    {
        value: REPORT_STATUS.SIGNED,
        tooltip: 'explorer.report.signed',
        icon: 'mdi-file-check',
        color: 'rgb(202, 17, 81)'
    },
];

export const DEFAULT_STATUS: ReportStatus = REPORT_STATUS_FIELDS[0];

export const EDIT_REPORT_STATUS: ReportStatus[] = [
        {
            value: REPORT_STATUS.VALIDATED,
            label: 'explorer.report.validate',
            icon: 'mdi-file-certificate',
            color: 'rgb(185 221 156)'
        },
        {
            value: REPORT_STATUS.SIGNED,
            label: 'explorer.report.sign',
            icon: 'mdi-file-check',
            color: 'rgb(243 136 173)'
        },
        {
            value: REPORT_STATUS.TO_VALIDATE,
            label: 'explorer.report.toValidate',
            icon: 'mdi-check',
            color: 'rgb(199 236 255)'
        },
        {
            value: REPORT_STATUS.TO_SIGN,
            label: 'explorer.report.toSign',
            icon: 'mdi-gesture',
            color: 'rgb(199 236 255)'
        },
    ];
