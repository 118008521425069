export const lang = {

    login: {
        site: 'Site',
        error: 'Login ou mot de passe ou site incorrect',
    },

    staff: {
        has_all_sites: 'Accès à tous les sites',
        department: 'Département',
    }
};
