import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {debounce} from 'lodash';
import {MatSliderChange} from '@angular/material/slider';

@Component({
    selector: 'ftp-viewport-scrollbar',
    templateUrl: './viewport-scrollbar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        './viewport-scrollbar.component.scss'
    ]
})
export class ViewportScrollbarComponent {
    @Input() public max: number;
    @Input() public value: number;

    @Output() indexChange: EventEmitter<number> = new EventEmitter<number>();

    public debouncedSetImage = debounce((ev: MatSliderChange) => this.indexChange.emit(ev.value), 100);
}
