import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {NgBusyModule} from 'ng-busy';
import {TranslateModule} from '@ngx-translate/core';
import {FtFileManagerSharedModule, FtVisualizerModule} from '@ft/file-manager';
import {FtAuthModule, FtCoreModule, MaterialModule} from '@ft/core';

import {ViewerContainerComponent} from './components/viewer-container/viewer-container.component';
import {ViewportComponent} from './components/viewport/viewport.component';
import {ViewportScrollbarComponent} from './components/viewport-scrollbar/viewport-scrollbar.component';
import {initializeTools} from './utils/initialize-tools';
import {initializeLoader} from './utils/intialize-loader';
import {ViewerService} from './services/viewer.service';
import {ViewerSeriesComponent} from './components/viewer-series/viewer-series.component';
import {ViewerToolbarComponent} from './components/viewer-toolbar/viewer-toolbar.component';
import {ToolsService} from './services/tools.service';
import {PrintContainerComponent} from './components/print-container/print-container.component';
import {PrintOptionsComponent} from './components/print-options/print-options.component';
import {PrintService} from './services/print.service';
import {PrintOverlayComponent} from './components/print-overlay/print-overlay.component';
import {SeriesPrintDialog} from './dialogs/series-print/series-print.dialog';
import {ViewerPasswordPromptDialog} from './dialogs/viewer-password-prompt/viewer-password-prompt.dialog';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgBusyModule,
        RouterModule,
        TranslateModule.forChild(),
        FtCoreModule,
        FtAuthModule,
        MaterialModule,
        FtFileManagerSharedModule,
        FtVisualizerModule
    ],
    providers: [
        ToolsService,
        PrintService,
        ViewerService,
        {
            // fix scroll issue with viewer toolbar
            // https://github.com/angular/components/issues/4892
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {touchGestures: 'off'}
        },
    ],
    declarations: [
        ViewerContainerComponent,
        ViewportComponent,
        ViewportScrollbarComponent,
        ViewerSeriesComponent,
        ViewerToolbarComponent,

        PrintContainerComponent,
        PrintOptionsComponent,
        PrintOverlayComponent,
        SeriesPrintDialog,
        ViewerPasswordPromptDialog
    ]
})
export class FtpViewerModule {
    constructor() {
        initializeTools();
        initializeLoader();
    }
}
