import * as cornerstoneTools from 'cornerstone-tools';

export const IMAGE_TYPE = 'image/jpeg';

export const TOOL_MODE_FUNCTIONS = {
    active: cornerstoneTools.setToolActiveForElement,
    passive: cornerstoneTools.setToolPassiveForElement,
    enabled: cornerstoneTools.setToolEnabledForElement,
    disabled: cornerstoneTools.setToolDisabledForElement,
};


// overlay related
export const TEXT_PADDING = 5; // padding defined by cornerstone;

export const TOP_LEFT_KEYS = [
    'patientName', 'patientId'
];

export const TOP_RIGHT_KEYS = [
    'institution', 'studyDescription', 'contentDateTime'
];

export const BOTTOM_LEFT_KEYS = [
    'seriesNumber', 'imageDimensions', 'stack', 'frameRate', 'slice', 'seriesDescription'
];

export const BOTTOM_RIGHT_KEYS = [
    'zoomPercentage', 'window', 'compression'
];
