import {assign, get} from 'lodash';
import * as dicomParser from 'dicom-parser';

import * as cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import {formatMoment, formatPN} from './helpers';
import {NAIVE_DATE_FORMAT} from '@ft/core';

const {parseImageId, dataSetCacheManager, metaData} = cornerstoneWADOImageLoader.wadouri;

const DEFAULT_PIXEL_SPACING = [0.2, 0.2];

export function wadoUriMetaDataProvider(type, imageId) {
    const parsedImageId = parseImageId(imageId);
    const dataSet = dataSetCacheManager.get(parsedImageId.url);

    if (!dataSet) return;

    if (type === 'generalImageModule') {
        return {
            instanceNumber: dataSet.intString('x00200013'),
            lossyImageCompression: dataSet.string('x00282110'),
            lossyImageCompressionRatio: dataSet.string('x00282112'),
            lossyImageCompressionMethod: dataSet.string('x00282114'),
        };
    }

    if (type === 'patientModule') {
        return {
            patientId: dataSet.string('x00100020'),
            patientName: formatPN(dataSet.string('x00100010')),
        };
    }

    if (type === 'generalStudyModule') {
        const studyDate = dataSet.string('x00080020');
        const studyTime = dataSet.string('x00080030');
        const studyDateTime = `${formatMoment(studyDate, 'YYYYMMDD', NAIVE_DATE_FORMAT)} ${formatMoment(studyTime, ['HHmmss', 'HH:mm:ss'], 'HH:mm:ss')}`;

        return {
            studyDescription: dataSet.string('x00081030'),
            studyDate, studyTime, studyDateTime
        };
    }

    if (type === 'contentModule') {
        const contentDate = dataSet.string('x00080023');
        const contentTime = dataSet.string('x00080033');
        const contentDateTime = `${formatMoment(contentDate, 'YYYYMMDD', NAIVE_DATE_FORMAT)} ${formatMoment(contentTime, ['HHmmss', 'HH:mm:ss'], 'HH:mm:ss')}`;

        return {
            contentDate, contentTime, contentDateTime
        };
    }

    if (type === 'cineModule') {
        const frameTime = dataSet.float('x00181063');
        const frameRate = 1000 / frameTime;

        return {
            frameTime, frameRate
        };
    }

    if (dataSet.elements[type] !== undefined) {
        const element = dataSet.elements[type];
        if (!element.vr) {
            return;
        }

        return dicomParser.explicitElementToString(dataSet, element);
    }
}

function getUltraSoundPixelSpacing(dataSet) {
    const ultrasoundRegions = get(dataSet, 'elements.x00186011.items.0.dataSet');

    if (!ultrasoundRegions) return;

    const physicalDeltaX = ultrasoundRegions.double('x0018602c') * 10;
    const physicalDeltaY = ultrasoundRegions.double('x0018602e') * 10;

    // physicalDeltaY can be negative value => the browser freezes
    // to avoid having the browser blocked or the image resized and still have the scaleOverlay;
    // we can return DEFAULT_PIXEL_SPACING (= [0.2, 0.2])
    if (physicalDeltaY > 0 && physicalDeltaX === physicalDeltaY) {
        return [
            physicalDeltaX, physicalDeltaY
        ];
    } else {
        return DEFAULT_PIXEL_SPACING;
    }
}


function getPixelSpacing(imageId) {
    const parsedImageId = parseImageId(imageId);
    const dataset = dataSetCacheManager.get(parsedImageId.url);

    if (!dataset) return {};

    let pixelSpacing = metaData.getNumberValues(dataset, 'x00280030', 2);

    const USPixelSpacing = getUltraSoundPixelSpacing(dataset);
    const imagePixelSpacing = metaData.getNumberValues(dataset, 'x00181164', 2);
    const nominalScannedPixelSpacing = metaData.getNumberValues(dataset, 'x00182010', 2);
    const ultrasoundRegionPixelSpacing = metaData.getNumberValues(dataset, 'x00186011', 2);

    // mri specific siemens
    const MRIPixelSpacingSet = get(dataset, 'elements.x52009230.items.0.dataSet.elements.x00289110.items.0.dataSet');
    const MRIPixelSpacing = MRIPixelSpacingSet ? metaData.getNumberValues(MRIPixelSpacingSet, 'x00280030', 2) : null;

    let rowPixelSpacing = null;
    let columnPixelSpacing = null;

    if (USPixelSpacing) pixelSpacing = USPixelSpacing;
    if (MRIPixelSpacing) pixelSpacing = MRIPixelSpacing;
    if (imagePixelSpacing) pixelSpacing = imagePixelSpacing;
    if (nominalScannedPixelSpacing) pixelSpacing = nominalScannedPixelSpacing;
    if (ultrasoundRegionPixelSpacing) pixelSpacing = ultrasoundRegionPixelSpacing;

    // to always have the overlayScale;
    // if (!pixelSpacing) pixelSpacing = DEFAULT_PIXEL_SPACING;
    if (pixelSpacing) {
        rowPixelSpacing = pixelSpacing[0];
        columnPixelSpacing = pixelSpacing[1];
    }

    return {pixelSpacing, rowPixelSpacing, columnPixelSpacing};
}

export function wadoUriPixelSpacingProvider(type, imageId) {
    if (type === 'imagePlaneModule') return assign(metaData.metaDataProvider(type, imageId), getPixelSpacing(imageId));
    return metaData.metaDataProvider(type, imageId);
}
