export const lang = {

    login: {
        site: 'Site',
        error: 'Username or password or site is incorrect',
    },

    staff: {
        has_all_sites: 'Access to all sites',
        department: 'Department',
    }
};
