import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard, ExpirationPageComponent, LoggedGuard} from '@ft/core';

import {FtpMainComponent} from './app.component';
import {ViewerContainerComponent} from './viewer/components/viewer-container/viewer-container.component';
import {ArchivesComponent} from './settings/components/archives/archives.component';
import {DcmUploadComponent} from './settings/components/dcm-upload/dcm-upload.component';
import {EditReportComponent} from './explorer/components/edit-report/edit-report.component';
import {ReportsListComponent} from './explorer/components/reports-list/reports-list.component';
import {LoginChildComponent} from './authentication/components/login-child/login-child.component';

const routes: Routes = [
    {
        path: '', pathMatch: 'full', redirectTo: '/explorer/studies',
    },
    {
        path: 'login',
        canActivate: [LoggedGuard],
        component: LoginChildComponent,
        data: {logo: '/assets/logo.png'}
    },
    {
        path: '',
        canActivate: [AuthGuard],
        component: FtpMainComponent,
        children: [
            {
                path: 'explorer',
                loadChildren: () => import('./explorer/explorer-routing.module').then(m => m.ExplorerRoutingModule)
            },
            {
                path: 'settings',
                loadChildren: () => import('./settings/settings-routing.module').then(m => m.SettingsRoutingModule)
            },
            {
                path: 'archives',
                component: ArchivesComponent
            },
            {
                path: 'instances-upload',
                component: DcmUploadComponent,
            },
            {
                path: 'reports',
                component: ReportsListComponent,
            },
            {
                path: 'reports/edit-report',
                component: EditReportComponent,
            },
        ]
    },
    {
        path: 'viewer',
        canActivate: [AuthGuard],
        component: ViewerContainerComponent
    },
    {
        path: 'only-viewer',
        component: ViewerContainerComponent
    },
    {
        // share link for external access to studies
        // secured by password

        path: 'share',
        component: ViewerContainerComponent
    },
    {
        path: 'expiration',
        component: ExpirationPageComponent,
        data: {logo: '/assets/logo.png'}
    },
    {
        path: '**', pathMatch: 'full', redirectTo: '/explorer/studies'
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
