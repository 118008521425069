<mat-toolbar>
    <!--    <button mat-button class="menu-button" (click)="handleSideNave()" [class.active]="isOpen">
            <mat-icon fontSet="mdi" fontIcon="mdi-menu"></mat-icon>
            <span class="label">Stack</span>
        </button>-->

    <div class="logo">
        <img src="/assets/fireview.png" alt="" class="large">
        <img src="/assets/fire-view-small.png" alt="" class="small">
    </div>


    <button mat-button [matTooltip]="'viewer.tools.stack_scroll' | translate" (click)="setActiveTool('StackScroll')"
            [class.active]="activeToolName === 'StackScroll'">
        <mat-icon fontSet="mdi" fontIcon="mdi-layers"></mat-icon>
        <span class="label">{{'viewer.tools.stack_scroll' | translate}}</span>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.zoom' | translate" (click)="setActiveTool('Zoom')"
            [class.active]="activeToolName === 'Zoom'">
        <mat-icon fontSet="mdi" fontIcon="mdi-magnify-plus-outline"></mat-icon>
        <span class="label">{{'viewer.tools.zoom' | translate}}</span>
    </button>

    <button mat-button [matTooltip]="'viewer.tools.contrast' | translate" (click)="setActiveTool('Wwwc')"
            [class.active]="activeToolName === 'Wwwc'">
        <mat-icon fontSet="mdi" fontIcon="mdi-circle-half-full"></mat-icon>
        <span class="label">{{'viewer.tools.contrast' | translate}}</span>
    </button>

    <button mat-button [matTooltip]="'viewer.tools.roi_window' | translate" (click)="setActiveTool('WwwcRegion')"
            [class.active]="activeToolName === 'WwwcRegion'">
        <mat-icon fontSet="mdi" fontIcon="mdi-contrast-box"></mat-icon>
        <span class="label">{{'viewer.tools.roi_window' | translate}}</span>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.pan' | translate" (click)="setActiveTool('Pan')"
            [class.active]="activeToolName === 'Pan'">
        <mat-icon fontSet="mdi" fontIcon="mdi-hand-back-right"></mat-icon>
        <span class="label">{{'viewer.tools.pan' | translate}}</span>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.magnify' | translate" (click)="setActiveTool('Magnify')"
            [class.active]="activeToolName === 'Magnify'">
        <mat-icon fontSet="mdi" fontIcon="mdi-magnify-expand"></mat-icon>
        <span class="label">{{'viewer.tools.magnify' | translate}}</span>
    </button>


    <button mat-button [matTooltip]="'viewer.tools.length_measurement' | translate" (click)="setActiveTool('Length')"
            [class.active]="activeToolName === 'Length'">
        <mat-icon fontSet="mdi" fontIcon="mdi-vector-line"></mat-icon>
        <span class="label">{{'viewer.tools.length_measurement' | translate}}</span>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.arrow_annotate' | translate" (click)="setActiveTool('ArrowAnnotate')"
            [class.active]="activeToolName === 'ArrowAnnotate'">
        <mat-icon fontSet="mdi" fontIcon="mdi-format-annotation-plus"></mat-icon>
        <span class="label">{{'viewer.tools.arrow_annotate' | translate}}</span>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.angle_measurement' | translate" (click)="setActiveTool('Angle')"
            [class.active]="activeToolName === 'Angle'">
        <mat-icon fontSet="mdi" fontIcon="mdi-angle-acute"></mat-icon>
        <span class="label">{{'viewer.tools.angle_measurement' | translate}}</span>
    </button>

    <button mat-button [matTooltip]="'viewer.tools.elliptical_roi' | translate" (click)="setActiveTool('EllipticalRoi')"
            [class.active]="activeToolName === 'EllipticalRoi'">
        <mat-icon fontSet="mdi" fontIcon="mdi-ellipse-outline"></mat-icon>
        <span class="label">{{'viewer.tools.elliptical_roi' | translate}}</span>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.rectangle_roi' | translate" (click)="setActiveTool('RectangleRoi')"
            [class.active]="activeToolName === 'RectangleRoi'">
        <mat-icon fontSet="mdi" fontIcon="mdi-crop-square"></mat-icon>
        <span class="label">{{'viewer.tools.rectangle_roi' | translate}}</span>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.pixel_probe' | translate" (click)="setActiveTool('Bidirectional')"
            [class.active]="activeToolName === 'Bidirectional'">
        <mat-icon fontSet="mdi" fontIcon="mdi-eyedropper-variant"></mat-icon>
        <span class="label">{{'viewer.tools.pixel_probe' | translate}}</span>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.freehand' | translate" (click)="setActiveTool('DragProbe')"
            [class.active]="activeToolName === 'DragProbe'">
        <mat-icon fontSet="mdi" fontIcon="mdi-gesture"></mat-icon>
        <span class="label">{{'viewer.tools.freehand' | translate}}</span>
    </button>
    <button mat-button [matTooltip]="'viewer.tools.eraser' | translate" (click)="setActiveTool('Eraser')"
            [class.active]="activeToolName === 'Eraser'">
        <mat-icon fontSet="mdi" fontIcon="mdi-eraser"></mat-icon>
        <span class="label">{{'viewer.tools.eraser' | translate}}</span>
    </button>


    <button mat-button [matTooltip]="'viewer.tools.crosshairs' | translate" (click)="setActiveTool('Crosshairs')"
            [class.active]="activeToolName === 'Crosshairs'">
        <mat-icon fontSet="mdi" fontIcon="mdi-crosshairs-gps"></mat-icon>
        <span class="label">{{'viewer.tools.crosshairs' | translate}}</span>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.viewport_scale_overlay' | translate"
            (click)="updateScaleOverlay()" [class.active]="scaleOverlay">
        <mat-icon fontSet="mdi" fontIcon="mdi-ruler-square" class="mdi-rotate-180"></mat-icon>
        <span class="label">{{'viewer.tools.viewport_scale_overlay' | translate}}</span>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.viewports_reference_lines' | translate"
            (click)="updateReferenceLines()" [class.active]="referenceLines">
        <mat-icon fontSet="mdi" fontIcon="mdi-arrow-split-horizontal"></mat-icon>
        <span class="label">{{'viewer.tools.viewports_reference_lines' | translate}}</span>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.synchronize_contrast' | translate"
            (click)="enableSynchronizer()" [class.active]="wwccSynchronizer">
        <mat-icon fontSet="mdi" fontIcon="mdi-vector-link"></mat-icon>
        <span class="label">{{'viewer.tools.synchronize_contrast' | translate}}</span>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.synchronize_pan_zoom' | translate"
            (click)="enablePanZoomSynchronizer()" [class.active]="isPanZoomSynchronizerEnable">
        <mat-icon fontSet="mdi" fontIcon="mdi-link-variant"></mat-icon>
        <span class="label">{{'viewer.tools.synchronize_pan_zoom' | translate}}</span>
    </button>


    <button mat-button class="bright" [matTooltip]="'viewer.tools.reset' | translate" (click)="resetViewport()">
        <mat-icon fontSet="mdi" fontIcon="mdi-backup-restore"></mat-icon>
        <span class="label">{{'viewer.tools.reset' | translate}}</span>
    </button>
    <button mat-button class="bright"
            [matTooltip]="(state?.playing ? 'viewer.tools.stop' : 'viewer.tools.play') | translate"
            (click)="setState('playing', !state.playing)">
        <mat-icon fontSet="mdi" [fontIcon]="state?.playing ? 'mdi-stop' : 'mdi-play'"></mat-icon>
        <span class="label">{{(state?.playing ? 'viewer.tools.stop' : 'viewer.tools.play') | translate}}</span>
    </button>

    <button mat-button class="bright" [matTooltip]="'viewer.tools.invert' | translate"
            (click)="setState('invert', !state.invert)" [class.active]="!state?.invert">
        <mat-icon fontSet="mdi" fontIcon="mdi-invert-colors"></mat-icon>
        <span class="label">{{'viewer.tools.invert' | translate}}</span>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.rotate_left' | translate"
            (click)="setState('rotation', state.rotation - 90)">
        <mat-icon fontSet="mdi" fontIcon="mdi-rotate-left-variant"></mat-icon>
        <span class="label">{{'viewer.tools.rotate_left' | translate}}</span>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.rotate_right' | translate"
            (click)="setState('rotation', state.rotation + 90)">
        <mat-icon fontSet="mdi" fontIcon="mdi-rotate-right-variant"></mat-icon>
        <span class="label">{{'viewer.tools.rotate_right' | translate}}</span>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.h_flip' | translate"
            (click)="setState('hflip', !state.hflip)" [class.active]="state?.hflip">
        <mat-icon fontSet="mdi" fontIcon="mdi-reflect-horizontal"></mat-icon>
        <span class="label">{{'viewer.tools.h_flip' | translate}}</span>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.v_flip' | translate"
            (click)="setState('vflip', !state.vflip)" [class.active]="state?.vflip">
        <mat-icon fontSet="mdi" fontIcon="mdi-reflect-vertical"></mat-icon>
        <span class="label">{{'viewer.tools.v_flip' | translate}}</span>
    </button>
    <button mat-button class="bright" [matTooltip]="'viewer.tools.download' | translate" (click)="download()">
        <mat-icon fontSet="mdi" fontIcon="mdi-download"></mat-icon>
        <span class="label">{{'viewer.tools.download' | translate}}</span>
    </button>


    <button mat-button class="bright" [matTooltip]="'viewer.tools.layouts' | translate"
            [matMenuTriggerFor]="layoutsMenu">
        <mat-icon fontSet="mdi" fontIcon="mdi-view-grid"></mat-icon>
        <span class="label">{{'viewer.tools.layouts' | translate}}</span>
    </button>

    <button mat-button class="bright" [matTooltip]="'viewer.tools.fps' | translate" [matMenuTriggerFor]="fpsMenu">
        <mat-icon fontSet="mdi" fontIcon="mdi-animation-play"></mat-icon>
        <span class="label">{{'viewer.tools.fps' | translate}}</span>
    </button>

    <button mat-button [matTooltip]="'viewer.tools.merge' | translate" (click)="mergeSeries()"
            [disabled]="checkedSeries.length<2">
        <mat-icon fontSet="mdi" fontIcon="mdi-set-merge"></mat-icon>
        <span class="label">{{'viewer.tools.merge' | translate}}</span>
    </button>

    <button mat-button [matTooltip]="'viewer.tools.split' | translate" (click)="splitStudyFromSeries()"
            [disabled]="checkedSeries.length<1">
        <mat-icon fontSet="mdi" fontIcon="mdi-merge"></mat-icon>
        <span class="label">{{'viewer.tools.split' | translate}}</span>
    </button>

    <button mat-button [matTooltip]="'viewer.tools.sendToModalities' | translate" (click)="sendToModalities()">
        <mat-icon fontSet="mdi" fontIcon="mdi-send"></mat-icon>
        <span class="label">{{'viewer.tools.send' | translate}}</span>
    </button>

</mat-toolbar>

<mat-menu #layoutsMenu="matMenu" yPosition="above" xPosition="before" class="layout-menu">
    <button *ngFor="let item  of layouts" mat-menu-item (click)="setLayout(item)" [class.active]="item === layout">
        {{item}}
    </button>
</mat-menu>

<mat-menu #fpsMenu="matMenu" yPosition="above" xPosition="before" class="fps-menu">
    <button *ngFor="let item  of fps" mat-menu-item (click)="setState('frameRate', item)"
            [ngClass]="{active: item == state?.frameRate}">
        {{item}}
    </button>
</mat-menu>
