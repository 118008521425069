<ng-container *ngIf="imageId">
    <!-- Overlays -->
    <div class="overlay top left">
        <div>{{metadata.patientName}}</div>
        <div>{{metadata.patientId}}</div>
    </div>

    <div class="overlay top right">
        <div>{{metadata.studyDescription}}</div>
    </div>

    <div class="overlay bottom left">
        <div>{{metadata.studyDate | ftDate}} {{metadata.studyTime| ftTime: ['HHmmss', 'HH:mm:ss']}}</div>
    </div>

    <div class="overlay bottom right">
        <div>{{metadata.institution}}</div>
    </div>
</ng-container>

