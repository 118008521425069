export const lang = {
    loading: 'Chargement... {{percentage}}%',
    please_select_series: 'Faites glisser une series ici pour voir les images.',

    arrow_annotation: {
        dialog_title: 'Titre du l\'annotation',
        dialog_placeholder: 'Annotation'
    },

    download_instance: {
        dialog_title: 'Téléchargement',
        dialog_placeholder: 'Titre'
    },

    tools: {
        stack_scroll: 'Défiler',
        zoom: 'Zoom',
        contrast: 'Contraste',
        pan: 'Pan',
        magnify: 'Amplifier',
        length_measurement: 'Longueur',
        arrow_annotate: 'Annoter',
        angle_measurement: 'Angle',
        elliptical_roi: 'Elliptique ROI',
        rectangle_roi: 'Rectangle ROI',
        pixel_probe: 'Sonde',
        freehand: 'Main levée',
        highlight: 'Highlight',
        crosshairs: 'Crosshairs',
        roi_window: 'Contraste ROI',
        eraser: 'Gomme',
        reset: 'Réinitialiser',
        stop: 'Stop',
        play: 'Play',
        fps: 'Fps',
        invert: 'Inverser',
        rotate_left: 'Tourne à gauche',
        rotate_right: 'Tourner à droite',
        h_flip: 'Flip H',
        v_flip: 'Flip V',
        download: 'Télécharger',

        layouts: 'Disposition',
        clear_tools: 'Supprimer les annotations',
        viewport_overlay: 'Superposition de texte',
        viewport_scale_overlay: 'Echelle',
        viewports_reference_lines: 'Lignes de référence',
        synchronize_contrast: 'Sync. contraste',
        synchronize_pan_zoom: 'Sync. Pan/Zoom',
        merge: 'Fusion',
        split: 'Fraction',
        send: 'Envoyer',
        sendToModalities: 'Envoyer l\'étude aux modalités DICOM distantes',
    },

    series: {
      loading: 'Chargement...'
    },

    print: {
        // buttons
        launch: 'Imprimer',
        dicom: 'DICOM',
        cups: 'CUPS',
        navigator: 'CHROME',
        cancel: 'Annuler',

        // series instances selection
        series_handle: 'Selection des images',
        image_count: 'Nombre d\'images',
        selection_method: 'Methode de selection',
        apply: 'Appliquer',
        append: 'Ajouter',
        incremental: 'Incremental',
        slices: 'Coupes',
        manual: 'Manual',

        // blocks
        printer_block: 'Imprimante',
        printer_options_block: 'Options d\'impression',
        film_options_block: 'Options de film',

        // options
        film_type: 'Type de film',
        priority: 'Priorité',
        destination: 'Destination (film)',
        copies: 'Copies',
        color_print: 'Imprimer en couleur',

        // film options
        paper_orientation: 'Orientation (film)',
        film_size: 'Taille de film',
        print_display_mode: 'Format d\'affichage',
        print_interpolation: 'Interpolation',
        smooth: 'Type de lissage',
        border: 'Bordure',
        emptyImageDensity: 'Remplissage',
        dpi: 'DPI',
        report_only: 'Rapport',
        images_only: 'Images',
        both: 'Les deux',

        error_message: 'La tâche d\'impression a rencontré une erreur !!'
    },

    share_dialog: {
        validate: 'Valider',
        link_password: 'Mot de pass du l\'étude',
        error_msg: 'Mot de passe invalide ou lien corrompu !!!',
    }
};
