import {chain, get, isNil} from 'lodash';
import * as cornerstone from 'cornerstone-core';
import * as cornerstoneTools from 'cornerstone-tools';

import {formatNumberPrecision, formatSlice} from '../utils/helpers';
import {BOTTOM_LEFT_KEYS, BOTTOM_RIGHT_KEYS, TEXT_PADDING, TOP_LEFT_KEYS, TOP_RIGHT_KEYS} from '../utils/const';

const BaseTool = cornerstoneTools.import('base/BaseTool');
const drawTextBox = cornerstoneTools.import('drawing/drawTextBox');
const textBoxWidth = cornerstoneTools.import('drawing/textBoxWidth');
const getNewContext = cornerstoneTools.import('drawing/getNewContext');
const {getOrientationString, invertOrientationString} = cornerstoneTools.orientation;

export class ViewportOverlaysTool extends BaseTool {
    constructor(props = {}) {
        const defaultProps = {
            name: 'ViewportOverlays',
            mixins: ['enabledOrDisabledBinaryTool'],
        };

        super(props, defaultProps);
    }

    public enabledCallback(element) {
        this.forceImageUpdate(element);
    }

    public disabledCallback(element) {
        this.forceImageUpdate(element);
    }

    public forceImageUpdate(element) {
        const enabledElement = cornerstone.getEnabledElement(element);

        if (enabledElement.image) {
            cornerstone.updateImage(element);
        }
    }

    public renderToolData(evt) {
        const eventData = evt.detail;
        const viewport = eventData.viewport;
        const imageId = eventData.image.imageId;
        const enabledElement = eventData.enabledElement;

        const canvas = eventData.canvasContext.canvas;
        const metadata = this._generateMetadata(viewport, enabledElement, imageId);

        const TEXT_HEIGHT = this._getTextHeight();
        const context = getNewContext(eventData.canvasContext.canvas);
        const rightMargin = enabledElement.element.classList.contains('with-scrollbar') ? 24 : 0;

        // top left
        const topLeftItems = this._getArrayToDraw(metadata, TOP_LEFT_KEYS);
        drawTextBox(
            context, topLeftItems, TEXT_PADDING, TEXT_PADDING, '#ffffff'
        );

        // top right
        this._getArrayToDraw(metadata, TOP_RIGHT_KEYS).forEach((item, index) => {
            const textWidth = textBoxWidth(context, item, 0);

            drawTextBox(
                context, item,
                canvas.width - (textWidth + rightMargin + (TEXT_PADDING * 3)),
                TEXT_PADDING + (TEXT_HEIGHT * index), '#ffffff'
            );
        });

        // bottom left
        const bottomLeftItems = this._getArrayToDraw(metadata, BOTTOM_LEFT_KEYS);
        const leftStartPoint = (canvas.height - TEXT_PADDING) - (bottomLeftItems.length * TEXT_HEIGHT);

        bottomLeftItems.forEach((item, index) => {
            drawTextBox(
                context, item, TEXT_PADDING, leftStartPoint + (index * TEXT_HEIGHT), '#ffffff'
            );
        });

        // bottom right
        const bottomRightItems = this._getArrayToDraw(metadata, BOTTOM_RIGHT_KEYS);
        const rightStartPoint = (canvas.height - TEXT_PADDING) - (bottomRightItems.length * TEXT_HEIGHT);

        bottomRightItems.forEach((item, index) => {
            const textWidth = textBoxWidth(context, item, 0);

            drawTextBox(
                context, item, canvas.width - (textWidth + 15 + rightMargin), rightStartPoint + (index * TEXT_HEIGHT), '#ffffff'
            );
        });

        // markers
        // left marker
        const left = this._getArrayToDraw(metadata, ['markers.left']);
        const leftWidth = textBoxWidth(context, left, 0);
        drawTextBox(
            context, left, TEXT_PADDING, (canvas.height / 2) - leftWidth, '#ffffff'
        );

        // top marker
        const top = this._getArrayToDraw(metadata, ['markers.top']);
        drawTextBox(
            context, top, (canvas.width / 2) - TEXT_HEIGHT, TEXT_PADDING, '#ffffff'
        );
    }

    // private api
    private _getArrayToDraw(metadata, keys) {
        return chain(keys).map(key => get(metadata, key)).compact().value();
    }

    private _generateMetadata(viewport, enabledElement, imageId) {
        const seriesMetadata = cornerstone.metaData.get('generalSeriesModule', imageId) || {};
        const {seriesNumber, seriesDescription} = seriesMetadata;

        const imagePlaneModule = cornerstone.metaData.get('imagePlaneModule', imageId) || {};
        const {rows, columns, sliceThickness, sliceLocation, rowCosines, columnCosines} = imagePlaneModule;

        // study related
        const generalStudyModule = cornerstone.metaData.get('generalStudyModule', imageId) || {};
        const {studyDate, studyTime, studyDescription, studyDateTime} = generalStudyModule;

        const patientModule = cornerstone.metaData.get('patientModule', imageId) || {};
        const {patientId, patientName} = patientModule;

        const generalImageModule = cornerstone.metaData.get('generalImageModule', imageId) || {};
        const {instanceNumber} = generalImageModule;

        const cineModule = cornerstone.metaData.get('cineModule', imageId) || {};
        const {frameRate} = cineModule;

        // content related
        const {contentDateTime} = cornerstone.metaData.get('contentModule', imageId) || {};

        const imageDimensions = `${columns} x ${rows}`;
        const compression = this._getCompression(imageId);

        // rotation
        let markers = {top: '', left: ''};
        if (rowCosines && columnCosines && !isNil(viewport.rotation)) {
            markers = this._getOrientationMarkers(
                rowCosines, columnCosines,
                viewport.rotation, viewport.vflip, viewport.hflip
            );
        }

        // institution
        const institution = cornerstone.metaData.get('x00080080', imageId);

        // image positions
        const stack = get(enabledElement, 'toolStateManager.toolState.stack.data.0');

        return {
            sliceLocation,
            sliceThickness,
            slice: formatSlice(sliceThickness, sliceLocation),

            seriesDescription,
            seriesNumber: seriesNumber > 1 ? `Ser: ${seriesNumber}` : undefined,

            studyDate,
            studyTime,
            studyDateTime,
            studyDescription,

            patientId,
            institution,
            patientName,

            contentDateTime,

            instanceNumber,
            stack: `Img: ${stack.currentImageIdIndex + 1}/${stack.imageIds.length}`,

            frameRate: frameRate ? `${frameRate} FPS` : undefined,

            compression,
            imageDimensions,
            markers,

            zoomPercentage: `Zoom: ${formatNumberPrecision(viewport.scale * 100, 2)}%`,

            windowWidth: viewport.voi.windowWidth,
            windowCenter: viewport.voi.windowCenter,
            window: `W: ${formatNumberPrecision(viewport.voi.windowWidth, 2)} L: ${formatNumberPrecision(viewport.voi.windowCenter, 2)}`,
        };
    }

    private _getCompression(imageId) {
        const generalImageModule = cornerstone.metaData.get('generalImageModule', imageId) || {};

        const {lossyImageCompression, lossyImageCompressionRatio, lossyImageCompressionMethod} = generalImageModule;

        if (lossyImageCompression === '01' && lossyImageCompressionRatio !== '') {
            const compressionMethod = lossyImageCompressionMethod || 'Lossy: ';
            return compressionMethod + lossyImageCompressionRatio + ' : 1';
        }

        return 'Lossless / Uncompressed';
    }

    private _getOrientationMarkers(rowCosines, columnCosines, rotationDegrees, isFlippedVertically, isFlippedHorizontally) {
        const rowString = getOrientationString(rowCosines);
        const columnString = getOrientationString(columnCosines);
        const oppositeRowString = invertOrientationString(rowString);
        const oppositeColumnString = invertOrientationString(columnString);

        const markers = {
            top: oppositeColumnString,
            left: oppositeRowString,
        };

        // If any vertical or horizontal flips are applied, change the orientation strings ahead of
        // the rotation applications
        if (isFlippedVertically) {
            markers.top = invertOrientationString(markers.top);
        }

        if (isFlippedHorizontally) {
            markers.left = invertOrientationString(markers.left);
        }

        // Swap the labels accordingly if the viewport has been rotated
        // This could be done in a more complex way for intermediate rotation values (e.g. 45 degrees)
        if (rotationDegrees === 90 || rotationDegrees === -270) {
            return {
                top: markers.left, left: invertOrientationString(markers.top),
            };
        } else if (rotationDegrees === -90 || rotationDegrees === 270) {
            return {
                top: invertOrientationString(markers.left), left: markers.top,
            };
        } else if (rotationDegrees === 180 || rotationDegrees === -180) {
            return {
                top: invertOrientationString(markers.top), left: invertOrientationString(markers.left),
            };
        }

        return markers;
    }

    // text height related
    private _getTextHeight() {
        return cornerstoneTools.textStyle.getFontSize() + TEXT_PADDING;
    }
}
