import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
    selector: 'ftp-viewer-password-prompt',
    templateUrl: './viewer-password-prompt.dialog.html',
    styleUrls: ['./viewer-password-prompt.dialog.scss']
})
export class ViewerPasswordPromptDialog implements OnInit {
    public error = false;
    public password = '';
    public loader: Subscription;
    public showPassword = false;

    constructor(
        private _http: HttpClient,
        private _dialogRef: MatDialogRef<ViewerPasswordPromptDialog>
    ) {
    }

    public ngOnInit(): void {
    }

    public submit() {
        const parameters = new URLSearchParams(window.location.search);
        const studyLink = parameters.get('s');

        const url = `/api/study-share/check/${studyLink}/`;
        this.loader = this._http.post(url, {password: this.password})
            .subscribe({
                error: () => this.error = true,
                next: (data: any) => this._dialogRef.close(data.study),
            });
    }

    public handlePassword(ev) {
        ev.stopPropagation();
        this.showPassword = !this.showPassword;
    }
}
