<div class="ft-parent-layout mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
    <mat-toolbar color="accent">
        <div class="ft-module-icon">
            <button mat-icon-button>
                <mat-icon fontSet="mdi" fontIcon="mdi-file"></mat-icon>
            </button>
        </div>
        <h2 translate="explorer.report.label"></h2>
        <span fxFlex></span>
    </mat-toolbar>


    <form #searchForm="ngForm" [ngFormOptions]="{updateOn: 'change'}" fxLayout="row" class="ft-table-search-bar"
          [class.focused]="isFocused">
        <mat-drawer-container fxFlex class="border-radius-bottom">

            <mat-drawer-content class="ft-content ft-content-margin ft-table-container" fxLayout="column">
                <div fxLayout="row" class="ft-table-search-bar" [class.focused]="isFocused"
                     *ftAcl="{resource: 'report', action: 'search'}">
                    <mat-icon fontSet="mdi" fontIcon="mdi-magnify" [class.mat-primary]="isFocused"></mat-icon>

                    <input type="text" name="PatientName" [(ngModel)]='query.PatientName' fxFlex
                           [placeholder]="'core.search' | translate"
                           (focus)="isFocused = true" (blur)="isFocused = false" [ftAutoFocus]="true"/>

                    <button mat-icon-button class="ft-unset-button" (click)="query.PatientName = ''"
                            *ngIf="query.PatientName?.length > 0"
                            color="warn">
                        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                    </button>

                    <mat-form-field fxFlex="20" class="ft-content-margin">
                        <ft-select [placeholder]="'settings.site' | translate"
                                   [multiple]="true"
                                   [config]="selectedInstitutionsConfig" [(ngModel)]="institutions"
                                   name="selectedInstitutions"
                                   (ngModelChange)="updateSelectedInstitution()">
                        </ft-select>
                    </mat-form-field>

                    <div class="ft-button-list-action-bar">
                        <button #btn_elem mat-stroked-button *ngFor="let status of reportStatus"
                                (click)="filterReports(status.value, btn_elem)"
                                [matTooltip]="status.tooltip | translate">
                            <mat-icon fontSet="mdi" [fontIcon]="status.icon"
                                      [style]="'color: ' + status.color"></mat-icon>
                        </button>
                    </div>

                    <div class="ft-button-list-action-bar">
                        <button mat-stroked-button *ngFor="let action of dateActions" (click)="resetDateRange(action)"
                                [matTooltip]="action.tooltip | translate"
                                [ngClass]="{'mat-primary': isDateSelected(action)}">
                            {{action.label | translate}}
                        </button>
                    </div>

                    <div class="ft-datepicker-container">
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate [placeholder]="'explorer.search.start_date' | translate"
                                   [(ngModel)]="query.StudyDateStart" (ngModelChange)="query.RangeAction = undefined"
                                   name="StudyDateStart">
                            <input matEndDate [placeholder]="'explorer.search.end_date' | translate"
                                   [(ngModel)]="query.StudyDateEnd" (ngModelChange)="query.RangeAction = undefined"
                                   name="StudyDateEnd">
                        </mat-date-range-input>
                        <mat-datepicker-toggle tabindex="-1" matSuffix [for]="picker">
                            <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-menu-down"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </div>

                </div>

                <ft-generic-table fxFlex
                                  trackByKey="ID"
                                  [remote]="true"
                                  [source]="source$"
                                  [hasSearch]="false"
                                  [selectable]="false"
                                  [hasPagination]="false"
                                  [columns]="reportColumns">
                </ft-generic-table>

            </mat-drawer-content>
        </mat-drawer-container>

    </form>

</div>