import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'ftp-progress',
    template: `
        <div mat-dialog-content fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
            <mat-progress-spinner [mode]="progress ? 'determinate' : 'indeterminate'" [value]="progress" diameter="50">
            </mat-progress-spinner>
            <span [translate]="message" [translateParams]="messageParams"></span>
        </div>
    `,
    styles: []
})
export class ProgressStoreDialog implements OnInit {

    public message: any = null;
    public messageParams: any = null;
    public progress: number = null;
    private _observable: Observable<any>;

    constructor(
        private _dialogRef: MatDialogRef<ProgressStoreDialog>, @Inject(MAT_DIALOG_DATA) data: any, private _translate: TranslateService
    ) {
        this._observable = data.observable;
        this.message = data.message;
        this.messageParams = data.messageParams;
    }

    ngOnInit() {
        this._observable
            .subscribe({
                next: event => this.progress = Math.round(100 * event.loaded / event.total),
                complete: () => this._dialogRef.close()
            });
    }

}
