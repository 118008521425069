<div [ngBusy]="loader"></div>
<!--(openChange)="isOpen = $event"-->
<ftp-viewer-toolbar (layoutChange)="handleLayout($event)" [checkedSeries]="checkedSeries" (seriesChange)="reloadSeries()"
                    [(state)]="activeViewport.state" [(currentTool)]="activeViewport.activeToolName"
                    (scaleOverlayChange)="scaleOverlay = $event" (referenceLinesChange)="referenceLines = $event">
</ftp-viewer-toolbar>
<mat-drawer-container fxFlex>
    <mat-drawer mode="side" [opened]="isOpen">
        <ftp-viewer-series
                [series]="series" [activeSeries]="activeSeries" [checkedSeries]="checkedSeries" (changeCheckedSeries)="updateCheckedSeries($event)"
                (errorSeries)="handleErrorSeries($event)" (isCompleted)="handleCompleted($event)" #seriesRef>
        </ftp-viewer-series>
    </mat-drawer>
    <mat-drawer-content>
        <div fxLayout="row wrap" class="viewports-container" #viewportsRef>
            <ftp-viewport *ngFor="let viewport of viewports; trackBy:trackByFn" [ngStyle]="viewport.style" [id]="viewport.id"
                          [ngClass]="{'active': viewport.id === activeViewport.id}" [series]="viewport.series" [(state)]="viewport.state"
                          [activeTool]="viewport.activeToolName" [referenceLines]="referenceLines" [overlay]="scaleOverlay"
                          [printer]="false" (activeViewportChange)="activeViewport = viewport">
            </ftp-viewport>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
