import {SelectConfig} from '@ft/core';
import {of} from 'rxjs';
import {concat} from 'lodash';

export const DARK_THEME = 'dark-theme';
export const STUDY_QUERY_STORED = 'ft-study-query-stored';
export const REPORT_QUERY_STORED = 'ft-report-query-stored';
export declare type KEY_QUERY_STORED = 'ft-study-query-stored' | 'ft-report-query-stored';
export const SITE = 'site';
export const SEARCH_IN_ARCHIVES = 'search-in-archives';
export declare type HOST_KEYS = 'pacs_host' | 'office_server_host';

export const TASK_DURATIONS_ITEMS = [
    {
        key: '12_months',
        kwargs: {months: 12},
        label: 'settings.archive.12_months',
    },
    {
        key: '9_months',
        kwargs: {months: 9},
        label: 'settings.archive.9_months',
    },
    {
        key: '6_months',
        kwargs: {months: 6},
        label: 'settings.archive.6_months',
    },
    {
        key: '3_months',
        kwargs: {months: 3},
        label: 'settings.archive.3_months',
    },
    {
        key: '1_months',
        kwargs: {months: 1},
        label: 'settings.archive.1_months',
    },
    {
        key: '15_days',
        kwargs: {days: 15},
        label: 'settings.archive.15_days',
    },
    {
        key: '7_days',
        kwargs: {days: 7},
        label: 'settings.archive.7_days',
    }
];

export const MODALITIES = [
    {label: 'CR', key: 'CR'},
    {label: 'CT', key: 'CT'},
    {label: 'DX', key: 'DX'},
    {label: 'ES', key: 'ES'},
    {label: 'MG', key: 'MG'},
    {label: 'MR', key: 'MR'},
    {label: 'NM', key: 'NM'},
    {label: 'OP', key: 'OP'},
    {label: 'PX', key: 'PX'},
    {label: 'RG', key: 'RG'},
    {label: 'US', key: 'US'},
    {label: 'XA', key: 'XA'},
    {label: 'LIVE', key: 'LIVE'},
];

export const AVAILABLE_MODALITIES_CONFIG: SelectConfig = {
    key: 'label', compareKey: 'label', observable: of(MODALITIES)
};

export const MODALITIES_TYPES = concat([{label: 'ALL', key: null}, {label: 'ECG', key: 'ECG'}], MODALITIES);

export interface ArchiveProgress {
    total?: number;
    current?: number;
    uncompress?: boolean;
}

// print related
export const PRINT_REFRESH_TOPIC = 'printings.reset_process';
export const PRINTER_PAPERS = [
    {name: 'A3'}, {name: 'A4'}, {name: 'A5'}
];

// burner
export const BURNER_REFRESH_TOPIC = 'burner_robot.reset_process';

export interface AppConfig {
    pacs_host: string;
    office_server_host: string;
}
