<form novalidate fxLayout="column" #optionsForm="ngForm" [ngFormOptions]="{updateOn: 'change'}">
    <div class="drawer-block">
        <div class="title" fxLayout="row" fxLayoutAlign="center center">
            <h3 fxFlex class="title-label" [translate]="'viewer.print.printer_options_block'"></h3>
        </div>

        <div class="block-body" fxLayout="row wrap">
            <div fxLayout="row" fxFlex>
                <mat-form-field fxFlex>
                    <ft-select [placeholder]="'viewer.print.printer_block' | translate" [config]="printerSelect"
                               [(ngModel)]="options.Printer" name="Printer" required (ngModelChange)="updateConfig()">
                    </ft-select>
                </mat-form-field>
            </div>
            <mat-form-field>
                <ft-select [placeholder]="'viewer.print.film_type' | translate" [config]="filmType"
                           [(ngModel)]="options.MediumType" name="MediumType" required>
                </ft-select>
            </mat-form-field>
            <mat-form-field *ngIf="withViewerTools">
                <ft-select [placeholder]="'viewer.print.priority' | translate" [config]="priority"
                           [(ngModel)]="options.PrintPriority" name="PrintPriority">
                </ft-select>
            </mat-form-field>
            <mat-form-field *ngIf="withViewerTools">
                <ft-select [placeholder]="'viewer.print.destination' | translate" [config]="destination"
                           [(ngModel)]="options.FilmDestination"
                           name="FilmDestination" required>
                </ft-select>
            </mat-form-field>
            <mat-form-field>
                <input [placeholder]="'viewer.print.copies' | translate" matInput type="number" [ftNumber]="'float'"
                       [fix]="0" [min]="1" name="NumberOfCopies" [(ngModel)]="options.NumberOfCopies"/>
            </mat-form-field>
            <mat-slide-toggle *ngIf="withViewerTools" [(ngModel)]="options.Color" name="Color"
                              [disabled]="!options.Printer?.support_color">
                <span fxFlex>{{'viewer.print.color_print' | translate}}</span>
            </mat-slide-toggle>
            <mat-radio-group *ngIf="isBooklet" [(ngModel)]="options.bookletOption" [ngModelOptions]="{standalone: true}"
                             [style.font-size]="'14px'">
                <mat-radio-button *ngFor="let option of bookletPrintOption"
                                  [value]="option.value">
                    {{option.key | translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="drawer-block" *ngIf="withViewerTools">
        <div class="title" fxLayout="row" fxLayoutAlign="center center">
            <h3 fxFlex class="title-label" [translate]="'viewer.print.film_options_block'"></h3>
        </div>

        <div class="block-body" fxLayout="row wrap">
            <mat-form-field>
                <ft-select [placeholder]="'viewer.print.paper_orientation' | translate" [config]="orientation"
                           [(ngModel)]="options.FilmOrientation"
                           name="FilmOrientation">
                </ft-select>
            </mat-form-field>
            <mat-form-field>
                <ft-select [placeholder]="'viewer.print.film_size' | translate" [config]="filmSize"
                           [(ngModel)]="options.FilmSizeID"
                           name="FilmSizeID">
                </ft-select>
            </mat-form-field>
            <mat-form-field>
                <ft-select [placeholder]="'viewer.print.print_display_mode' | translate" [config]="displayMode"
                           [(ngModel)]="options.ImageDisplayFormat"
                           name="ImageDisplayFormat">
                </ft-select>
            </mat-form-field>
            <mat-form-field>
                <ft-select [placeholder]="'viewer.print.print_interpolation' | translate" [config]="interpolation"
                           [(ngModel)]="options.MagnificationType"
                           name="MagnificationType">
                </ft-select>
            </mat-form-field>
            <mat-form-field>
                <ft-select [placeholder]="'viewer.print.smooth' | translate" [config]="smooth"
                           [(ngModel)]="options.SmoothingType"
                           name="SmoothingType">
                </ft-select>
            </mat-form-field>
            <mat-form-field>
                <ft-select [placeholder]="'viewer.print.border' | translate" [config]="border"
                           [(ngModel)]="options.Border"
                           name="Border">
                </ft-select>
            </mat-form-field>
            <mat-form-field *ngIf="false">
                <ft-select [placeholder]="'viewer.print.emptyImageDensity' | translate" [config]="emptyImageDensity"
                           [(ngModel)]="options.EmptyImageDensity"
                           name="EmptyImageDensity">
                </ft-select>
            </mat-form-field>
            <mat-form-field style="width: 100%; margin: 0;">
                <ft-select [placeholder]="'viewer.print.dpi' | translate" [config]="dpi" [(ngModel)]="options.DPI"
                           name="DPI">
                </ft-select>
            </mat-form-field>
        </div>
    </div>

    <div class="drawer-block" *ngIf="withViewerTools">
        <div class="buttons">
            <button mat-icon-button [matTooltip]="'viewer.tools.stack_scroll' | translate"
                    (click)="setActiveTool('StackScroll')" [class.mat-primary]="activeToolName === 'StackScroll'">
                <mat-icon fontSet="mdi" fontIcon="mdi-layers"></mat-icon>
            </button>
            <button mat-icon-button [matTooltip]="'viewer.tools.zoom' | translate" (click)="setActiveTool('Zoom')"
                    [class.mat-primary]="activeToolName === 'Zoom'">
                <mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
            </button>
            <button mat-icon-button [matTooltip]="'viewer.tools.contrast' | translate" (click)="setActiveTool('Wwwc')"
                    [class.mat-primary]="activeToolName === 'Wwwc'">
                <mat-icon fontSet="mdi" fontIcon="mdi-contrast"></mat-icon>
            </button>
            <button mat-icon-button [matTooltip]="'viewer.tools.roi_window' | translate"
                    (click)="setActiveTool('WwwcRegion')" [class.mat-primary]="activeToolName === 'WwwcRegion'">
                <mat-icon fontSet="mdi" fontIcon="mdi-contrast-box"></mat-icon>
            </button>
            <button mat-icon-button [matTooltip]="'viewer.tools.pan' | translate" (click)="setActiveTool('Pan')"
                    [class.mat-primary]="activeToolName === 'Pan'">
                <mat-icon fontSet="mdi" fontIcon="mdi-cursor-move"></mat-icon>
            </button>
        </div>
        <hr class="separator">
        <div class="buttons">
            <button mat-icon-button [matTooltip]="'viewer.tools.length_measurement' | translate"
                    (click)="setActiveTool('Length')" [class.mat-primary]="activeToolName === 'Length'">
                <mat-icon fontSet="mdi" fontIcon="mdi-ruler"></mat-icon>
            </button>
            <button mat-icon-button [matTooltip]="'viewer.tools.arrow_annotate' | translate"
                    (click)="setActiveTool('ArrowAnnotate')" [class.mat-primary]="activeToolName === 'ArrowAnnotate'">
                <mat-icon fontSet="mdi" fontIcon="mdi-link-plus"></mat-icon>
            </button>
            <button mat-icon-button [matTooltip]="'viewer.tools.angle_measurement' | translate"
                    (click)="setActiveTool('Angle')" [class.mat-primary]="activeToolName === 'Angle'">
                <mat-icon fontSet="mdi" fontIcon="mdi-angle-right"></mat-icon>
            </button>

            <button mat-icon-button [matTooltip]="'viewer.tools.elliptical_roi' | translate"
                    (click)="setActiveTool('EllipticalRoi')" [class.mat-primary]="activeToolName === 'EllipticalRoi'">
                <mat-icon fontSet="mdi" fontIcon="mdi-checkbox-blank-circle-outline"></mat-icon>
            </button>
            <button mat-icon-button [matTooltip]="'viewer.tools.rectangle_roi' | translate"
                    (click)="setActiveTool('RectangleRoi')" [class.mat-primary]="activeToolName === 'RectangleRoi'">
                <mat-icon fontSet="mdi" fontIcon="mdi-crop-square"></mat-icon>
            </button>
            <button mat-icon-button [matTooltip]="'viewer.tools.pixel_probe' | translate"
                    (click)="setActiveTool('Bidirectional')" [class.mat-primary]="activeToolName === 'Bidirectional'">
                <mat-icon fontSet="mdi" fontIcon="mdi-google-circles-extended"></mat-icon>
            </button>
            <button mat-icon-button [matTooltip]="'viewer.tools.eraser' | translate" (click)="setActiveTool('Eraser')"
                    [class.mat-primary]="activeToolName === 'Eraser'">
                <mat-icon fontSet="mdi" fontIcon="mdi-eraser"></mat-icon>
            </button>
        </div>

        <hr class="separator">

        <div class="buttons">
            <button mat-icon-button class="bright" [matTooltip]="'viewer.tools.rotate_left' | translate"
                    (click)="setState('rotation', state.rotation - 90)">
                <mat-icon fontSet="mdi" fontIcon="mdi-rotate-left-variant"></mat-icon>
            </button>
            <button mat-icon-button class="bright" [matTooltip]="'viewer.tools.rotate_right' | translate"
                    (click)="setState('rotation', state.rotation + 90)">
                <mat-icon fontSet="mdi" fontIcon="mdi-rotate-right-variant"></mat-icon>
            </button>
            <button mat-icon-button class="bright" [matTooltip]="'viewer.tools.h_flip' | translate"
                    (click)="setState('hflip', !state.hflip)" [class.mat-primary]="state?.hflip">
                <mat-icon fontSet="mdi" fontIcon="mdi-swap-horizontal"></mat-icon>
            </button>
            <button mat-icon-button class="bright" [matTooltip]="'viewer.tools.v_flip' | translate"
                    (click)="setState('vflip', !state.vflip)" [class.mat-primary]="state?.vflip">
                <mat-icon fontSet="mdi" fontIcon="mdi-swap-vertical"></mat-icon>
            </button>

        </div>

        <hr class="separator">

        <div class="buttons">
            <button mat-icon-button [matTooltip]="'viewer.tools.synchronize_contrast' | translate"
                    (click)="enableWwwcSynchronizer()" [class.mat-primary]="isWwwcSynchronizerEnable">
                <mat-icon fontSet="mdi" fontIcon="mdi-vector-link"></mat-icon>
            </button>
            <button mat-icon-button [matTooltip]="'viewer.tools.synchronize_pan_zoom' | translate"
                    (click)="enablePanZoomSynchronizer()" [class.mat-primary]="isPanZoomSynchronizerEnable">
                <mat-icon fontSet="mdi" fontIcon="mdi-link-variant"></mat-icon>
            </button>
            <button mat-icon-button [matTooltip]="'viewer.tools.viewport_scale_overlay' | translate"
                    (click)="enableScaleOverlay()" [class.mat-primary]="isScaleOverlayEnable">
                <mat-icon fontSet="mdi" fontIcon="mdi-ruler-square" class="mdi-rotate-180"></mat-icon>
            </button>
            <button mat-icon-button [matTooltip]="'viewer.tools.viewport_overlay' | translate" (click)="enableOverlay()"
                    [class.mat-primary]="isOverlayEnable">
                <mat-icon fontSet="mdi" fontIcon="mdi-format-wrap-tight"></mat-icon>
            </button>
        </div>

    </div>
</form>
