import {Component, Inject} from '@angular/core';
import {ExplorerService} from '../../services/explorer.service';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    InstanceModel,
    instanceOfSeriesModel,
    instanceOfStudyModel,
    MergeSplitDialogInput,
    SeriesModel,
    StudyModel
} from '../../models/models';
import {chain, reject} from 'lodash';
import {SERIES_FIELDS} from '../../models/fields';

@Component({
    selector: 'ftp-merge-split-dialog',
    templateUrl: './merge-split-dialog.dialog.html',
    styles: []
})
export class MergeSplitDialogDialog {
    public title = '';
    public splitTypeTitle = '';
    public type: 'merge' | 'split';
    public keepSource = true;
    public resources: StudyModel[] | SeriesModel[] | InstanceModel[];
    public mainResource: any; // StudyModel | SeriesModel;
    public showDetail = true;
    public seriesFields = SERIES_FIELDS;

    constructor(
        private _explorerService: ExplorerService,
        private _translateService: TranslateService,
        private _dialogRef: MatDialogRef<MergeSplitDialogDialog>,
        @Inject(MAT_DIALOG_DATA) data: MergeSplitDialogInput
    ) {
        this.title = this._translateService.instant(data.title);
        this.type = this._translateService.instant(data.type);
        this.splitTypeTitle = this._translateService.instant(data.splitType === 'studies' ? 'explorer.series.series_count' : 'explorer.series.instances_count');
        this.resources = chain(data).get('resources').cloneDeep().value();

        if (this.type === 'merge') {
            this.mainResource = this.resources[0];
        }
    }

    public submit() {
        if (this.type === 'merge') {
            const otherResources = reject(this.resources, this.mainResource);

            this._dialogRef.close({
                keepSource: this.keepSource,
                mainResource: instanceOfStudyModel(this.mainResource) ? this.mainResource.ID : this.mainResource.SeriesInstanceUID,
                resources: chain(otherResources).map(resource => resource.ID).value()
            });
        } else {
            this._dialogRef.close({
                keepSource: this.keepSource,
                resources: chain(this.resources).map(resource => resource.ID).value()

            });
        }

    }

    public handleRscIDValue(resource: StudyModel | SeriesModel = this.mainResource) {
        if (instanceOfStudyModel(resource)) return resource.StudyDescription ?? resource.ID;
        else return resource.SeriesDescription ?? resource.SeriesInstanceUID;
    }

    public handleUIDValue(resource: SeriesModel | InstanceModel) {
        if (instanceOfSeriesModel(resource)) return resource.SeriesInstanceUID;
        else return resource.SOPInstanceUID;
    }

    public isStudy(resource = this.mainResource): boolean {
        return instanceOfStudyModel(resource);
    }

    public isSeries(): boolean {
        return instanceOfSeriesModel(this.mainResource);
    }

}
