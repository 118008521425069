<div class="ft-parent-layout mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
    <div class="upload-section ft-busy-container" *ngIf="!currentFile" fxFlex fxLayout="column" ngfDrop ngfSelect multiple="1"
         (drop)="handleItems($event, false)" [files]='files' (filesChange)="handleItems($event, true)" (validDragChange)="hasBaseDropZoneOver = $event"
         [ngClass]="{'file-over': hasBaseDropZoneOver}">
        <span [@uploadItemTransition]>{{'settings.dcm_upload.drag_and_drop' | translate}}</span>
        <mat-icon fontSet="mdi" fontIcon="mdi-cloud-upload" [@uploadItemTransition]></mat-icon>
    </div>

    <div class="progress-section" *ngIf="currentFile" fxFlex fxLayout="column">
        <mat-progress-bar mode="buffer" [value]="currentFile.progress" [@uploadItemTransition]></mat-progress-bar>
        <span [@uploadItemTransition]>{{'settings.dcm_upload.file_progress' | translate: {file: currentFile, total: total} }} ({{currentFile.size | ftFileSize}})</span>
    </div>
</div>

<button mat-mini-fab color="primary" (click)="dicomizer()"
        [matTooltip]="'settings.dcm_upload.dicomizer_btn' | translate" matTooltipPosition="above">
    <mat-icon fontSet="mdi" fontIcon="mdi-upload"></mat-icon>
</button>
