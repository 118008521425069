import {get} from 'lodash';


export function patientVariablesData(translate, patientValues = {}) {
    return [
        {
            key: '{{PatientID}}',
            label: translate.instant('settings.patient_vars.PatientID'),
            value: get(patientValues, 'PatientID', '')
        },
        {
            key: '{{PatientName}}',
            label: translate.instant('settings.patient_vars.PatientName'),
            value: get(patientValues, 'PatientName', '')
        },
        {
            key: '{{PatientBirthDate}}',
            label: translate.instant('settings.patient_vars.PatientBirthDate'),
            value: get(patientValues, 'PatientBirthDate', '')
        },
        {
            key: '{{PatientSex}}',
            label: translate.instant('settings.patient_vars.PatientSex'),
            value: get(patientValues, 'PatientSex', '')
        },
    ];

}

export function StudyVariablesData(translate, studyValues = {}): any[] {
    return [
        {
            key: '{{StudyID}}',
            label: translate.instant('settings.study_vars.StudyID'),
            value: get(studyValues, 'StudyID', '')
        },
        {
            key: '{{StudyDescription}}',
            label: translate.instant('settings.study_vars.StudyDescription'),
            value: get(studyValues, 'StudyDescription', '')
        },
        {
            key: '{{StudyDate}}',
            label: translate.instant('settings.study_vars.StudyDate'),
            value: get(studyValues, 'StudyDate', '')
        },
        {
            key: '{{InstitutionName}}',
            label: translate.instant('settings.study_vars.InstitutionName'),
            value: get(studyValues, 'InstitutionName', '')
        },
        {
            key: '{{ReferringPhysicianName}}',
            label: translate.instant('settings.study_vars.ReferringPhysicianName'),
            value: get(studyValues, 'ReferringPhysicianName', '')
        },
    ];
}

export function defaultVariables(translate): string {
    return JSON.stringify([
        {
            name: 'Patient',
            config: patientVariablesData(translate),
        },
        {
            name: 'Exam',
            config: StudyVariablesData(translate)
        },
    ]);
}
