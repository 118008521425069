import {assign, chain, get, isObject, reduce, toLower} from 'lodash';

const FILM_BOX_KEYS = [
    'ImageDisplayFormat', 'FilmOrientation', 'FilmSizeID', 'MagnificationType', 'SmoothingType',
    'BorderDensity', 'EmptyImageDensity', 'MinDensity', 'MaxDensity', 'Trim', 'RequestedResolutionID',
];

const FILM_SESSION_KEYS = [
    'NumberOfCopies', 'PrintPriority', 'MediumType', 'FilmDestination'
];

export const BOOKLET_PRINT_OPTION = [
    {key: 'viewer.print.report_only', value: 'REPORT_ONLY'},
    {key: 'viewer.print.images_only', value: 'IMAGES_ONLY'},
    {key: 'viewer.print.both', value: 'BOTH'}
];

export class PrintOption {
    Printer: any;

    MediumType: any;
    FilmSizeID: any;

    PrintPriority: any = {value: 'LOW'};
    FilmDestination: any = {value: 'MAGAZINE'};
    FilmOrientation: any = {value: 'LANDSCAPE', isLandScape: true};
    ImageDisplayFormat: any = {value: 'STANDARD\\1,1'};
    MagnificationType: any = {value: 'REPLICATE'};
    SmoothingType: any = {value: 'MEDIUM'};
    Border: any = {value: 'YES'};
    DPI: any = {value: '300'};
    NumberOfCopies = 1;
    Color = false;

    // just static now
    Trim = 'NO';
    MinDensity = 0;
    MaxDensity = 255;
    BorderDensity = 'BLACK';
    EmptyImageDensity = 'BLACK';
    RequestedResolutionID = 'STANDARD';
    bookletOption: 'REPORT_ONLY' | 'IMAGES_ONLY' | 'BOTH' = 'BOTH';

    public get layout(): string {
        return get(this.ImageDisplayFormat, 'layout', '1x1');
    }

    public get isLandScape(): boolean {
        return get(this.FilmOrientation, 'isLandScape', false);
    }

    public get background() {
        return toLower(this.EmptyImageDensity);
    }

    public request(): string {
        return JSON.stringify({
            layout: this.ImageDisplayFormat.layout, is_color: this.Color, bookletOption: this.bookletOption,
            FilmBox: this.FilmBox(), FilmSession: this.FilmSession()
        });
    }

    public dimension(): string {
        return chain(this.FilmSizeID).pick(['height', 'width']).thru(item => JSON.stringify(item)).value();
    }

    public FilmBox(): any {
        return reduce(FILM_BOX_KEYS, (sum, key) => assign(sum, {[key]: this._handleValue(key)}), {});
    }

    public FilmSession(): any {
        return reduce(FILM_SESSION_KEYS, (sum, key) => assign(sum, {[key]: this._handleValue(key)}), {});
    }

    private _handleValue(key: string) {
        const value = get(this, key);

        if (key === 'ImageDisplayFormat') return 'STANDARD\\1,1';
        else if (key === 'FilmSizeID') return get(value, 'file_size_id');
        else if (isObject(value)) return get(value, 'value');
        else return value;
    }
}
