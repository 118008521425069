import {Component, Inject} from '@angular/core';
import {SelectConfig} from '@ft/core';
import {HttpClient} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {get} from 'lodash';
import {of} from 'rxjs';

@Component({
    selector: 'ftp-modalities-selection',
    templateUrl: './modalities-selection.dialog.html',
    styleUrls: ['./modalities-selection.dialog.scss']
})
export class ModalitiesSelectionDialog {
    public data: { remoteModalities?: any } = {};
    public resources: string[] = [];
    public modalitiesSelectConfig: SelectConfig = null;

    constructor(
        private _http: HttpClient,
        private _dialogRef: MatDialogRef<ModalitiesSelectionDialog>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.resources = get(data, 'resources');
        this.modalitiesSelectConfig = {
            key: 'modalities',
            observable: of(get(data, 'modalities')),
            optionContent: item => item.AET,
            disabledOption: item => !item.AllowStore
        };
    }

    public submit() {
        this._dialogRef.close(this.data);
    }
}
