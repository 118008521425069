import {Component, Inject} from '@angular/core';
import {get, map as loMap} from 'lodash';
import {SelectConfig} from '@ft/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StudyModel} from '../../models/models';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Component({
    selector: 'ftp-robot-selection',
    templateUrl: './robot-selection.dialog.html',
    styleUrls: ['./robot-selection.dialog.scss']
})
export class RobotSelectionDialog {
    private _study: StudyModel = null;

    public data: { robot?: any, series?: any } = {};
    public robotsSelectConfig: SelectConfig = null;
    public seriesSelectConfig: SelectConfig = null;

    constructor(
        private _http: HttpClient,
        private _dialogRef: MatDialogRef<RobotSelectionDialog>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._study = get(data, 'study');
        this.robotsSelectConfig = get(data, 'selectConfig');
        this.seriesSelectConfig = {key: 'SeriesDescription', autoSelect: true, observable: this._constructSeriesForSelect()};
    }

    public submit() {
        this._dialogRef.close(this.data);
    }

    private _constructSeriesForSelect() {
        return this._http.get(`/dcm/studies/${this._study.ID}/series`)
            .pipe(
                map(items => loMap(items, item => {
                    const description = `${get(item, 'MainDicomTags.SeriesNumber')} - ${get(item, 'MainDicomTags.SeriesDescription', 'NO_DESCRIPTION')}`;

                    return {
                        ID: get(item, 'ID'),
                        SeriesDescription: description
                    };
                }))
            );
    }
}
