import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';

import {find, orderBy} from 'lodash';
import {from, of} from 'rxjs';
import {catchError, mergeMap, mergeScan, tap} from 'rxjs/operators';

import * as cornerstone from 'cornerstone-core';
import {SeriesItem} from '../../classes/series-item';
import {ViewerService} from '../../services/viewer.service';
import {IMAGE_TYPE} from '../../utils/const';
import {PrintService} from '../../services/print.service';


@Component({
    selector: 'ftp-viewer-series',
    templateUrl: './viewer-series.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        './viewer-series.component.scss'
    ]
})
export class ViewerSeriesComponent implements AfterViewInit {
    public checkedSeries: SeriesItem[];

    @Input('checkedSeries')
    public set handleCheckedSeries(checkedSeries: SeriesItem[]) {
        this.checkedSeries = checkedSeries;
    }

    @Input() public isPrint: boolean;
    @Input() public printer = false;
    @Input() public series: SeriesItem[];
    @Input() public activeSeries: string[];

    @ViewChildren('seriesThumbnails') public things: QueryList<any>;

    @Output() public isCompleted: EventEmitter<SeriesItem> = new EventEmitter<SeriesItem>();
    @Output() public errorSeries: EventEmitter<SeriesItem> = new EventEmitter<SeriesItem>();
    @Output() public changeCheckedSeries: EventEmitter<SeriesItem[]> = new EventEmitter<SeriesItem[]>();

    constructor(
        private _cdf: ChangeDetectorRef,
        private _printService: PrintService,
        private _viewerService: ViewerService,
    ) {
    }

    public ngAfterViewInit() {
        this.things.changes.subscribe(t => this.loadThumbnails(t));
    }

    public loadThumbnails(items) {
        this._loadThumbnails$(items)
            .subscribe();
    }

    public seriesPrint(series: SeriesItem) {
        this._printService.seriesPrintDialog(series)
            .subscribe(data => {
                console.log(data);
            });
    }

    public updateCheckedItems(series: SeriesItem) {
        if (this.checkedSeries.includes(series)) {
            const i = this.checkedSeries.indexOf(series);
            this.checkedSeries.splice(i, 1);
        } else {
            this.checkedSeries.push(series);
        }
        this.changeCheckedSeries.emit(this.checkedSeries);
    }

    // one by one loading
    private _loadThumbnails$(items) {
        const itemsArray = orderBy(items.toArray(), item => {
            return item.nativeElement.getAttribute('series') === this._viewerService.currentSeries ? 0 : 1;
        });

        return from(itemsArray)
            .pipe(
                mergeScan((acc, element, index) => {
                    const series = find(this.series, ['id', (element as ElementRef).nativeElement.id]);
                    return this._asyncLoad$(element, series);
                }, [], 2),
            );
    }

    private _asyncLoad$(element, series) {
        const thumbnailSrc = series.getThumbnailSrc();

        return from(cornerstone.loadAndCacheImage(thumbnailSrc))
            .pipe(
                mergeMap(image => from(this._renderAsync(image))),
                tap(dataURL => {
                    const imageElement = element.nativeElement.querySelector('.image-container img');
                    imageElement.src = dataURL;

                    element.nativeElement.classList.add('series-item-loaded');

                    this.isCompleted.emit(series);
                    this._cdf.detectChanges();
                }),
                catchError((error) => {
                    console.error(error);

                    element.nativeElement.style.display = 'none';

                    this.errorSeries.emit(series);
                    this._cdf.detectChanges();

                    return of([false]);
                })
            );
    }

    private _renderAsync(image): Promise<string[]> {
        return new Promise((resolve, reject) => {
            try {
                const canvasElement = document.createElement('canvas');
                canvasElement.setAttribute('width', '134');
                canvasElement.setAttribute('height', '134');

                cornerstone.renderToCanvas(canvasElement, image);
                const dataURL = canvasElement.toDataURL(IMAGE_TYPE, 1);

                resolve([dataURL]);
            } catch (error) {
                reject(error);
            }
        });
    }
}
