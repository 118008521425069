<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <mat-icon fontSet="mdi" fontIcon="mdi-merge"></mat-icon>
    </div>
    <h2 mat-dialog-title>{{ title }}</h2>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<mat-slide-toggle [(ngModel)]="keepSource" color="warn" class="ft-padding">
    <b [translate]="type == 'merge'? 'explorer.tools.keep_source_secondary' : 'explorer.tools.keep_source'"></b>
</mat-slide-toggle>
<form novalidate #form="ngForm" (submit)="submit()" fxLayout="column">

    <!--        MERGE          -->
    <div mat-dialog-content fxLayout="column" class="ft-busy-container" *ngIf="type=='merge'">

        <mat-form-field appearance="fill" class="with-error-label">
            <mat-label [translate]="'explorer.tools.select_main_resource'"></mat-label>
            <mat-select [(value)]="mainResource">
                <mat-option *ngFor="let resource of resources" [value]="resource">
                    {{handleRscIDValue(resource)}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- *** STUDIES *** -->
        <ng-template [ngIf]="isStudy()">
            <mat-accordion>
                <mat-expansion-panel [expanded]=showDetail (opened)="showDetail = true" (closed)="showDetail = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{'explorer.series.series_count' | translate }}</mat-panel-title>
                        <mat-panel-description>
                            <strong>{{mainResource.StudySeries}}</strong>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p>{{'explorer.study.patient_id' | translate }} : {{mainResource.PatientID}}</p>
                    <p>{{'explorer.study.patient_name' | translate }} : {{mainResource.PatientName}}</p>
                    <p>{{'explorer.study.patient_sex' | translate }} : {{mainResource.PatientSex}}</p>
                    <p>{{'explorer.study.study_description' | translate }} : {{mainResource.StudyDescription}}</p>
                    <p>{{'explorer.study.study_date' | translate }}
                        : {{mainResource.StudyDate | ftDate}} {{mainResource.StudyTime | ftTime}} </p>
                    <p>{{'explorer.study.referring_physician_name' | translate }}
                        : {{mainResource.ReferringPhysicianName}}</p>
                    <p>{{'explorer.study.institution_name' | translate }} : {{mainResource.InstitutionName}}</p>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>


        <!-- *** SERIES *** -->
        <ng-template [ngIf]="isSeries()">
            <mat-accordion>
                <mat-expansion-panel [expanded]=showDetail (opened)="showDetail = true" (closed)="showDetail = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{'explorer.series.instances_count' | translate }}</mat-panel-title>
                        <mat-panel-description>{{mainResource.SeriesInstances}}</mat-panel-description>
                    </mat-expansion-panel-header>
                    <ng-container *ngFor="let item of seriesFields">
                        <ng-template [ngIf]="item.type == 'text'">
                            <p>{{item.label | translate }} : {{mainResource[item.name]}}</p>
                        </ng-template>
                        <ng-template [ngIf]="item.type == 'date'">
                            <p>{{item.label | translate }}
                                : {{mainResource[item.name] | ftDate}} {{mainResource.SeriesTime | ftTime}}</p>
                        </ng-template>
                    </ng-container>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-template>

    </div>
    <!--        END MERGE          -->

    <!--        SPLIT          -->
    <div mat-dialog-content fxLayout="column" class="ft-busy-container" *ngIf="type=='split'">
        <mat-accordion>
            <mat-expansion-panel [expanded]=showDetail (opened)="showDetail = true" (closed)="showDetail = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{splitTypeTitle}}</mat-panel-title>
                    <mat-panel-description>
                        <strong>{{resources.length}}</strong>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let item of resources">
                    <p>{{handleUIDValue(item)}}</p>
                </ng-container>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <!--        END SPLIT          -->


    <div mat-dialog-actions>
        <button mat-raised-button color="primary" type="submit" tabindex="2" [disabled]="form.invalid">
            <mat-icon fontSet="mdi" fontIcon="mdi-check"></mat-icon>
            <span [translate]="'common.validate'"></span>
        </button>
        <button mat-raised-button color="warn" tabindex="-1" [mat-dialog-close]="false" type="button">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            <span [translate]="'common.cancel'"></span>
        </button>
    </div>
</form>
