import Hammer from 'hammerjs';
import * as cornerstone from 'cornerstone-core';
import * as cornerstoneMath from 'cornerstone-math';
import * as cornerstoneTools from 'cornerstone-tools';
import {wadoUriMetaDataProvider, wadoUriPixelSpacingProvider} from './metadata-provider';

export function initializeTools() {
    // disable log
    // localStorage.setItem('cornerstoneTools', 'false');

    // add metadata providers
    cornerstone.metaData.addProvider(wadoUriMetaDataProvider);
    cornerstone.metaData.addProvider(wadoUriPixelSpacingProvider, 100);

    // configure max requests not working now
    cornerstone.imageLoadPoolManager.maxNumRequests = {interaction: 4, thumbnail: 4, prefetch: 4};


    // tools externals
    cornerstoneTools.external.Hammer = Hammer;
    cornerstoneTools.external.cornerstone = cornerstone;
    cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

    //
    cornerstoneTools.init({showSVGCursors: true, globalToolSyncEnabled: true});

    // TODO enhance for our style
    // Preferences
    cornerstoneTools.toolStyle.setToolWidth(2);
    // cornerstoneTools.textStyle.setFont(`bold 14px Arial, sans-serif`);
    cornerstoneTools.toolColors.setToolColor('#EEFF41');
    cornerstoneTools.toolColors.setActiveColor('#FF1744');

    cornerstoneTools.store.state.touchProximity = 40;

    // stackPrefetch
    cornerstoneTools.stackPrefetch.setConfiguration({
        maxImagesToPrefetch: Infinity, preserveExistingPool: false, maxSimultaneousRequests: 4
    });
}

