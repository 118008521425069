export const lang = {
    navbar: {
        patients: 'Patients',
        studies: 'Studies',
        series: 'Series',
        instances: 'Instances',
    },

    search: {
        start_date: 'Start',
        end_date: 'End',
        refresh_studies: 'Refresh studies',
        advanced_search: 'Recherche Avancée',
        search_in_archives: 'Search in archived studies',
        archives_found: 'there are {{length}} studies archived'
    },

    // table related
    study: {
        study_title: 'Study',
        patient_id: 'ID Patient',
        patient_name: 'Patient name',
        patient_birth_date: 'Birth Date',
        patient_studies: 'Studies',
        patient_sex: 'Sex',
        accession_number: 'Accession number',
        study_description: 'Description',
        study_id: 'ID Study',
        study_series: 'Series',
        study_date: 'Date',
        study_time: 'To',
        referring_physician_name: 'Referring physician',
        institution_name: 'Clinic',
        hasReport: 'Report',
        pathologies: 'Pathologies',
        add_pathology: 'Add pathologie',
    },

    series: {
        series_title: 'Series',
        manufacturer: 'Manufacturer',
        operators_name: 'Operator',
        series_description: 'Series description',
        station_name: 'Station',
        series_number: 'N° of series',
        modality: 'Modality',
        status: 'Status',
        series_count: 'Nb of Series',
        series_instances: 'Instances',
        instances_count: 'Nb of instances',
        series_date: 'Date',
        series_time: 'To',
    },

    instance: {
        instance_number: 'Instance Number',
        number_of_frames: 'Number of frames',
        image_comments: 'Comments',
        file_size: 'File size',
        index_in_series: 'Series index',
        sop_instance_uid: 'SOPInstanceUID',

        tags: 'Tags',
        preview: 'Preview',
    },

    report: {
        label: 'reports List ',
        removeStudyReportMsg: 'Are you sure to delete this report?',
        quitEditorMsg: 'Do you really want to quit the editor?',
        edit: 'Edit',
        editNewTab: 'Open in a new tab',
        save: 'Save',
        download: 'Download',
        print: 'Print',
        print_booklet: 'Print booklet',
        close: 'Close',
        status: 'Status',
        pending: 'Pending',
        inProgress: 'In progress',
        validate: 'Validate',
        toValidate: 'To validate',
        validated: 'Validated',
        toSign: 'To sign',
        sign: 'Sign',
        signed: 'Signed',
        statusChangedTo: 'Status is changed to "{{value}}"',

    },

    // TODO enhance translations
    cancel: 'Cancel',

    genders: {
        male: 'Male',
        female: 'Female',
    },

    actions: {
        print: 'Print',
        anonymize: 'Anonymize',
        download_zip: 'Download zip',
        more_actions: 'More actions',
        download_patient_studies: 'Download studies patient',
        visualize_study: 'Visualize',
        visualize_series: 'Visualize',
        visualize_archives: 'Visualize',
        delete: 'Delete',
        protect_patient: 'Protect the patient',
        choose_study: 'open study',
        choose_series: 'open series',
        close: 'Close'
    },

    confirm: {
        anonymize_patient_confirm: 'Are you sure to anonymize the patient "{{value}}"',
        anonymize_study_confirm: 'Are you sure to anonymize the study "{{value}}"',
        anonymize_series_confirm: 'Are you sure to anonymize series "{{value}}"',
        delete_patient_confirm: 'would you like to delete the patient "{{value}}"',
        delete_study_confirm: 'Are you sure to delete the study "{{value}}"',
        delete_series_confirm: 'Are you sure to delete series "{{value}}"',
        delete_instance_confirm: 'Are you sure to delete the instance "{{value}}"',
    },

    side_nave: {
        copy_to_clipboard: 'Tag copied to clipboard with success !!'
    },

    series_update: {
        tooltip: 'Edit',
        dialog_label: 'Edit the study',
        study_date: 'Date of the study',
        study_time: 'Time of the study',
    },

    media: {
        send_to_robot: 'Send to robot',
        send_to_robot_fail: 'No robot is configured !!',
        download_media: 'Download',
        robot_selection_label: 'Please select a robot',
        robot_selection_placeholder: 'Name of the robot',
        series_selection_placeholder: 'Series to be burned',
        send_to_robot_success: 'The study has been sent to the robot successfully',
    },

    modality_action: {
        all: 'All'
    },

    date_actions: {
        one_day_short: '1d',
        one_day_long: 'One day',
        three_days_short: '3d',
        three_days_long: 'Three days',
        seven_days_short: '7d',
        seven_days_long: 'One week',
        fourteen_days_short: '14d',
        fourteen_days_long: 'Two weeks',
        thirty_days_short: '30d',
        thirty_days_long: 'One month',
        any_short: 'All',
        any_long: 'Without a restriction date',
    },

    sites_search: {
        others: 'Others',
    },

    tools: {
        merge: 'Merge',
        split: 'Split',
        merge_studies: 'Merge studies',
        merge_series: 'Merge series',
        split_studies: 'Split studies',
        split_series: 'Split series',
        merge_studies_title: 'Merging of studies',
        merge_series_title: 'Merging of series',
        split_studies_title: 'Splitting of studies',
        split_series_title: 'Splitting of series',
        keep_source: 'Keep sources',
        keep_source_secondary: 'Keep secondary sources',
        select_main_resource: 'Select main resource',
        split_study_from_series: 'Split: Merge the selected series into a single study',
        split_series_from_instances: 'Split: Merge selected instances into a single series',
        merging_studies_error: 'Error when merging studies',
        merging_series_error: 'Error when merging series',
        splitting_studies_error: 'Error when splitting studies',
        splitting_series_error: 'Error when splitting series',
    },

    modality_tools: {
        sendToModalities: 'Send studies to remote modalities',
        sendStudies: 'Send studies',
        sendDescription: 'Send these "{{length}}" studies to a remote DICOM modalities',
        selectModality: 'Select a remote DICOM modality',
        resources: 'Resources',
        sentToModalityMessage: 'Send to the modality "{{title}}"...',
        studySentSuccessfully: 'Study successfully submitted',
    },

    status: {
        succeeded: 'Succeeded',
        failed: 'Failed',
    }
};
