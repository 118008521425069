import moment from 'moment';
import * as cornerstone from 'cornerstone-core';

import {PrintOverlay} from '../models/overlay-conf';

// overlays formatters
export function formatPN(name) {
    if (!name) {
        return;
    }

    // Convert the first ^ to a ', '. String.replace() only affects
    // the first appearance of the character.
    const commaBetweenFirstAndLast = name.replace('^', ', ');

    // Replace any remaining '^' characters with spaces
    const cleaned = commaBetweenFirstAndLast.replace(/\^/g, ' ');

    // Trim any extraneous whitespace
    return cleaned.trim();
}

export function formatMoment(str, inputFormat, outputFormat = 'HH:mm:ss') {
    const timeMoment = moment(str, inputFormat);
    if (timeMoment.isValid()) return timeMoment.format(outputFormat);
    else return '';
}

export function formatNumberPrecision(value, precision) {
    if (value !== null) {
        return parseFloat(value).toFixed(precision);
    }
}

export function formatSlice(sliceThickness, sliceLocation) {
    let str =  ``;

    if (sliceLocation) str += `Loc: ${formatNumberPrecision(sliceLocation, 2)}mm `;
    if (sliceThickness) str += `Thick: ${formatNumberPrecision(sliceThickness, 2)}mm`;

    return str;
}

export function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
    } else {
        byteString = unescape(dataURI.split(',')[1]);
    }
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type: mimeString});
}

// static method to be used in printing overlay generation
export function generatePrintOverlayMetadata(imageId): PrintOverlay {
    const generalStudyModule = cornerstone.metaData.get('generalStudyModule', imageId) || {};
    const {studyDate, studyTime, studyDescription} = generalStudyModule;

    const patientModule = cornerstone.metaData.get('patientModule', imageId) || {};
    const {patientId, patientName} = patientModule;

    const institution = cornerstone.metaData.get('x00080080', imageId);

    return {
        studyDate,
        studyTime,
        studyDescription,
        patientId,
        institution,
        patientName: formatPN(patientName),
    };
}
