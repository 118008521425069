import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import {assign} from 'lodash';
import {Subscription} from 'rxjs';
import * as cornerstone from 'cornerstone-core';
import * as cornerstoneTools from 'cornerstone-tools';

import {getResizeObserver} from '@ft/core';
import {SeriesItem} from '../../classes/series-item';
import {OverlayConf} from '../../models/overlay-conf';
import {TOOL_MODE_FUNCTIONS} from '../../utils/const';
import {ToolsService} from '../../services/tools.service';
import {ToolConf} from '../../models/tool-conf';
import {ViewportState} from '../../models/viewport-conf';

const scrollToIndex = cornerstoneTools.importInternal('util/scrollToIndex');

@Component({
    selector: 'ftp-viewport',
    templateUrl: './viewport.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        './viewport.component.scss'
    ],
    host: {
        unselectable: 'on',
        onmousedown: 'return false;',
        oncontextmenu: 'return false',
        onselectstart: 'return false;',
        class: 'viewport-wrapper cornerstone-enabled-image',
    }
})
export class ViewportComponent implements OnInit, AfterViewInit, OnDestroy {
    public isLoading = false;
    public imageProgress = 0;
    public overlayConf: OverlayConf = {stackSize: 0};

    @Input() public printer: boolean;
    @ViewChild('viewport', {static: true}) public viewportRef: ElementRef;

    @Output() public stateChange: EventEmitter<ViewportState> = new EventEmitter<ViewportState>();
    @Output() public activeViewportChange: EventEmitter<any> = new EventEmitter<any>();

    private _enabled = false;
    private _imageIds: string[];
    private _activeTool: string;
    private _overlay = 'enabled';
    private _textOverlay = 'enabled';
    private _referenceLines = 'enabled';
    private _resizeSubject: Subscription;
    private _currentState: ViewportState = {};

    private _loadHandlerTimeout: any = null;

    constructor(
        private _elementRef: ElementRef,
        private _cdf: ChangeDetectorRef,
        private _toolsService: ToolsService,
        private _zone: NgZone) {
    }

    ngAfterViewInit(): void {
        this._zone.runOutsideAngular(() => {
            cornerstone.enable(this.viewportRef.nativeElement /*, {renderer: 'webgl', fillStyle: 'white'}*/);

            // events binding
            this.viewportRef.nativeElement.addEventListener(cornerstone.EVENTS.NEW_IMAGE, this._onNewImage);

            // Update image load progress-store
            cornerstone.events.addEventListener('cornerstoneimageloadprogress', this._onImageProgress);

            // Set Viewport Active
            this.viewportRef.nativeElement.addEventListener(cornerstoneTools.EVENTS.MOUSE_CLICK, this._setViewportActive);
            this.viewportRef.nativeElement.addEventListener(cornerstoneTools.EVENTS.MOUSE_DOWN, this._setViewportActive);
            this.viewportRef.nativeElement.addEventListener(cornerstoneTools.EVENTS.TOUCH_PRESS, this._setViewportActive);
            this.viewportRef.nativeElement.addEventListener(cornerstoneTools.EVENTS.TOUCH_START, this._setViewportActive);
            this.viewportRef.nativeElement.addEventListener(cornerstoneTools.EVENTS.STACK_SCROLL, this._setViewportActive);
            this.viewportRef.nativeElement.addEventListener(cornerstoneTools.EVENTS.MULTI_TOUCH_START, this._setViewportActive);

            try {
                this._configureInitialTools();
                this._setupLoadHandlers();
            } catch (error) {
                console.log(error);
            }
        });
    }

    ngOnInit(): void {
        this._resizeSubject = getResizeObserver(this._elementRef.nativeElement, 50)
            .subscribe(() => this._resizeViewports());
    }

    ngOnDestroy(): void {
        this._resizeSubject.unsubscribe();

        // disable cornerstone stuff
        cornerstoneTools.stackPrefetch.disable(this.viewportRef.nativeElement);
        cornerstoneTools.clearToolState(this.viewportRef.nativeElement, 'stackPrefetch');
        cornerstoneTools.stopClip(this.viewportRef.nativeElement);
        cornerstone.disable(this.viewportRef.nativeElement);

        // remove events
        this.viewportRef.nativeElement.removeEventListener(cornerstone.EVENTS.NEW_IMAGE, this._onNewImage);
        cornerstone.events.removeEventListener('cornerstoneimageloadprogress', this._onImageProgress);
        this.viewportRef.nativeElement.removeEventListener(cornerstoneTools.EVENTS.MOUSE_CLICK, this._setViewportActive);
        this.viewportRef.nativeElement.removeEventListener(cornerstoneTools.EVENTS.MOUSE_DOWN, this._setViewportActive);
        this.viewportRef.nativeElement.removeEventListener(cornerstoneTools.EVENTS.TOUCH_PRESS, this._setViewportActive);
        this.viewportRef.nativeElement.removeEventListener(cornerstoneTools.EVENTS.TOUCH_START, this._setViewportActive);
        this.viewportRef.nativeElement.removeEventListener(cornerstoneTools.EVENTS.STACK_SCROLL, this._setViewportActive);
        this.viewportRef.nativeElement.removeEventListener(cornerstoneTools.EVENTS.MULTI_TOUCH_START, this._setViewportActive);

        // load manager
        try {
            cornerstoneTools.loadHandlerManager.removeHandlers(this.viewportRef.nativeElement);
        } catch (e) {
        }
    }

    @Input('series')
    public set handleSeries(item: SeriesItem) {
        if (!item) return;

        this.isLoading = true;
        this._imageIds = item.stack.imageIds;
        this.overlayConf.stackSize = this._imageIds.length;

        this._cdf.markForCheck();

        cornerstone.loadAndCacheImage(item.getFirstInstance())
            .then(image => {
                // clear last series stack
                cornerstoneTools.clearToolState(this.viewportRef.nativeElement, 'stack');

                cornerstone.displayImage(this.viewportRef.nativeElement, image);
                cornerstone.reset(this.viewportRef.nativeElement);

                // different synchronizers
                this._toolsService.synchronizer.add(this.viewportRef.nativeElement);
                this._toolsService.wwwcSynchronizer.add(this.viewportRef.nativeElement);
                this._toolsService.panZoomSynchronizer.add(this.viewportRef.nativeElement);
                cornerstoneTools.addToolState(this.viewportRef.nativeElement, 'stack', item.stack);
                cornerstoneTools.stackPrefetch.enable(this.viewportRef.nativeElement);

                // scale overlay
                const overlaySetToolModeFn = TOOL_MODE_FUNCTIONS[this._overlay];
                overlaySetToolModeFn(this.viewportRef.nativeElement, this._toolsService.scaleOverlay.name);

                // reference lines
                const referenceLinesSetToolModeFn = TOOL_MODE_FUNCTIONS[this._referenceLines];
                referenceLinesSetToolModeFn(
                    this.viewportRef.nativeElement, this._toolsService.referenceLines.name, this._toolsService.referenceLines.options
                );

                // text overlay
                const textOverlaySetToolModeFn = TOOL_MODE_FUNCTIONS[this._textOverlay];
                textOverlaySetToolModeFn(
                    this.viewportRef.nativeElement, this._toolsService.textOverlay.name, {}
                );

                const viewport = cornerstone.getViewport(this.viewportRef.nativeElement);
                this._currentState = {
                    playing: false, frameRate: 24, hflip: viewport.hflip, vflip: viewport.vflip, invert: viewport.invert,
                    rotation: viewport.rotation
                };

                this.stateChange.emit(this._currentState);

                this._enabled = true;
                if (this._activeTool) this.handleActiveTool = this._activeTool;

                this.isLoading = false;
                this._cdf.markForCheck();
            });
    }

    @Input('activeTool')
    public set handleActiveTool(name) {
        this._activeTool = name;

        if (!this._enabled) return;

        // TODO remove this after (just for testing)
        const validTools = cornerstoneTools.store.state.tools.filter(tool => tool.element === this.viewportRef.nativeElement);
        const validToolNames = validTools.map(tool => tool.name);

        if (!validToolNames.includes(name)) {
            console.warn(
                `Trying to set a tool active that is not "added". Available tools include: ${validToolNames.join(',')}`
            );
        }

        // TODO better idea
        const toolInstance: ToolConf = this._toolsService.availableTools.find(item => item.name === name);
        cornerstoneTools.setToolActiveForElement(this.viewportRef.nativeElement, name, {
            mouseButtonMask: 1, synchronizationContext: toolInstance.synchronizer
        });
    }

    @Input('overlay')
    public set handleOverlay(state) {
        this._overlay = state ? 'enabled' : 'disabled';

        if (!this._enabled) return;

        const setToolModeFn = TOOL_MODE_FUNCTIONS[this._overlay];
        setToolModeFn(this.viewportRef.nativeElement, this._toolsService.scaleOverlay.name, {});
    }

    @Input('referenceLines')
    public set handleReferenceLines(state) {
        this._referenceLines = state ? 'enabled' : 'disabled';

        if (!this._enabled) return;

        const setToolModeFn = TOOL_MODE_FUNCTIONS[this._referenceLines];
        setToolModeFn(this.viewportRef.nativeElement, this._toolsService.referenceLines.name, this._toolsService.referenceLines.options);
    }

    @Input('textOverlay')
    public set handleTextOverlay(state) {
        this._textOverlay = state ? 'enabled' : 'disabled';

        if (!this._enabled) return;

        const setToolModeFn = TOOL_MODE_FUNCTIONS[this._textOverlay];
        setToolModeFn(this.viewportRef.nativeElement, this._toolsService.textOverlay.name, {});
    }

    @Input('state')
    public set handleState(state: ViewportState) {
        if (!state) {
            if (this._enabled) this.stateChange.emit(this._currentState);
            return;
        }

        const viewport = cornerstone.getViewport(this.viewportRef.nativeElement);

        viewport.hflip = state.hflip;
        viewport.vflip = state.vflip;
        viewport.invert = state.invert;
        viewport.rotation = state.rotation;

        if (state.playing) {
            const validFrameRate = Math.max(state.frameRate, 1);
            cornerstoneTools.playClip(this.viewportRef.nativeElement, validFrameRate);
        } else {
            cornerstoneTools.stopClip(this.viewportRef.nativeElement);
        }

        cornerstone.setViewport(this.viewportRef.nativeElement, viewport);
    }

    @Input('background')
    public set handleBackground(color) {
        if (!this._enabled) return;

        // Get the canvas context and reset the transform
        const enabledElement = cornerstone.getEnabledElement(this.viewportRef.nativeElement);

        console.log(color, enabledElement);

        const image = enabledElement.image;
        const context = enabledElement.canvas.getContext('2d');

        context.setTransform(1, 0, 0, 1, 0, 0);
        context.fillStyle = color;
    }

    //
    public imageSliderChange(index) {
        scrollToIndex(this.viewportRef.nativeElement, index);
    }

    // private api
    private _resizeViewports() {
        // cornerstoneTools.toolStyle.setToolWidth(5);
        // cornerstoneTools.textStyle.setFont(`35px Roboto, sans-serif`);

        cornerstone.resize(this.viewportRef.nativeElement);
    }

    private _configureInitialTools() {
        // Setup "Stack State"
        cornerstoneTools.clearToolState(this.viewportRef.nativeElement, 'stack');
        cornerstoneTools.addStackStateManager(this.viewportRef.nativeElement, ['stack', 'playClip', 'Crosshairs', 'referenceLines']);

        // setup dummy tools
        this._toolsService.availableTools.forEach(tool => {
            cornerstoneTools.addToolForElement(this.viewportRef.nativeElement, tool.class, {configuration: tool.configuration || {}});

            if (tool.mode) {
                const setToolModeFn = TOOL_MODE_FUNCTIONS[tool.mode];
                setToolModeFn(this.viewportRef.nativeElement, tool.name, tool.options || {});
            }
        });
    }

    private _setupLoadHandlers() {
        // We use this to "flip" `isLoading` to true, if our startLoading request
        // takes longer than our "loadIndicatorDelay"
        const startLoadHandler = element => {
            clearTimeout(this._loadHandlerTimeout);
            this._loadHandlerTimeout = setTimeout(() => {
                this.isLoading = true;
                this._cdf.markForCheck();
            }, 45);
        };

        const endLoadHandler = (element, image) => {
            clearTimeout(this._loadHandlerTimeout);

            this.isLoading = false;
            this._cdf.markForCheck();
        };

        cornerstoneTools.loadHandlerManager.setStartLoadHandler(startLoadHandler, this.viewportRef.nativeElement);
        cornerstoneTools.loadHandlerManager.setEndLoadHandler(endLoadHandler, this.viewportRef.nativeElement);
    }

    // events
    private _onImageProgress = (event) => {
        this.imageProgress = event.detail.percentComplete;
        this._cdf.markForCheck();
    }

    private _onNewImage = (event) => {
        const {imageId} = event.detail.image;
        const imageIndex = this._imageIds.indexOf(imageId);

        this.overlayConf = assign({}, this.overlayConf, {imageId, imageIndex});

        this._cdf.markForCheck();
    }

    private _setViewportActive = (_) => {
        this.activeViewportChange.emit(true);
        this._cdf.markForCheck();
    }
}
