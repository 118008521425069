import {TableColumnDefinition} from '@ft/core';


export const StudyTableColumns: TableColumnDefinition[] = [
    {label: 'explorer.study.patient_id', key: 'PatientID', type: 'text'},
    {label: 'explorer.study.patient_name', key: 'PatientName', type: 'text'},
    {
        label: 'explorer.study.patient_sex', key: 'PatientSexIcon', type: 'icon', class: item => [
            item.PatientSex === 'M' ? 'male' : '', item.PatientSex === 'F' ? 'female' : ''
        ]
    },
    {label: 'explorer.study.patient_birth_date', key: 'PatientBirthDate', type: 'date'},
    {label: 'explorer.study.accession_number', key: 'AccessionNumber', type: 'text'},
    {label: 'explorer.study.study_description', key: 'StudyDescription', type: 'text'},
    {label: 'explorer.study.study_series', key: 'StudySeries', type: 'badge'},
    {label: 'explorer.study.study_date', key: 'StudyDate', type: 'date'},
    {label: 'explorer.study.study_time', key: 'StudyTime', type: 'time', timeFormats: ['HHmmss', 'HH:mm:ss']},
    {label: 'explorer.study.referring_physician_name', key: 'ReferringPhysicianName', type: 'text'},
    {label: 'explorer.study.institution_name', key: 'InstitutionName', type: 'text'},
];


export const SeriesTableColumns: TableColumnDefinition[] = [
    {label: 'explorer.series.series_description', key: 'SeriesDescription', type: 'text'},
    {label: 'explorer.series.manufacturer', key: 'Manufacturer', type: 'text'},
    {label: 'explorer.series.station_name', key: 'StationName', type: 'text'},
    {label: 'explorer.series.modality', key: 'Modality', type: 'badge'},
    {label: 'explorer.series.series_number', key: 'SeriesNumber', type: 'badge'},
    {label: 'explorer.series.series_instances', key: 'SeriesInstances', type: 'badge'},
    {label: 'explorer.series.series_date', key: 'SeriesDate', type: 'date'},
    {label: 'explorer.series.series_time', key: 'SeriesTime', type: 'time', timeFormats: ['HHmmss', 'HH:mm:ss']},
];


export const InstanceTableColumns: TableColumnDefinition[] = [
    {label: 'explorer.instance.instance_number', key: 'InstanceNumber', type: 'number'},
    {label: 'explorer.instance.number_of_frames', key: 'NumberOfFrames', type: 'number'},
    {label: 'explorer.instance.image_comments', key: 'ImageComments', type: 'text'},
    {label: 'explorer.instance.file_size', key: 'FileSize', type: 'file-size'},
    {label: 'explorer.instance.index_in_series', key: 'IndexInSeries', type: 'number'},
    {label: 'explorer.instance.sop_instance_uid', key: 'SOPInstanceUID', type: 'text'}
];

export const reportTableColumns: TableColumnDefinition[] = [
    {label: 'explorer.study.study_date', key: 'StudyDate', type: 'date'},
    {label: 'explorer.study.patient_name', key: 'PatientName', type: 'text'},
    {label: 'explorer.study.patient_id', key: 'PatientID', type: 'text'},
    {
        label: 'explorer.study.patient_sex', key: 'PatientSexIcon', type: 'icon', class: item => [
            item.PatientSex === 'M' ? 'male' : '', item.PatientSex === 'F' ? 'female' : ''
        ]
    },
    {label: 'explorer.study.study_description', key: 'StudyDescription', type: 'text'},
    {label: 'explorer.study.referring_physician_name', key: 'ReferringPhysicianName', type: 'text'},
    {label: 'explorer.study.institution_name', key: 'InstitutionName', type: 'text'},
    {
        label: 'explorer.report.status',
        key: 'reportStatus.icon',
        type: 'icon',
        style: (item) => ({color: item.reportStatus.color})
    },

];
