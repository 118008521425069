<h1 mat-dialog-title translate="viewer.print.series_handle"></h1>

<form name="prompt-form" novalidate #form="ngForm" (submit)="submit()" fxLayout="column">

    <div fxLayout="row" fxLayoutGap="6px">
        <mat-form-field appearance="fill" fxFlex *ngIf="data.method !== manualSelection">
            <mat-label [translate]="'viewer.print.image_count'"></mat-label>
            <input matInput name="count" [(ngModel)]="data.count" required ftNumber="float" [fix]="0" [min]="1" [max]="series.instancesCount">
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex>
            <mat-label [translate]="'viewer.print.selection_method'"></mat-label>
            <ft-select required [config]="selectConfig" [(ngModel)]="data.method" name="method"></ft-select>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="6px" *ngIf="data.method === manualSelection">
        MANUAL SELECTION
    </div>

    <div mat-dialog-actions>
        <button mat-stroked-button color="primary" type="submit" tabindex="2" [disabled]="form.invalid">
            <mat-icon fontSet="mdi" fontIcon="mdi-check"></mat-icon>
            <span [translate]="'viewer.print.apply'"></span>
        </button>
        <button mat-stroked-button color="primary" type="button" tabindex="3" (click)="submit()" [disabled]="form.invalid" fxFlex *ngIf="false">
            <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
            <span [translate]="'viewer.print.append'"></span>
        </button>
        <button mat-stroked-button color="warn" tabindex="-1" color="warn" [mat-dialog-close]="false" type="button">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            <span [translate]="'viewer.print.cancel'"></span>
        </button>
    </div>
</form>
