<form name="prompt-form" novalidate #form="ngForm" (submit)="submit()" fxLayout="column" class="ft-busy-container">
    <div [ngBusy]="loader"></div>
    <div mat-dialog-content fxLayout="row" class="ft-busy-container">
        <div fxFlex="200px" fxLayout="column">
            <mat-form-field>
                <input matInput [placeholder]="'explorer.study.patient_id' | translate"
                       [(ngModel)]="data.PatientID" name="PatientID" required>
            </mat-form-field>

            <mat-form-field>
                <input matInput [placeholder]="'explorer.study.patient_name' | translate"
                       [(ngModel)]="data.PatientName" name="PatientName" required>
            </mat-form-field>

            <mat-form-field>
                <input matInput ftDate [matDatepicker]="dp"
                       [placeholder]="'explorer.study.patient_birth_date' | translate"
                       [(ngModel)]="data.PatientBirthDate" name="PatientBirthDate" #dateModel="ngModel">
                <mat-datepicker-toggle tabindex="-1" matSuffix [for]="dp">
                    <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
                <button matSuffix mat-icon-button [ftValueUnset]="dateModel" tabindex="-1">
                    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                </button>
            </mat-form-field>

            <div fxLayout="row" fxLayoutGap="6px">
                <mat-form-field fxFlex="50">
                    <ft-select [placeholder]="'explorer.study.patient_sex' | translate" [config]="genderSelect"
                               [(ngModel)]="data.PatientSex" name="PatientSex" #genderModel="ngModel">
                    </ft-select>
                    <button matSuffix mat-icon-button [ftValueUnset]="genderModel" tabindex="-1">
                        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field fxFlex="50">
                    <ft-select [placeholder]="'explorer.series.modality' | translate"
                               [config]="modalitiesSelect" [(ngModel)]="data.Modality"
                               name="Modality" #modelModality="ngModel">
                    </ft-select>
                    <button matSuffix mat-icon-button [ftValueUnset]="modelModality" tabindex="-1">
                        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <mat-form-field>
                <input matInput [placeholder]="'explorer.study.study_description' | translate"
                       [(ngModel)]="data.StudyDescription" name="StudyDescription"
                       #studyDescriptionModel="ngModel">
                <button matSuffix mat-icon-button [ftValueUnset]="studyDescriptionModel" tabindex="-1">
                    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field>
                <input matInput [placeholder]="'explorer.study.accession_number' | translate"
                       [(ngModel)]="data.AccessionNumber" name="AccessionNumber"
                       #accessionNumberModel="ngModel">
                <button matSuffix mat-icon-button [ftValueUnset]="accessionNumberModel" tabindex="-1">
                    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field>
                <input matInput [placeholder]="'explorer.study.referring_physician_name' | translate"
                       [(ngModel)]="data.ReferringPhysicianName" name="ReferringPhysicianName"
                       #referringPhysicianModel="ngModel">
                <button matSuffix mat-icon-button [ftValueUnset]="referringPhysicianModel" tabindex="-1">
                    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field style="margin-bottom: 0;">
                <input matInput [placeholder]="'explorer.study.institution_name' | translate"
                       [(ngModel)]="data.InstitutionName" name="InstitutionName"
                       #institutionName="ngModel">
                <button matSuffix mat-icon-button [ftValueUnset]="institutionName" tabindex="-1">
                    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="upload-section ft-busy-container" fxFlex fxLayout="column" ngfDrop ngfSelect multiple="1"
             [ngClass]="{'file-over': hasBaseDropZoneOver}" (validDragChange)="hasBaseDropZoneOver = $event"
             accept="application/pdf,image/*" [(files)]="files">
            <ng-container *ngIf="files.length == 0">
                <span translate="settings.dcm_upload.drag_and_drop"></span>
                <mat-icon fontSet="mdi" fontIcon="mdi-cloud-upload"></mat-icon>
            </ng-container>

            <ng-container *ngIf="files.length > 0">
                <span translate="settings.dcm_upload.files_count" [translateParams]="{count: files.length}"></span>
            </ng-container>
        </div>
    </div>


    <div mat-dialog-actions>
        <button mat-stroked-button color="primary" type="submit" tabindex="2" [disabled]="form.invalid || files.length == 0">
            <mat-icon fontSet="mdi" fontIcon="mdi-check"></mat-icon>
            <span [translate]="'settings.dcm_upload.dicomizer_btn'"></span>
        </button>
        <button mat-stroked-button color="warn" tabindex="-1" [mat-dialog-close]="false" type="button">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            <span [translate]="'viewer.print.cancel'"></span>
        </button>
    </div>
</form>

