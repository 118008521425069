<div [ngBusy]="loader"></div>

<div class="ft-parent-layout mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
    <mat-toolbar color="accent">
        <div class="ft-module-icon">
            <button mat-icon-button>
                <mat-icon fontSet="mdi" [fontIcon]="currentIconStatus"
                          [matTooltip]="study?.reportStatus.tooltip"></mat-icon>
            </button>
        </div>
        <h2 [matTooltip]="toolBarTitle">{{toolBarTitle}}</h2>
        <span fxFlex></span>
        <div>
            <button mat-button *ngFor="let status of statusButtons" [disabled]="!canChangeStatus"
                    (click)="update(status)">
                <mat-icon fontSet="mdi" [fontIcon]="status.icon"></mat-icon>
                <span>{{status.label | translate}}</span>
            </button>

            <button mat-icon-button (click)="downloadRep()" [disabled]="!isExist"
                    [matTooltip]="'explorer.report.download' | translate"
                    *ftAcl="{resource: 'report', action: 'get'}">
                <mat-icon fontSet="mdi" fontIcon="mdi-download"></mat-icon>
            </button>
            <button mat-icon-button (click)="printBooklet()" [disabled]="!isExist"
                    [matTooltip]="'explorer.report.print_booklet' | translate"
                    *ftAcl="{resource: 'report', action: 'print'}">
                <mat-icon fontSet="mdi" fontIcon="mdi-book-open-blank-variant"></mat-icon>
            </button>

            <button mat-icon-button (click)="print()" [disabled]="!isExist"
                    [matTooltip]="'explorer.report.print' | translate"
                    *ftAcl="{resource: 'report', action: 'print'}">
                <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
            </button>

            <button mat-icon-button [disabled]="mode == 'view'" (click)="forceSave()"
                    [matTooltip]="'explorer.report.save' | translate">
                <mat-icon fontSet="mdi" fontIcon="mdi-content-save"></mat-icon>
            </button>

            <button mat-icon-button (click)="closeEditor()" [matTooltip]="'explorer.report.close' | translate">
                <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <div fxFlex fxLayout="column" class="ft-content no-padding">
        <ft-only-office
                *ngIf="ooParams"
                [params]="ooParams"
                [loadTemplates]="mode == 'edit'"
                [saveAsTemplate]="true"
                (officeConfig)="handleDoc($event)">
        </ft-only-office>
        <img *ngIf="isApproved" alt="Approved" id="approved" src="/assets/approved.png" tabindex="0">
    </div>

</div>

