import {LoginModel} from '@ft/core';

export class ChildLoginModel extends LoginModel {

    constructor(
        public username: string, public password: string, public site: string
    ) {
        super(username, password);
    }
}
