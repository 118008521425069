import {Component} from '@angular/core';
import {
    DocParams,
    FORCE_SAVE_MESSAGE_TYPES,
    ForceSaveResponse,
    integrateVariablesPlugin,
    OnlyOfficeService,
    SearchAndReplace
} from '@ft/office';
import {ExplorerService} from '../../services/explorer.service';
import {StudyModel} from '../../models/models';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {mergeMap, of, Subscription} from 'rxjs';
import {AuthService, GeneralPurposeService, PrintingService} from '@ft/core';
import {REPORT_STATUS} from '../../models/enums';
import {TranslateService} from '@ngx-translate/core';
import {assign, cloneDeep, includes, isEmpty, reduce, trim} from 'lodash';
import {EDIT_REPORT_STATUS, EditorMode, REPORT_STATUS_FIELDS} from '../../models/fields';
import {HttpClient} from '@angular/common/http';
import {FtpSettingsService} from '../../../settings/services/settings.service';
import {patientVariablesData, StudyVariablesData} from '../../../settings/models/variables';

@Component({
    selector: 'ftp-edit-report',
    templateUrl: './edit-report.component.html',
    styleUrls: ['./edit-report.component.scss']
})
export class EditReportComponent {
    public loader: Subscription;
    public ooParams: any;
    public mode: EditorMode = 'edit';
    public currentIconStatus = 'mdi-file-document-edit';
    public study: StudyModel;
    public toolBarTitle = '';
    public isExist = false;
    public canChangeStatus = false;
    public isApproved = false;
    public statusButtons = EDIT_REPORT_STATUS;
    private _configResponse: any;
    private readonly _canEditAfterSigning: boolean;
    private _patientData = [];
    private _examData = [];
    private _variablesData = [];

    constructor(private _route: ActivatedRoute,
                private _router: Router,
                private _explorerService: ExplorerService,
                private _officeService: OnlyOfficeService,
                private _generalPurpose: GeneralPurposeService,
                private _translateService: TranslateService,
                private _snackBar: MatSnackBar,
                private _http: HttpClient,
                private _printingService: PrintingService,
                private _settingService: FtpSettingsService,
                private _authService: AuthService,
    ) {
        this._canEditAfterSigning = this._authService.aclHandler({resource: 'report', action: 'edit-after-signing'});

        this._route.queryParams
            .pipe(
                mergeMap(params => this._explorerService.getStudy(params.study)),
            ).subscribe(study => {
            this.study = study;


            this.currentIconStatus = study.reportStatus.icon;
            this.toolBarTitle = `${this.study.PatientName}: ${this.study.StudyDescription ?? '__'}`;
            this.isApproved = includes([REPORT_STATUS.VALIDATED, REPORT_STATUS.SIGNED], this.study.reportStatus.value);
            if (this.study.reportStatus.value === REPORT_STATUS.SIGNED && !this._canEditAfterSigning) {
                this.mode = 'view';
            }
            this.isExist = this.study?.reportStatus.value !== REPORT_STATUS.PENDING;
            this.openEditor();

            this._handlePluginData();
        });

    }

    public openEditor() {
        this.canChangeStatus = this.isExist && this.mode === 'edit';
        this.ooParams = this._getQueryParams();
    }

    public handleDoc(config: any) {
        this._configResponse = config;
        this._configResponse.events = assign(this._configResponse.events, {
            onDocumentStateChange: () => this.compileVariables(),
            onPluginsReady: () => {
                setTimeout(() => this.compileVariables(), 200);
            }
        });
    }

    public closeEditor() {
        return this._generalPurpose.openConfirmDialog('explorer.report.quitEditorMsg')
            .subscribe(result => result ? this._router.navigate(['/explorer/studies']) : of(false));
    }

    public forceSave() {
        this._officeService.forceSaveDoc(this._configResponse.document.key)
            .subscribe((res: ForceSaveResponse) => {
                this._officeService.handleForceSaveResponse(res);
                if (res.error === FORCE_SAVE_MESSAGE_TYPES.NO_ERROR) {
                    this.canChangeStatus = true;
                    this.isExist = true;
                }
            });
    }

    public updateStatus(status) {
        this._explorerService.updateReportStatus(this.study.ID, status.value).subscribe(report => {
            this.currentIconStatus = REPORT_STATUS_FIELDS.find(rep => rep.value === report.status).icon;
            this.isApproved = !!includes([REPORT_STATUS.VALIDATED, REPORT_STATUS.SIGNED], report.status);
            this._snackBar.open(this._translateService.instant('explorer.report.statusChangedTo',
                {value: this._translateService.instant(status.label)}));
        });
    }

    public update(status) {
        this.forceSave();
        this.updateStatus(status);
        // Avoid reloading the editor as it may not save the report after forced saving, even the response says saving was done
    }

    public print() {
        this._explorerService.printReport(this.study.ID);
    }

    public printBooklet() {
        const context = reduce(this._variablesData, (result, valueData, key) => {
            const varKey = trim(valueData.key, '{}');
            result[varKey] = valueData.value;
            return result;
        }, {});
        this._explorerService.printBookletView(this.study.ID, JSON.stringify(context));
    }

    public downloadRep() {
        this._explorerService.downloadReport(this.study.ID)
            .subscribe(download => download());
    }

    private _getQueryParams(): DocParams {
        const fileDownUrl = `${this._explorerService.reportUrl}${this.study.ID}/download-report/`;
        return {
            officeServerHost: this._settingService.appConfig.office_server_host,
            appBaseHost: this._settingService.appConfig.pacs_host,
            id: this.study.ID,
            onlyOfficeUrl: this._explorerService.reportUrl,
            withHistory: true,
            fileUrl: fileDownUrl,
            forcesave: true,
            compactHeader: true,
            toolbarNoTabs: true,
            toolbarHideFileName: true,
            mode: this.mode,
            // plugins: ['variables-list', 'insert-compile-variables'],
        };
    }

    private _handlePluginData() {
        const studyInfo = cloneDeep(this.study);
        if (this.study.PatientBirthDate) {
            studyInfo.PatientBirthDate =
                this._explorerService.formatStringDate(this.study.PatientBirthDate as string);
        }
        if (this.study.StudyDate) {
            studyInfo.StudyDate =
                this._explorerService.formatStringDate(this.study.StudyDate as string, 'DD/MM/YYYY');
        }
        this._patientData = patientVariablesData(this._translateService, studyInfo);
        this._examData = StudyVariablesData(this._translateService, studyInfo);
        this._variablesData = [...this._patientData, ...this._examData];

        this._officeService.initPostMessage(this.sendVariablesList());

    }

    public compileVariables() {
        this._variablesData.forEach((it: any) =>
            this._officeService.sendCommand(SearchAndReplace(it.key, !isEmpty(it.value) ? `${it.value} ` : '-'))
        );
    }

    public sendVariablesList(): string {
        const data = JSON.stringify([
                {
                    name: 'Patient',
                    config: this._patientData,
                },
                {
                    name: 'Exam',
                    config: this._examData,
                },
            ]
        );
        return integrateVariablesPlugin(data);
    }
}
