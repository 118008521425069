import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, of, Subscription, timer} from 'rxjs';
import {debounce, distinctUntilChanged, mergeMap, skip, tap} from 'rxjs/operators';


import {concat, without} from 'lodash';
import {AuthService, GeneralPurposeService, SCALE_ANIMATION, TableColumnDefinition} from '@ft/core';
import {FtpSettingsService} from '../../services/settings.service';
import {ARCHIVE_COLUMNS} from '../../models/table-columns';
import {ArchiveProgressDialog} from '../../dialogs/archives-progress/archive-progress.dialog';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'ftp-archives',
    templateUrl: './archives.component.html',
    animations: [
        SCALE_ANIMATION
    ],
    styleUrls: [
        './archives.component.scss'
    ]
})
export class ArchivesComponent implements OnInit, OnDestroy {
    @ViewChild('searchForm', {static: true}) public searchForm: any;

    public isFocused = false;
    public query: any = {};
    public currentArchive: any;
    public archives: any[] = [];
    public selectedItems: any[] = [];
    public archiveSource$: Observable<any[]> = of([]);

    public archiveColumns: TableColumnDefinition[] = concat(ARCHIVE_COLUMNS as any, {
        label: '', key: 'actions', type: 'actions', actions: [
            {
                class: 'mat-primary',
                icon: 'mdi-keyboard-return',
                method: item => this.unArchive([item], false),
                acl: {resource: 'archive-task', action: 'unarchive', behavior: 'disabled'}
            },
            {
                class: 'mat-warn',
                icon: 'mdi-delete',
                method: item => this.removeArchive([item], false),
                acl: {resource: 'archive-task', action: 'delete', behavior: 'disabled'}
            }
        ]
    } as any) as any;

    private _subscription: Subscription;

    constructor(
        private _dialog: MatDialog,
        private _settingService: FtpSettingsService,
        private _generalPurpose: GeneralPurposeService,
        private _route: ActivatedRoute,
        _authService: AuthService,
    ) {
    }

    public ngOnInit() {
        this._route.queryParams
            .pipe(
                tap((params) => this.query.search = params.search),
                tap(_ => this.setArchivesStudies()),
            )
            .subscribe(() => null);

        this._subscription = this.searchForm.valueChanges
            .pipe(distinctUntilChanged(), debounce(() => timer(750)), skip(1))
            .subscribe(() => this.setArchivesStudies());
    }

    public ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    public setArchivesStudies() {
        this.archiveSource$ = this._settingService.getArchivedTaskStudies(this.query);
    }

    public unArchive(items, multi) {
        this._generalPurpose.openConfirmDialog('settings.archive.unarchive_confirm', {length: items.length})
            .pipe(
                mergeMap(result => result ? this._runUnArchive(items) : of(false))
            )
            .subscribe(() => this._successCall(items, multi));
    }

    public removeArchive(items, multi) {
        this._generalPurpose.openConfirmDialog('settings.archive.remove_confirm', {length: items.length})
            .pipe(
                mergeMap(result => result ? this._settingService.removeArchivedStudies(items) : of(false))
            )
            .subscribe(() => this._successCall(items, multi));
    }

    private _runUnArchive(items): Observable<any> {
        const dialog = this._dialog.open(ArchiveProgressDialog, {
            minWidth: '500px',
            disableClose: true,
            data: {items},
        });

        return dialog.afterClosed();
    }

    private _successCall(items, multi) {
        if (multi) this.selectedItems = [];
        else this.selectedItems = without(this.selectedItems, items[0]);

        this.setArchivesStudies();
    }

}
