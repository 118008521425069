import {Moment} from 'moment';
import {SelectConfig} from '@ft/core';
import {of} from 'rxjs';
import {REPORT_STATUS} from './enums';

const PATIENT_GENDERS: any[] = [
    {value: 'M', label: 'explorer.genders.male'}, {value: 'F', label: 'explorer.genders.female'}
];

export const PATIENT_GENDER_SELECT_CONFIG: SelectConfig = {
    key: 'value', compareKey: 'value',
    valueKey: 'value', observable: of(PATIENT_GENDERS)
};

export interface PatientModel {
    ID: string;
    PatientID: string;
    PatientName: string;
    PatientSex: string;
    PatientBirthDate: string;
    PatientStudies: number;

    MediaName: string;
}

export interface StudyModel {
    ID: string;
    AccessionNumber: string;
    InstitutionName: string;
    PatientBirthDate: string | Moment;
    PatientID: string;
    PatientName: string;
    PatientSex: string;
    ReferringPhysicianName: string;
    StudyDate: string | Moment;
    StudyDescription: string;
    StudyID: string;
    StudyInstanceUID: string;
    StudySeries: number;
    StudyTime: string;

    MediaName: string;
    StudyDateTime: number;

    // Report section
    report?: any;
    reportStatus?: ReportStatus;
    // statusColor?: string;
    // statusIcon?: string;
    // statusValue?: REPORT_STATUS;
}

export interface ReportStatus {
    color?: string;
    icon?: string;
    value?: REPORT_STATUS;
    tooltip?: string;
    label?: string;
}

export function instanceOfStudyModel(object: any): object is StudyModel {
    return 'StudyInstanceUID' in object;
}

export function instanceOfSeriesModel(object: any): object is SeriesModel {
    return 'SeriesInstanceUID' in object;
}

export function instanceOfInstanceModel(object: any): object is InstanceModel {
    return 'SOPInstanceUID' in object;
}

export interface SeriesModel {
    ID: string;
    Manufacturer: string;
    Modality: string;
    OperatorsName: string;
    ParentStudy: string;
    SeriesDate: string;
    SeriesInstanceUID: string;
    SeriesDescription: string;
    SeriesInstances: number;
    SeriesNumber: number;
    SeriesTime: string;
    StationName: string;
    Status: string;
}


export interface InstanceModel {
    ID: string;
    FileSize: number;
    ImageComments: string;
    InstanceNumber: string;
    NumberOfFrames: string;
    SOPClassUID: string;
    SOPInstanceUID: string;
    ParentSeries: string;
    IndexInSeries: string;
}

export interface DateAction {
    label: string;
    tooltip: string;
    meta: object | boolean;
}

export interface StudyQuery {
    PatientID?: string;
    PatientSex?: string;
    PatientName?: string;
    AccessionNumber?: string;
    StudyDescription?: string;
    PatientBirthDate?: Moment;
    ReferringPhysicianName?: string;

    ModalitiesInStudy?: string[];
    InstitutionName?: string[];

    RangeAction?: DateAction;
    StudyDateEnd?: Moment;
    StudyDateStart?: Moment;
    status?: any;
}

export const MODALITIES: string[] = [
    'CR', 'CT', 'DX', 'ECG', 'ES', 'MR', 'US', null
];

export const DATE_ACTIONS: DateAction[] = [
    {label: 'explorer.date_actions.one_day_short', tooltip: 'explorer.date_actions.one_day_long', meta: {days: 0}},
    {
        label: 'explorer.date_actions.three_days_short',
        tooltip: 'explorer.date_actions.three_days_long',
        meta: {days: -3}
    },
    {
        label: 'explorer.date_actions.seven_days_short',
        tooltip: 'explorer.date_actions.seven_days_long',
        meta: {days: -7}
    },
    {
        label: 'explorer.date_actions.fourteen_days_short',
        tooltip: 'explorer.date_actions.fourteen_days_long',
        meta: {days: -14}
    },
    {
        label: 'explorer.date_actions.thirty_days_short',
        tooltip: 'explorer.date_actions.thirty_days_long',
        meta: {days: -30}
    },
    {label: 'explorer.date_actions.any_short', tooltip: 'explorer.date_actions.any_long', meta: false},
];

export interface ExplorerConfig {
    availableModalities: any[];
    selectedModalities: any[];
    selectedInstitutions: any[];
    defaultDuration: any;
}

export interface MergeSplitDialogInput {
    title: string;
    type: 'merge'|'split';
    splitType?: 'studies'|'series';
    resources: StudyModel[] | SeriesModel[] | InstanceModel[];
}

export interface MergeSplitDialogOutput {
    keepSource: boolean;
    mainResource: string;
    resources: string[];
}
