import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';

import {get} from 'lodash';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {FtpSettingsService} from '../../services/settings.service';
import {ArchiveProgress} from '../../models/consts';

@Component({
    selector: 'ftp-archive-progress',
    templateUrl: './archive-progress.dialog.html',
    styleUrls: [
        './archive-progress.dialog.scss'
    ]
})
export class ArchiveProgressDialog implements OnInit {
    public progress: any;
    public archives: any[];

    constructor(
        private _dialogRef: MatDialogRef<ArchiveProgressDialog>, private _settingsService: FtpSettingsService,
        private _changeDetectorRef: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this.archives = get(data, 'items', []);
    }

    get currentLabel() {
        if (this.progress) return 'settings.archive.current_item';
        else return 'settings.archive.initializing';
    }

    get progressLabel() {
        if (this.progress) {
            if (this.actualProgress.uncompress) return 'settings.archive.uncompress';
            else return 'settings.archive.handling_item';
        } else return '';
    }

    ngOnInit() {
        this._settingsService.unArchiveStudies(this.archives)
            .subscribe({
                next: data => {
                    this.progress = data;
                    this._changeDetectorRef.markForCheck();
                },
                complete: () => {
                    this._settingsService.reloadInstance().subscribe(() => this._dialogRef.close());
                }
            });
    }

    getProgressValue() {
        if (this.actualProgress.uncompress) return 0;
        else return Math.round(100 * this.actualProgress.current / this.actualProgress.total);
    }

    get actualProgress(): ArchiveProgress {
        return get(this.progress, 'progress', false);
    }

}
