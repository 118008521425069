import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';

import {assign, chain, compact, get, map as loMap} from 'lodash';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';

import {AuthService, FtWsService, GeneralConfigsService, GeneralPurposeService, SelectConfig} from '@ft/core';
import {AppConfig, BURNER_REFRESH_TOPIC, HOST_KEYS, PRINT_REFRESH_TOPIC, SITE} from '../models/consts';
import {SetupConfigService} from '../../authentication/services/setup-config.service';

@Injectable()
export class FtpSettingsService {
    public appConfig: AppConfig;
    private readonly _updateSuccessLabel: string = 'settings.update_success';
    private _modalitiesUrl = '/dcm/modalities';
    private _departmentsUrl = '/api/staff/department/';
    private _servicesUrl = '/api/staff/service/';

    constructor(
        private _ws: FtWsService,
        private _http: HttpClient,
        private _snackBar: MatSnackBar,
        private _generalConfig: GeneralConfigsService,
        private _generalPurpose: GeneralPurposeService,
        private _translateService: TranslateService,
        private _authService: AuthService,
        private _setupConfigs: SetupConfigService) {
    }

    public get sitesSelectConfig(): SelectConfig {
        return {
            key: 'name',
            compareKey: 'name',
            observable: of(this.authorizedSites),
            optionContent: item => item.name
        };
    }

    public assignDefaultSiteSelection(obj: any) {
        if (!obj.site) {
            this.getStaffDefaultSite().subscribe(
                department => assign(obj, {site: department})
            );
        }
    }

    public getPacsGeneral() {
        return this._generalConfig.getAppConfig('pacs_general_settings', true)
            .pipe(
                map((data) => data || {})
            );
    }

    public setPacsGeneral(config) {
        return this._generalConfig.setAppConfig({pacs_general_settings: config})
            .pipe(
                mergeMap((data) => this.refreshConfig(data))
            );
    }

    public getStaffDefaultSite(): Observable<any> {
        const staffId = JSON.parse(localStorage.getItem('staff')).id;
        if (!staffId) return of(this.localSite);
        return this._generalPurpose.getByHttp(`/api/staff/staff-form/${staffId}/`)
            .pipe(map(data => data.service.department));
    }

    public get localSite(): any {
        const site = localStorage.getItem(SITE);
        return site ? JSON.parse(site) : null;
    }

    public get authorizedSitesNames(): string[] {
        return this.authorizedSites.map(inst => inst.name);
    }

    public get availableSites(): any[] {
        return get(this._setupConfigs, 'sites', []);
    }

    public get authorizedSites(): any[] {
        if (this._authService.isRoot() || this._authService.getUser().has_all_sites) {
            return this.availableSites;
        } else {
            return [this.localSite];
        }
    }

    public getModalities(): Observable<any[]> {
        return forkJoin([
            this._http.get(this._modalitiesUrl),
            this._http.get(`${this._modalitiesUrl}?expand`)
        ]).pipe(
            map((data) => this._modalitiesMap(data))
        );
    }

    public getHandledModalities(): Observable<any[]> {
        return this.getModalities().pipe(
            map(modalities => modalities.map(modality => new Object({
                title: modality.AET,
                host: modality.Host,
                port: modality.Port,
            })))
        );
    }

    public saveModality(modality): Observable<any> {
        return this._http.put(`${this._modalitiesUrl}/${modality.AET}`, modality)
            .pipe(
                mergeMap(() => this.getModalities())
            );
    }

    public removeModalityDialog(modality): Observable<any> {
        return this._generalPurpose.openConfirmDialog('settings.modality_remove_confirm', {title: modality.AET})
            .pipe(
                mergeMap(result => result ? this.removeModality(modality) as any : of(false))
            );
    }

    public removeModality(modality): Observable<any> {
        return this._http.delete(`${this._modalitiesUrl}/${modality.AET}`)
            .pipe(
                mergeMap(() => this.getModalities())
            );
    }

    public refreshConfig(data) {
        return this._ws.call('settings.update_config')
            .pipe(
                mergeMap(() => this.reloadInstance()),
                map(() => data), tap(() => this.showSnackBar('settings.configuration_updated'))
            );
    }

    public reloadInstance() {
        return this._ws.call('settings.reset_instances')
            .pipe(
                map(() => true)
            );
    }

    // hl7 related
    public getHl7Config() {
        return this._generalConfig.getAppConfig('hl7_config', true);
    }

    public setHl7Config(config) {
        return this._generalConfig.setAppConfig({hl7_config: config})
            .pipe(
                mergeMap(() => this._ws.call('settings.reset_process')),
                map(() => true)
            );
    }

    public loadAppConfig(): Observable<boolean> {
        return this._generalConfig.getAppConfig(['pacs_host', 'office_server_host'])
            .pipe(
                tap(config => {
                        this.appConfig = config || {
                            pacs_host: '',
                            office_server_host: ''
                        };
                    }
                ),
                map(config => config)
            );
    }

    // pacs external host
    public getHostConfig(key: HOST_KEYS) {
        return this._generalConfig.getAppConfig(key, true);
    }

    public setHostConfig(key: HOST_KEYS, host: string) {
        const obj = Object({});
        obj[key] = host;
        return this._generalConfig.setAppConfig(obj)
            .pipe(
                tap(res => this.appConfig[key] = host),
                map(() => true)
            );
    }

    /*public getPacsHost() {
        return this._generalConfig.getAppConfig('pacs_host', true);
    }

    public setPacsHost(host) {
        return this._generalConfig.setAppConfig({pacs_host: host})
            .pipe(
                map(() => true)
            );
    }*/

    // Ian Nodes
    public getNodes() {
        const url = '/api/settings/ian-node/';
        return this._generalPurpose.getByHttp(url);
    }

    public saveNode(node) {
        return this._generalPurpose.postHttp('/api/settings/ian-node/', node);
    }

    public removeNode(node) {
        return this._generalPurpose.openConfirmDialog('settings.ian_nodes.remove_confirm', node)
            .pipe(
                mergeMap(result => result ? this.removeNodeItem(node) as any : of(false))
            );
    }

    // Auto Routing
    public getAutoRouting(getAllItems = false) {
        const url = `/api/settings/auto-routing/?all=${getAllItems}`;
        return this._generalPurpose.getByHttp(url).pipe(
            map(routes => loMap(routes, r =>
                assign(r, {labelModalities: r.query.modalities.map(m => m.key ? m.label : this._translateService.instant(`explorer.modality_action.all`))})
            ))
        );
    }

    public saveAutoRouting(route) {
        return this._generalPurpose.postHttp('/api/settings/auto-routing/', route);
    }

    public removeRouting(route) {
        return this._generalPurpose.openConfirmDialog('settings.auto_routing.remove_confirm', route)
            .pipe(
                mergeMap(result => result ? this.removeRoutingItem(route) as any : of(false))
            );
    }

    // Department
    public getDepartments() {
        return this._generalPurpose.getByHttp(this._departmentsUrl);
    }

    public saveDepartment(department) {
        return this._generalPurpose.postHttp(this._departmentsUrl, department);
    }

    public removeDepartment(department) {
        return this._generalPurpose.openConfirmDialog('settings.department.remove_confirm', department)
            .pipe(
                mergeMap(result => result ? this._removeDepartmentItem(department) as any : of(false))
            );
    }

    // Service
    public getServices() {
        return this._generalPurpose.getByHttp(this._servicesUrl);
    }

    public saveService(service) {
        return this._generalPurpose.postHttp(this._servicesUrl, service);
    }

    public removeService(service) {
        return this._generalPurpose.openConfirmDialog('settings.service.remove_confirm', service)
            .pipe(
                mergeMap(result => result ? this._removeServiceItem(service) as any : of(false))
            );
    }

    // printer
    public getPrinters() {
        const url = '/api/printing/printer/';
        return this._generalPurpose.getByHttp(url);
    }

    public savePrinter(printer) {
        return this._generalPurpose.postHttp('/api/printing/printer/', printer);
    }

    public removePrinter(printer) {
        return this._generalPurpose.openConfirmDialog('settings.printer.remove_confirm', printer)
            .pipe(
                mergeMap(result => result ? this._removePrinterItem(printer) as any : of(false))
            );
    }

    // print scp
    public getPrintScpConfig() {
        return this._generalConfig.getAppConfig('print_scp_settings', true)
            .pipe(
                map((data) => this._filterRulesData(data))
            );
    }

    public setPrintScpConfig(config) {
        return this._generalConfig.setAppConfig({print_scp_settings: config})
            .pipe(
                mergeMap(() => config.is_active ? this.refreshInstance(PRINT_REFRESH_TOPIC) : of(true)),
                tap(() => this.showSnackBar(this._updateSuccessLabel))
            );
    }

    public refreshInstance(topic) {
        return this._ws.call(topic)
            .pipe(
                map(() => true)
            );
    }

    // archive Task
    public getTasks() {
        const url = '/api/settings/archive-task/';
        return this._generalPurpose.getByHttp(url);
    }

    public saveTask(task) {
        return this._generalPurpose.postHttp('/api/settings/archive-task/', task);
    }

    public removeTask(task) {
        return this._generalPurpose.openConfirmDialog('settings.archive.task_remove_confirm', task)
            .pipe(
                mergeMap(result => result ? this._removeTaskItem(task) as any : of(false))
            );
    }

    public getArchivedTaskStudies(query): Observable<any> {
        return this._http.get('/api/settings/archive/list-query/',
            {params: {query: JSON.stringify(query)}}
        );
    }

    public unArchiveStudies(studies): Observable<any> {
        return this._ws.call('archive.un_archive_studies', {studies});
    }

    public removeArchivedStudies(studies) {
        return this._ws.call('archive.remove_archived_studies', {studies});
    }

    // Burner
    public getBurnersConfig() {
        return this._generalPurpose.getByHttp('/api/burner-robot/burner-config/');
    }

    public getBurnersJobs() {
        return this._generalPurpose.getByHttp('/api/burner-robot/burner-config/current-jobs/')
            .pipe(
                map(items => loMap(items, i => {
                    return assign(i, {statusTranslated: el => this._translateService.instant(`settings.burner.${el.status}`)});
                }))
            );
    }

    public saveBurnerConfig(burnerConfig) {
        return this._generalPurpose.postHttp('/api/burner-robot/burner-config/', burnerConfig);
    }

    public removeBurnerConfig(burnerConfig) {
        return this._generalPurpose.openConfirmDialog('settings.burner.remove_confirm', burnerConfig)
            .pipe(
                mergeMap(result => result ? this._removeBurnerConfigItem(burnerConfig) as any : of(false))
            );
    }

    // burner scp config
    public getBurnerScpConfig() {
        return this._generalConfig.getAppConfig('burner_scp_settings', true)
            .pipe(
                map((data) => this._filterRulesData(data)),
            );
    }

    public setBurnerScpConfig(config) {
        return this._generalConfig.setAppConfig({burner_scp_settings: config})
            .pipe(
                mergeMap(() => config.is_active ? this.refreshInstance(BURNER_REFRESH_TOPIC) : of(true)),
                tap(() => this.showSnackBar(this._updateSuccessLabel))
            );
    }

    public getPrintingTemplates() {
        return this._generalPurpose.getByHttp('/api/oo/printing-models/');
    }

    public showSnackBar(label: string) {
        this._snackBar.open(this._translateService.instant(label), '', {
            duration: 2000,
            panelClass: 'ft-snackbar',
            verticalPosition: 'bottom',
            horizontalPosition: 'left'
        });
    }

    private _modalitiesMap(items) {
        return chain(items[0] as any)
            .map(item => this._modalityItemMap(item, items[1])).value();
    }

    private _modalityItemMap(item, tags) {
        return chain(item)
            .get(tags, tags[item])
            .value();
    }

    private removeNodeItem(node) {
        const url = `/api/settings/ian-node/`;
        return this._generalPurpose.deleteHttp(url, node)
            .pipe(
                map(() => true)
            );
    }

    private removeRoutingItem(route) {
        const url = `/api/settings/auto-routing/`;
        return this._generalPurpose.deleteHttp(url, route)
            .pipe(
                map(() => true)
            );
    }

    private _removeDepartmentItem(department) {
        return this._generalPurpose.deleteHttp(this._departmentsUrl, department)
            .pipe(
                map(() => true)
            );
    }

    private _removeServiceItem(service) {
        return this._generalPurpose.deleteHttp(this._servicesUrl, service)
            .pipe(
                map(() => true)
            );
    }

    private _removePrinterItem(printer) {
        const url = `/api/printing/printer/`;
        return this._generalPurpose.deleteHttp(url, printer)
            .pipe(
                map(() => true)
            );
    }

    private _removeBurnerConfigItem(burnerConfig) {
        const url = `/api/burner-robot/burner-config/`;
        return this._generalPurpose.deleteHttp(url, burnerConfig)
            .pipe(
                map(() => true)
            );
    }

    // private api
    private _removeTaskItem(task) {
        const url = `/api/settings/archive-task/`;
        return this._generalPurpose.deleteHttp(url, task)
            .pipe(
                map(() => true)
            );
    }

    private _filterRulesData(data: any) {
        if (data) {
            if (!(this._authService.isRoot() || this._authService.getUser().has_all_sites)) {
                const filteredRules = data.rules.map(rule => {
                    if (!(rule.site) || rule.site?.name === this.localSite.name) {
                        return rule;
                    }
                });
                data.rules = compact(filteredRules);
            }
            return data;
        } else return {};

    }
}
