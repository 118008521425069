import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {of, Subscription, timer} from 'rxjs';
import {SelectConfig} from '@ft/core';
import {
    BASE_SELECT_CONF,
    BORDER,
    DPI,
    EMPTY_IMAGE_DENSITY,
    FILM_TYPES,
    PAPER_ORIENTATION,
    PRINT_DESTINATION,
    PRINT_DISPLAY_MODE,
    PRINT_INTERPOLATION,
    PRINT_PRIORITY,
    SMOOTH
} from '../../models/print-config';
import {BOOKLET_PRINT_OPTION, PrintOption} from '../../classes/print-option';
import {debounce, distinctUntilChanged} from 'rxjs/operators';
import {ViewportState} from '../../models/viewport-conf';
import {assign, first, map} from 'lodash';
import {ToolsService} from '../../services/tools.service';
import {NgForm} from '@angular/forms';

@Component({
    exportAs: 'print-options',
    selector: 'ftp-print-options',
    templateUrl: './print-options.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        './print-options.component.scss'
    ]
})
export class PrintOptionsComponent implements OnInit, OnDestroy {
    @ViewChild('optionsForm', {static: true}) public optionsForm: NgForm;
    @Output() public optionChange: EventEmitter<PrintOption> = new EventEmitter<PrintOption>();

    public bookletPrintOption = BOOKLET_PRINT_OPTION;
    public activeToolName = 'Wwwc';
    public isOverlayEnable = false;
    public isScaleOverlayEnable = false;
    public options: PrintOption = new PrintOption();
    public printerSelect: SelectConfig = {key: 'description', url: '/api/printing/printer/', autoSelect: true};

    public filmType: SelectConfig = Object.assign({}, BASE_SELECT_CONF, {observable: of(FILM_TYPES)});
    public priority: SelectConfig = Object.assign({}, BASE_SELECT_CONF, {observable: of(PRINT_PRIORITY)});
    public destination: SelectConfig = Object.assign({}, BASE_SELECT_CONF, {observable: of(PRINT_DESTINATION)});

    public filmSize: SelectConfig = {key: 'file_size_id', compareKey: 'file_size_id', observable: of([])};
    public orientation: SelectConfig = Object.assign({}, BASE_SELECT_CONF, {observable: of(PAPER_ORIENTATION)});
    public displayMode: SelectConfig = Object.assign({}, BASE_SELECT_CONF, {observable: of(PRINT_DISPLAY_MODE)});
    public interpolation: SelectConfig = Object.assign({}, BASE_SELECT_CONF, {observable: of(PRINT_INTERPOLATION)});
    public smooth: SelectConfig = Object.assign({}, BASE_SELECT_CONF, {observable: of(SMOOTH)});
    public border: SelectConfig = Object.assign({}, BASE_SELECT_CONF, {observable: of(BORDER)});
    public dpi: SelectConfig = Object.assign({}, BASE_SELECT_CONF, {observable: of(DPI)});
    public emptyImageDensity: SelectConfig = Object.assign({}, BASE_SELECT_CONF, {valueKey: 'value', observable: of(EMPTY_IMAGE_DENSITY)});

    @Output() public overlayChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public currentToolChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() public scaleOverlayChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() public withViewerTools = true;
    @Input() public isBooklet = false;

    // state related
    @Input() public state: ViewportState = {};
    @Output() public stateChange: EventEmitter<ViewportState> = new EventEmitter<ViewportState>();

    private _subscription: Subscription;

    get isWwwcSynchronizerEnable() {
        return this._toolsService.wwwcSynchronizer.enabled;
    }

    get isPanZoomSynchronizerEnable() {
        return this._toolsService.panZoomSynchronizer.enabled;
    }

    constructor(private _toolsService: ToolsService) {
    }

    ngOnInit() {
        this._subscription = this.optionsForm.valueChanges
            .pipe(distinctUntilChanged(), debounce(() => timer(50)))
            .subscribe(() => this.optionChange.emit(this.options));
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }


    @Input('currentTool')
    public set handleCurrentTool(currentTool: string) {
        if (!currentTool) return;
        this.activeToolName = currentTool;
    }

    public setActiveTool(name) {
        this.activeToolName = name;
        this.currentToolChange.emit(name);
    }

    public setState(property, value) {
        this.state = assign({}, this.state, {[property]: value});
        this.stateChange.emit(this.state);
    }

    public enableWwwcSynchronizer() {
        this._toolsService.wwwcSynchronizer.enabled = !this._toolsService.wwwcSynchronizer.enabled;
    }

    public enablePanZoomSynchronizer() {
        this._toolsService.panZoomSynchronizer.enabled = !this._toolsService.wwwcSynchronizer.enabled;
    }

    public enableScaleOverlay() {
        this.isScaleOverlayEnable = !this.isScaleOverlayEnable;
        this.scaleOverlayChange.emit(this.isScaleOverlayEnable);
    }

    public enableOverlay() {
        this.isOverlayEnable = !this.isOverlayEnable;
        this.overlayChange.emit(this.isOverlayEnable);
    }

    // config related
    public updateConfig() {
        const types = map(this.options.Printer.supported_types, item => new Object({value: item}));
        const destinations = map(this.options.Printer.supported_destinations, item => new Object({value: item}));

        this.options.MediumType = first(types);
        this.options.FilmDestination = first(destinations);
        this.options.FilmSizeID = first(this.options.Printer.supported_papers);

        this.filmType = assign({}, this.filmType, {observable: of(types)});
        this.destination = assign({}, this.destination, {observable: of(destinations)});
        this.filmSize = assign({}, this.filmSize, {observable: of(this.options.Printer.supported_papers)});
    }
}
