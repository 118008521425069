<div [ngBusy]="loader"></div>

<mat-drawer-container fxFlex [class.is-printing]="isPrinting">
    <mat-drawer mode="side" [opened]="isOpen" [disableClose]="true" [style]="{'display':  !isStudy?'none':''}">
        <ftp-viewer-series [isPrint]="false" [series]="series" [activeSeries]="activeSeries" [printer]="true"
                           (errorSeries)="handleErrorSeries($event)" (isCompleted)="handleCompleted($event)" #seriesRef>
        </ftp-viewer-series>
    </mat-drawer>
    <mat-drawer-content fxLayout="column" [@slideContent]="animationState"
                        (@slideContent.start)="onAnimationStart($event)"
                        (@slideContent.done)="onAnimationDone($event)">
        <ng-container *ngIf="!isStudy">
            <ft-pdf-viewer [src]="reportUrl"></ft-pdf-viewer>
        </ng-container>

        <div class="print-viewport-container" #containerRef [ngClass]="{'hide-items': !isStudy}">
            <div class="print-viewport-scroller" #viewportsRef>
                <div fxLayout="row wrap" class="paper-layout" [ngStyle]="paperStyle"
                     *ngFor="let paper of papers; let i = index">
                    <ftp-viewport *ngFor="let viewport of paper.viewports; trackBy:trackByFn"
                                  [ngStyle]="viewport.style"
                                  [id]="viewport.id"
                                  [series]="viewport.series" [printer]="true" [(state)]="viewport.state"
                                  [activeTool]="activeToolName" [referenceLines]="false"
                                  [overlay]="scaleOverlay" [textOverlay]="overlay" [background]="background"
                                  (activeViewportChange)="activeViewport = viewport" class="print-viewport">
                    </ftp-viewport>
                    <ftp-print-overlay [imageId]="overlayImageId" *ngIf="!overlay"></ftp-print-overlay>

                    <button mat-mini-fab color="warn" (click)="removePaper(i)" *ngIf="papers.length > 1"
                            class="remove-button">
                        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                    </button>
                </div>
            </div>

            <button mat-mini-fab color="primary" (click)="addPaper()" class="add-button">
                <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
            </button>
        </div>
    </mat-drawer-content>

    <mat-drawer mode="side" position="end" [opened]="isOpen" [disableClose]="true">
        <ftp-print-options fxFlex (optionChange)="handleOptionChange($event)" #options="print-options"
                           [withViewerTools]="isStudy" [isBooklet]="isBooklet"
                           [(state)]="activeViewport.state" [(currentTool)]="activeToolName"
                           (scaleOverlayChange)="scaleOverlay = $event" (overlayChange)="overlay = $event">
        </ftp-print-options>

        <div class="print-action-buttons" fxLayout="row" fxLayoutGap="6px">
            <ng-container *ngIf="isStudy && !isBooklet">
                <button fxFlex mat-raised-button color="primary" class="has-icon" (click)="runPrint()"
                        *ftAcl="{resource: 'printer', action: 'print', behavior: 'disabled'}"
                        [disabled]="options.optionsForm.invalid" [matTooltip]="'viewer.print.dicom'| translate">
                    <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
                    <span>{{'viewer.print.launch' | translate}}</span>
                </button>
            </ng-container>

            <button mat-raised-button color="primary" class="icon-only" (click)="runLocalPrint('cups')"
                    [disabled]="options.optionsForm.invalid"
                    [matTooltip]="'viewer.print.cups'| translate"
                    *ftAcl="{resource: 'printer', action: 'print', behavior: 'disabled'}">
                <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
            </button>
            <ng-container *ngIf="isStudy">
                <button mat-raised-button color="primary" class="icon-only" (click)="runLocalPrint('chrome')"
                        [matTooltip]="'viewer.print.navigator'| translate"
                        *ftAcl="{resource: 'printer', action: 'generate-image', behavior: 'disabled'}">
                    <mat-icon fontSet="mdi" fontIcon="mdi-printer-eye"></mat-icon>
                </button>
            </ng-container>

            <button fxFlex mat-raised-button color="warn" class="has-icon" (click)="close()">
                <mat-icon fontSet="mdi" fontIcon="mdi-chevron-double-right"></mat-icon>
                <span>{{'viewer.print.cancel' | translate}}</span>
            </button>
        </div>

    </mat-drawer>

</mat-drawer-container>
