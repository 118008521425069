import {ChangeDetectorRef, Component} from '@angular/core';
import {assign, concat, debounce, reduce} from 'lodash';
import {from, of, Subscription} from 'rxjs';

import {UploadInstance} from '../../models/upload-instance';
import {UPLOAD_ITEM_ANIMATION} from '../../animations/upload-transition';
import {map, mergeMap, tap} from 'rxjs/operators';
import {UploadHandlerService} from '../../services/upload-handler.service';
import {DicomizerDialog} from '../../dialogs/dicomizer/dicomizer.dialog';
import {PROMPT_DIALOG} from '@ft/core';
import {MatDialog} from '@angular/material/dialog';


@Component({
    selector: 'ftp-dcm-upload',
    templateUrl: './dcm-upload.component.html',
    styleUrls: [
        './dcm-upload.component.scss'
    ],
    animations: [
        UPLOAD_ITEM_ANIMATION
    ]
})
export class DcmUploadComponent {
    constructor(
        private _dialog: MatDialog,
        private _cdRef: ChangeDetectorRef,
        private _service: UploadHandlerService,
    ) {
    }

    public total: number = null;
    public files: File[] = null;
    public currentFile: UploadInstance = null;

    public hasBaseDropZoneOver = false;
    public subscription: Subscription | Promise<any> = null;
    public handleItems = debounce((event, isFiles) => this._handleItems(event, isFiles));

    // copied code to support files/folder upload
    private static _handleEntries(event) {
        return Array.from(event.dataTransfer.items)
            .filter((item: DataTransferItem) => item.kind === 'file')
            .map((item: DataTransferItem) => item.webkitGetAsEntry());
    }

    private _handleItems(event, isFiles) {
        const entries = isFiles ? event : DcmUploadComponent._handleEntries(event);
        const subject = isFiles ? of(event) : from(this._buildTree(entries)).pipe(
            map(items => this._inlineTree(items))
        );

        subject
            .pipe(
                tap(items => this.total = items.length),
                map(items => items.map((item, index) => new UploadInstance(item, index))),
                mergeMap(items => this._service.uploadFiles(items))
            )
            .subscribe({
                next: data => this.currentFile = data,
                complete: () => {
                    this.files = [];
                    this.currentFile = null;
                }
            });
    }

    // dicomizer related
    public dicomizer() {
        const dialogRef = this._dialog.open(DicomizerDialog, assign(PROMPT_DIALOG, {
            data: {},
            minWidth: '650px',
            disableClose: true,
        }));
    }

    private _inlineTree(tree) {
        return reduce(tree.directories, (files, directory) => {
            return concat(files, this._inlineTree(directory));
        }, tree.files);
    }

    private _parseFileEntry(fileEntry) {
        return new Promise((resolve, reject) => {
            fileEntry.file(
                file => resolve(file), err => reject(err)
            );
        });
    }

    private _parseDirectoryEntry(directoryEntry) {
        const directoryReader = directoryEntry.createReader();
        return new Promise((resolve, reject) => {
            directoryReader.readEntries(
                entries => resolve(this._buildTree(entries)), err => reject(err)
            );
        });
    }

    private _buildTree(entries) {
        const promises = [];
        const tree = {files: [], directories: []};
        entries.forEach(entry => {
            if (entry.isFile) {
                const promise = this._parseFileEntry(entry).then(file => {
                    tree.files.push(file);
                });

                promises.push(promise);
            } else if (entry.isDirectory) {
                const promise = this._parseDirectoryEntry(entry).then(directory => {
                    tree.directories.push(directory);
                });

                promises.push(promise);
            }
        });

        return Promise.all(promises).then(() => tree);
    }
}
