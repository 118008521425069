import {Component, OnInit} from '@angular/core';
import {AppConfigsService, AuthService, BaseLoginComponent} from '@ft/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {SITE} from '../../../settings/models/consts';
import {ChildLoginModel} from '../../models/authentication-models';
import {SetupConfigService} from '../../services/setup-config.service';

@Component({
    selector: 'ftp-login-child',
    templateUrl: './login-child.component.html',
    styleUrls: ['./login-child.component.scss']
})
export class LoginChildComponent extends BaseLoginComponent implements OnInit {

    public sites: any = [];
    public model: ChildLoginModel = new ChildLoginModel('', '', null);

    constructor(
        protected router: Router,
        protected snackBar: MatSnackBar,
        protected authService: AuthService,
        protected translate: TranslateService,
        activeRoute: ActivatedRoute,
        appConfig: AppConfigsService,
        private _setupConfigService: SetupConfigService) {
        super(router, snackBar, authService, translate, activeRoute, appConfig);

        this.sites = this._setupConfigService.sites;
        localStorage.removeItem(SITE);
    }

    public ngOnInit() {
        super.ngOnInit();
        this.errorMsg = this.translate.instant('authentication.login.error');
    }

    public onSubmit() {
        super.onSubmit();

        const site = this.sites.find(s => s.name === this.model.site);
        if (site) localStorage.setItem(SITE, JSON.stringify(site));

    }

}
