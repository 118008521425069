import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {generatePrintOverlayMetadata} from '../../utils/helpers';
import {PrintOverlay} from '../../models/overlay-conf';

@Component({
    selector: 'ftp-print-overlay',
    templateUrl: './print-overlay.component.html',
    styleUrls: [
        './print-overlay.component.scss'
    ]
})
export class PrintOverlayComponent implements OnChanges {
    @Input() public imageId: string;
    public metadata: PrintOverlay = {};

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.imageId && changes.imageId.currentValue) {
            this.metadata = generatePrintOverlayMetadata(this.imageId);
        }
    }

}
