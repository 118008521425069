import {Component, Inject, OnInit} from '@angular/core';

import {Moment} from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigsService, GeneralPurposeService, ToolbarConfig} from '@ft/core';
import {SwUpdate} from '@angular/service-worker';
import {DateAdapter} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DOCUMENT} from '@angular/common';
import {DARK_THEME} from './settings/models/consts';

@Component({
    selector: 'ftp-root-app',
    template: `
        <router-outlet></router-outlet>
    `,
    host: {'[class.dark-theme]': 'isDarkTheme'},
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    constructor(
        private _swUpdate: SwUpdate,
        private _generalPurpose: GeneralPurposeService,
        translate: TranslateService,
        appConfig: AppConfigsService,
        adapter: DateAdapter<Moment>,
        momentAdapter: MomentDateAdapter,
        @Inject(DOCUMENT) private _document: Document) {

        if (this._document && this.isDarkTheme) {
            this._document.body.classList.add('dark-theme');
        }

        translate.use(appConfig.appLang);
        translate.setDefaultLang(appConfig.appLang);

        adapter.setLocale(appConfig.appLang);
        momentAdapter.setLocale(appConfig.appLang);
    }

    public get isDarkTheme(): boolean {
        return JSON.parse(localStorage.getItem('dark-theme') || 'false');
    }

    ngOnInit() {
        if (this._swUpdate.isEnabled) {
            this._swUpdate.available.subscribe(() => {
                this._generalPurpose.showAppCodeUpdateToast();
            });
        }
    }
}

// main component
@Component({
    selector: 'ftp-main',
    host: {
        class: 'ft-main-component',
    },
    template: `
        <ft-app-toolbar [config]="toolbarConfig"></ft-app-toolbar>
        <router-outlet></router-outlet>
    `,
})
export class FtpMainComponent {
    public toolbarConfig: ToolbarConfig;

    constructor() {
        this.toolbarConfig = {
            logo: '/assets/logo.png',
            links: [
                {
                    label: 'shared.explorer',
                    path: '/explorer',
                    acl: {resource: 'explorer', action: 'get', class: 'mat-tab-disabled'}
                },
                {
                    label: 'shared.archives',
                    path: '/archives',
                    acl: {resource: 'archive-task', action: 'get', class: 'mat-tab-disabled'}
                },
                {
                    label: 'shared.dcm_upload',
                    path: '/instances-upload',
                    acl: {resource: 'explorer', action: 'create', class: 'mat-tab-disabled'}
                },
                {
                    label: 'shared.reports',
                    path: '/reports',
                    acl: {resource: 'report', action: 'get', class: 'mat-tab-disabled'},
                },
            ],
            actions: [
                {
                    class: 'params',
                    icon: 'mdi-cog',
                    tooltip: 'settings.label',
                    href: '/settings',
                    acl: {resource: 'app-setup', action: 'update'}
                },
                {
                    class: 'params',
                    icon: 'mdi-brightness-4',
                    tooltip: 'settings.theme',
                    method: () => {
                        const darkTheme = JSON.parse(localStorage.getItem(DARK_THEME));
                        localStorage.setItem(DARK_THEME, JSON.stringify(!darkTheme));
                        if (!darkTheme) {
                            document.body.classList.add('dark-theme');
                        } else {
                            document.body.classList.remove('dark-theme');
                        }
                    }
                }
            ],
            phoneNumbers: ['0665 02 71 92', '0663 43 13 00', '0522 29 46 15']
        };
    }

}
