<div *ngFor="let item of series; let i=index" #seriesThumbnails class="series-item cornerstone-enabled-image"
     oncontextmenu="return false"
     unselectable="on" onselectstart="return false;"
     onmousedown="return false;" [ngClass]="{'active': activeSeries.includes(item.id)}" [id]="item.id" [attr.series]="item.seriesId">

    <div class="image-container" oncontextmenu="return false">
        <img />
        <span class="loading-holder" translate="viewer.series.loading"></span>
    </div>

    <span class="series-instances-count" [class.is-print]="isPrint">{{item.instancesCount}}</span>

    <mat-checkbox *ngIf="!printer" class="series-checkbox" (change)="updateCheckedItems(item)"></mat-checkbox>
    <button *ngIf="isPrint" mat-icon-button [matTooltip]="'viewer.print.series_handle' | translate" (click)="seriesPrint(item)">
        <mat-icon fontSet="mdi" fontIcon="mdi-layers"></mat-icon>
    </button>
    <span class="series-description" [matTooltip]="item.description">{{item.description}}</span>
</div>
