import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';

import {JwtModule} from '@auth0/angular-jwt';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import {
    AppConfigsService,
    FtAuthModule,
    FtCoreModule,
    ftTranslateFactory,
    FtWsConnectionService,
    FtWsModule,
    JwtConfig,
    POST_AUTH_CONFIG,
    WS_CONFIG
} from '@ft/core';

import {lang} from './i18n';
import {AppComponent, FtpMainComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {FtpExplorerModule} from './explorer/explorer.module';
import {FtpSettingsModule} from './settings/settings.module';
import {FtpViewerModule} from './viewer/viewer.module';
import {ServiceWorkerModule} from '@angular/service-worker';

import {environment} from '../environments/environment';
import {ExplorerService} from './explorer/services/explorer.service';
import {AuthenticationModule} from './authentication/authentication.module';
import {SetupConfigService} from './authentication/services/setup-config.service';
import {FtpSettingsService} from './settings/services/settings.service';

const translateConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: ftTranslateFactory(lang)
    }
};

export function postAuthFactory(explorerService: ExplorerService, settingsService: FtpSettingsService) {
    return [
        explorerService.loadUiConfig(),
        settingsService.loadAppConfig()
    ];
}

@NgModule({
    declarations: [
        AppComponent,
        FtpMainComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,

        AppRoutingModule,
        JwtModule.forRoot(JwtConfig),
        TranslateModule.forRoot(translateConfig),
        FtWsModule.forRoot({
            provide: WS_CONFIG, useFactory: () => new Object({path: 'ws'})
        }),
        FtCoreModule,
        AuthenticationModule,
        FtAuthModule.forRoot(
            {
                provide: POST_AUTH_CONFIG, deps: [ExplorerService, FtpSettingsService], useFactory: postAuthFactory
            }
        ),
        FtpExplorerModule,
        FtpSettingsModule,
        FtpViewerModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
    ],
    providers: [
        {
            multi: true,
            provide: APP_INITIALIZER,
            deps: [SetupConfigService],
            useFactory: (config: SetupConfigService) => () => config.load('/api/setup-config/'),
        },
        {
            multi: true,
            provide: APP_INITIALIZER,
            deps: [AppConfigsService],
            useFactory: (config: AppConfigsService) => () => config.load('/api/system/'),
        },
        {
            multi: true,
            provide: APP_INITIALIZER,
            deps: [FtWsConnectionService],
            useFactory: (config: FtWsConnectionService) => () => config.connect().toPromise(),
        }
    ],
    bootstrap: [AppComponent]
})
export class FtpAppModule {
    constructor() {
    }
}
