<div class="ft-parent-layout mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
    <mat-toolbar color="accent">
        <div class="ft-module-icon">
            <button mat-icon-button>
                <mat-icon fontSet="mdi" fontIcon="mdi-archive"></mat-icon>
            </button>
        </div>
        <h2 translate="settings.archive.label"></h2>
        <span fxFlex></span>
    </mat-toolbar>

    <div fxFlex fxLayout="column" class="ft-content no-padding">
        <mat-drawer-container class="ft-content-container" fxFlex>
            <mat-drawer-content fxLayout="column" class="ft-padding ft-content ft-table-container">
                <form #searchForm="ngForm" [ngFormOptions]="{updateOn: 'change'}" fxLayout="row"
                      class="ft-table-search-bar" [class.focused]="isFocused">
                    <mat-icon fontSet="mdi" fontIcon="mdi-magnify" [class.mat-primary]="isFocused"></mat-icon>

                    <input type="text" [(ngModel)]='query.search' [placeholder]="'settings.archive.search' | translate"
                           name="search"
                           (focus)="isFocused = true" (blur)="isFocused = false" [ftAutoFocus]="currentArchive" fxFlex/>

                    <div class="ft-datepicker-container">
                        <input ftDate [(ngModel)]='query.study_date' [matDatepicker]="dp"
                               [placeholder]="'settings.archive.study_date_search' | translate" name="study_date">
                        <mat-datepicker-toggle tabindex="-1" matSuffix [for]="dp">
                            <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-menu-down"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #dp></mat-datepicker>
                    </div>

                    <button mat-icon-button color="warn" (click)="query = {}">
                        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                    </button>

                    <button mat-icon-button color="primary"
                            *ftAcl="{resource: 'archive-task', action: 'unarchive'}"
                            [disabled]="selectedItems.length == 0" (click)="unArchive(selectedItems, true)">
                        <mat-icon fontSet="mdi" fontIcon="mdi-keyboard-return"></mat-icon>
                    </button>

                    <button mat-icon-button color="warn"
                            *ftAcl="{resource: 'archive-task', action: 'delete'}"
                            [disabled]="selectedItems.length == 0" (click)="removeArchive(selectedItems, true)">
                        <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
                    </button>
                </form>

                <ft-generic-table fxFlex
                                  [remote]="true"
                                  [source]="archiveSource$"
                                  [hasSearch]="false"
                                  [hasPagination]="false"
                                  [selectable]="true"
                                  [(selection)]="selectedItems"
                                  [columns]="archiveColumns">
                </ft-generic-table>

            </mat-drawer-content>
        </mat-drawer-container>
    </div>
</div>
