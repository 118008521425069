import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable, of, timer} from 'rxjs';
import {FtWsService, SelectConfig, TableColumnDefinition} from '@ft/core';
import {reportTableColumns} from '../../models/columns';
import {debounce, distinctUntilChanged, map, tap} from 'rxjs/operators';
import {ExplorerService} from '../../services/explorer.service';
import {DATE_ACTIONS, DateAction, StudyQuery} from '../../models/models';
import {assign, concat, without} from 'lodash';
import {DateAdapter} from '@angular/material/core';
import {REPORT_STATUS_FIELDS} from '../../models/fields';
import {TranslateService} from '@ngx-translate/core';
import {REPORT_QUERY_STORED} from '../../../settings/models/consts';
import {REPORT_STATUS} from '../../models/enums';

@Component({
    selector: 'ftp-reports-list',
    templateUrl: './reports-list.component.html',
    styleUrls: ['./reports-list.component.scss']
})
export class ReportsListComponent implements OnInit {

    @ViewChild('searchForm', {static: true}) public searchForm: any;
    public isFocused = false;
    public query: StudyQuery = {};
    public dateActions: DateAction[] = DATE_ACTIONS;
    public listStatus: any[] = [];
    public reportStatus: any[] = REPORT_STATUS_FIELDS;
    public institutions: any[] = [];
    public AVAILABLE_SITES_CONFIG: SelectConfig = {
        key: 'label', compareKey: 'value', observable: of([])
    };
    public availableInstitutionsConfig: SelectConfig = assign({}, this.AVAILABLE_SITES_CONFIG);
    public selectedInstitutionsConfig: SelectConfig = assign({}, this.availableInstitutionsConfig, {observable: of([])});

    public source$: Observable<any[]> = of([]);
    public reportColumns: TableColumnDefinition[] = concat(reportTableColumns as any, {
        label: '', key: 'actions', type: 'actions', actions: [
            {
                class: 'mat-accent',
                icon: 'mdi-file-document-edit',
                tooltip: this._translateService.instant('explorer.report.edit'),
                method: item => this._explorerService.editReport(item, 'current'),
                acl: {resource: 'report', action: 'update', behavior: 'disabled'}
            },
            {
                class: 'mat-accent',
                icon: 'mdi-open-in-new',
                tooltip: this._translateService.instant('explorer.report.editNewTab'),
                method: item => this._explorerService.editReport(item, 'new'),
                acl: {resource: 'report', action: 'update', behavior: 'disabled'}
            },
            {
                class: 'mat-accent',
                icon: 'mdi-printer',
                tooltip: this._translateService.instant('explorer.report.print'),
                isDisabled: item => item.reportStatus.value === REPORT_STATUS.PENDING,
                method: item => this._explorerService.printReport(item.ID),
                acl: {resource: 'report', action: 'print', behavior: 'disabled'},
            },
            {
                class: 'mat-warn',
                icon: 'mdi-delete',
                tooltip: 'explorer.actions.delete',
                method: item => this._deleteReportItem(item),
                acl: {resource: 'report', action: 'delete', behavior: 'disabled'}
            }
        ]
    } as any) as any;

    constructor(private _explorerService: ExplorerService, private _adapter: DateAdapter<any>,
                private _translateService: TranslateService, private _ws: FtWsService) {

        this.selectedInstitutionsConfig = assign({}, this.selectedInstitutionsConfig, {
            observable: of(this._explorerService.siteFilterOptions)
        });

        this.query = this._explorerService.getLastQueryStored(REPORT_QUERY_STORED);
        this._explorerService.handleSearchByLastStoredQuery(this.query, this.institutions);

    }

    public ngOnInit(): void {
        this.searchForm.valueChanges
            .pipe(
                distinctUntilChanged(), debounce(() => timer(750)),
                map(() => this._explorerService.mapQuery(this.query)),
                tap(() => this._explorerService.saveQuery(REPORT_QUERY_STORED, this.query))
            ).subscribe(_ => this._setSourcesQuery()
        );

        // this.handleCreationFromTemplate();
    }

    // public handleCreationFromTemplate() {
    //     // this._onlyOfficeService.listenCreationFromTemplate().subscribe(data => {
    //     this._ws.observe('office.create_from_template').subscribe(data => {
    //       console.log('ddd', data)
    //         // window.close();
    //         // this.destroyEditorFile();
    //         // this._readDoc();
    //     });
    // }

    public isDateSelected(action: DateAction) {
        return this._explorerService.isDateSelected(action, this.query);
    }

    // search related
    public resetDateRange(action: DateAction) {
        this._explorerService.resetDateRange(action, this.query);
    }

    public filterReports(status: any, element) {
        const elementClass = element._elementRef.nativeElement.classList;
        if (this.listStatus.includes(status)) {
            this.listStatus = without(this.listStatus, status);
            elementClass.remove('mat-primary');
        } else {
            this.listStatus.push(status);
            elementClass.add('mat-primary');
        }

        this._setSourcesQuery();
    }

    public updateSelectedInstitution() {
        this._explorerService.handleSelectedInstitutionQuery(this.institutions, this.query);
        this.searchForm.valueChanges.next(this.query.InstitutionName);
    }

    private _setSourcesQuery() {
        const query = this._explorerService.mapQuery(this.query);
        this.source$ = this._explorerService.filterReports(query, this.listStatus);
    }

    private _deleteReportItem(item) {
        this._explorerService.deleteStudyReport(item).subscribe(value => {
            if (value) {
                this._setSourcesQuery();
            }
        });
    }

}
