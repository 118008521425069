<div class="logo">
    <img src="/assets/logo.png" alt="">
</div>

<form name="prompt-form" novalidate #form="ngForm" (submit)="submit()" fxLayout="column" class="ft-busy-container" *ngIf="!error">
    <div [ngBusy]="loader"></div>
    <mat-form-field appearance="fill" class="with-error-label">
        <mat-label>{{'viewer.share_dialog.link_password' | translate}}</mat-label>
        <input matInput name="password" [type]="showPassword ? 'text' : 'password'"
               [(ngModel)]="password" required minlength="8" maxlength="8" autocomplete="off"
        />

        <button matSuffix mat-icon-button type="button" (click)="handlePassword($event)" tabindex="-1">
            <mat-icon fontSet="mdi" [fontIcon]="showPassword ? 'mdi-eye' : 'mdi-eye-off'"></mat-icon>
        </button>
    </mat-form-field>

    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
        <button mat-stroked-button color="primary" type="submit" tabindex="2" [disabled]="form.invalid">
            <mat-icon fontSet="mdi" fontIcon="mdi-check"></mat-icon>
            <span translate='viewer.share_dialog.validate'></span>
        </button>
    </div>
</form>

<div *ngIf="error" class="error-block">
    <h2>{{'viewer.share_dialog.error_msg' | translate}}</h2>
</div>
