<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <mat-icon fontSet="mdi" fontIcon="mdi-disc-player"></mat-icon>
    </div>
    <h2 mat-dialog-title translate="explorer.media.robot_selection_label"></h2>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>
<form novalidate #form="ngForm" (submit)="submit()">
    <div mat-dialog-content fxLayout="column" fxLayoutGap="6px" style="position: relative;">
        <mat-form-field appearance="fill">
            <mat-label translate="explorer.media.robot_selection_placeholder"></mat-label>
            <ft-select required [config]="robotsSelectConfig" [(ngModel)]="data.robot" name="robot"></ft-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label translate="explorer.media.series_selection_placeholder"></mat-label>
            <ft-select required [config]="seriesSelectConfig" [(ngModel)]="data.series" name="series" [multiple]="true"></ft-select>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button type="submit" tabindex="2" color="primary" [disabled]="form.invalid">
            <span translate="common.validate"></span>
        </button>
        <button mat-raised-button mat-dialog-close tabindex="-1" color="warn">
            <span translate="common.cancel"></span>
        </button>
    </div>
</form>


