import {isString, map, times, uniqueId, get} from 'lodash';
import {SeriesPrintRule} from '../models/print-config';

export class SeriesItem {
    public stack: any;
    public instances: string[];

    public seriesId: string;
    public instancesCount: number;
    public id: string = uniqueId('series-');

    public modality: string;
    public description: string;
    public seriesNumber: number;
    public StudyInstanceUID: string;
    public SeriesInstanceUID: string;

    constructor(study, seriesId, tags: any, key: string | any[], frameCount?: number) {
        this.seriesId = seriesId;
        this.modality = tags.Modality;
        this.description = tags.SeriesDescription;
        this.SeriesInstanceUID = tags.SeriesInstanceUID;
        this.seriesNumber = parseInt(tags.SeriesNumber, null);
        this.StudyInstanceUID = get(study, 'MainDicomTags.StudyInstanceUID');

        if (isString(key)) this.instances = times(frameCount, index => {
            return `wadouri:/dcm/wado?requestType=WADO&objectUID=${key}&contentType=application%2Fdicom&frame=${index}`;
            // tslint:disable-next-line:max-line-length
            // return `wadors:/dcm/dicom-web/studies/${this.StudyInstanceUID}/series/${this.SeriesInstanceUID}/instances/${key}/frames/${index + 1}`;
        });
        else this.instances = map(key, item => {
            const pk = get(item, 'MainDicomTags.SOPInstanceUID');
            return `wadouri:/dcm/wado?requestType=WADO&objectUID=${pk}&contentType=application%2Fdicom`;

            // return `wadors:/dcm/dicom-web/studies/${this.StudyInstanceUID}/series/${this.SeriesInstanceUID}/instances/${pk}/frames/1`;
        });

        this.instancesCount = this.instances.length;
        this.stack = {
            imageIds: this.instances, currentImageIdIndex: 0
        };
    }

    public getFirstInstance() {
        return this.instances[this.stack.currentImageIdIndex];
    }

    public getThumbnailSrc() {
        return this.getFirstInstance(); // `${this.getFirstInstance()}/rendered`.replace('wadors:', '');
    }

    // series print related
    public generateSeriesItems(rule: SeriesPrintRule) {

    }

    private _handleIncrementalRule(rule: SeriesPrintRule) {

    }

    private _handleSlicesRule(rule: SeriesPrintRule) {

    }

    private _handleManualRule(rule: SeriesPrintRule) {

    }
}
