<h1 mat-dialog-title>
    {{currentLabel | translate: progress}}
</h1>

<div fxLayout="column" class="progress-container">
    <mat-progress-bar [mode]="actualProgress?.current ? 'determinate' : 'buffer'" [value]="getProgressValue()"></mat-progress-bar>
    <span class="status">
        {{progressLabel | translate: actualProgress}}
    </span>
</div>
