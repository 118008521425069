import * as dicomParser from 'dicom-parser';
import * as cornerstone from 'cornerstone-core';
import {external, webWorkerManager} from 'cornerstone-wado-image-loader';

export function initializeLoader() {
    external.dicomParser = dicomParser;
    external.cornerstone = cornerstone;

    const config: any = {
        startWebWorkersOnDemand: true,
        maxWebWorkers: Math.max(navigator.hardwareConcurrency - 1, 1),
        taskConfiguration: {
            decodeTask: {
                strict: false
            }
        }
    };

    webWorkerManager.initialize(config);
}

export function terminateLoader() {
    webWorkerManager.terminate();
}
