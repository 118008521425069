import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DateAdapter} from '@angular/material/core';
import {ActivatedRoute, Router} from '@angular/router';

import {forkJoin, Observable, of, Subject, throwError} from 'rxjs';
import {catchError, delay, map, mergeMap, tap} from 'rxjs/operators';


import {
    AuthService,
    FtWsService,
    GeneralConfigsService,
    GeneralPurposeService,
    NAIVE_DATE_FORMAT,
    SelectConfig,
    SMALL_DIALOG
} from '@ft/core';
import {FtFileManagerSharedService} from '@ft/file-manager';

import {
    DateAction,
    ExplorerConfig,
    InstanceModel,
    PatientModel,
    SeriesModel,
    StudyModel,
    StudyQuery
} from '../models/models';
import {PrintContainerComponent} from '../../viewer/components/print-container/print-container.component';
import {DCM_PRINT_DIALOG_DATA} from '../../viewer/services/print.service';
import {KEY_QUERY_STORED, STUDY_QUERY_STORED} from '../../settings/models/consts';
import {RobotSelectionDialog} from '../dialogs/robot-selection/robot-selection.dialog';
import {MergeSplitDialogDialog} from '../dialogs/merge-split/merge-split-dialog.dialog';
import {ModalitiesSelectionDialog} from '../dialogs/modalities-selection/modalities-selection.dialog';
import {FtpSettingsService} from '../../settings/services/settings.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ProgressStoreDialog} from '../dialogs/progress-store/progress-store.dialog';
import {REPORT_STATUS} from '../models/enums';
import {
    assign,
    chain,
    cloneDeep,
    compact,
    concat,
    differenceBy,
    find,
    get,
    includes,
    isArray,
    isEmpty,
    isEqual,
    isString,
    map as loMap,
    pick,
    size,
    split,
    trimEnd
} from 'lodash';
import {DEFAULT_STATUS, REPORT_STATUS_FIELDS} from '../models/fields';
import {PrintOption} from '../../viewer/classes/print-option';
import {PrintConfig} from '../../viewer/models/print-config';


@Injectable()
export class ExplorerService {
    public explorerConfig: ExplorerConfig;
    public reportUrl = '/api/report/';
    private _editReportRouteUrl = '/reports/edit-report';

    private _baseStudiesQuery = {Limit: 100, Expand: true, Level: 'Study'};

    constructor(
        private _router: Router,
        private _http: HttpClient,
        private _dialog: MatDialog,
        private _adapter: DateAdapter<any>,
        private _activatedRoute: ActivatedRoute,
        private _generalConfig: GeneralConfigsService,
        private _generalPurpose: GeneralPurposeService,
        private _previewDialog: FtFileManagerSharedService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _settingService: FtpSettingsService,
        private _ws: FtWsService,
        private _authService: AuthService,
    ) {
    }

    public get siteOptions(): any[] {
        return this._settingService.authorizedSites.map((site: any) => JSON.parse(JSON.stringify({
            label: site.name,
            value: site.name,
            key: site.id
        })));
    }

    public get siteFilterOptions(): any[] {
        return concat(this.siteOptions,
            {label: this._translateService.instant('explorer.sites_search.others'), value: '\\', key: ''}
        );
    }

    // patient related
    public getPatient(id): Observable<PatientModel[]> {
        return forkJoin([this._http.get(`/dcm/patients/${id}`), this._http.get(`/dcm/patients/${id}/protected`)])
            .pipe(
                map((data) => assign(this._patientItemMap(data[0]), {Protected: data[1] === 1}))
            );
    }

    public protectPatient(patient) {
        const url = `/dcm/patients/${patient.ID}/protected`;
        const data = patient.Protected ? '0' : '1';

        return this._http.put(url, data)
            .pipe(
                map(() => new Object({Protected: data === '1'}))
            );
    }

    public downloadPatientMedia(patient: PatientModel): Observable<any> {
        return this._downloadInstance(patient, 'patients');
    }

    public editReport(item, tab: 'current' | 'new') {
        const queryParams = {queryParams: {study: item.ID}};
        if (tab === 'current') {
            return this._router.navigate([this._editReportRouteUrl], queryParams);
        } else if (tab === 'new') {
            const url = this._router.createUrlTree([this._editReportRouteUrl], queryParams);
            window.open(url.toString());
        }
    }

    public filterReports(Query, listStatus: REPORT_STATUS[]): Observable<any> {
        return forkJoin([
            this._findStudies(Query),
            // isEqual(listStatus, [REPORT_STATUS.PENDING]) ?
            this._http.get(this.reportUrl)
            // : this._http.get(`${this.reportUrl}filter/`, {params: {status: listStatus}})
        ]).pipe(
            map(data => chain(this._studiesMap(data[0] as any, data[1]))
                .filter((study: StudyModel) => listStatus.length > 0 ? includes(listStatus, study.reportStatus.value) : study)
                .value()
            )
        );
    }

    public updateReportStatus(studyId, status): Observable<any> {
        return this._http.put(`${this.reportUrl}${studyId}/update/`, {status});
    }

    private _studyReportItemMap(study, reports) {
        const rep = find(reports, {uid: study.ID});

        const statusItem = rep ? REPORT_STATUS_FIELDS.find(item => item.value === rep.status) : DEFAULT_STATUS;

        return assign(study, {
            reportStatus: {
                value: statusItem.value,
                icon: statusItem.icon,
                color: statusItem.color,
                tooltip: this._translateService.instant(statusItem.tooltip)
            },
            report: rep
        });
    }

    public hideCheckboxAction(canSend: boolean = false): boolean {
        const canMergeSplit = this._authService.aclHandler({resource: 'explorer', action: 'merge'})
            || this._authService.aclHandler({resource: 'explorer', action: 'split'});
        return !(canSend ? canMergeSplit || this._authService.aclHandler({resource: 'explorer', action: 'send'})
            : canMergeSplit);
    }

    public get canGetReports(): boolean {
        return this._authService.aclHandler({resource: 'report', action: 'get'});
    }

    public getStudyIdUrl(uid): string {
        return `/dcm/studies/${uid}`;
    }

    // studies related
    public getStudies(Query): Observable<StudyModel[]> {
        return forkJoin([
            this._findStudies(Query),
            this.canGetReports ? this._http.get(`${this.reportUrl}`) : of(null)
        ])
            .pipe(
                map(data => this._studiesMap(data[0], data[1]) as any)
            );
    }

    public getArchivedStudies(query: any): Observable<any> {
        return this._http.get(`/api/settings/archive/search-archives/`,
            {
                params: {study_date: query.StudyDate ?? '', search: query.PatientName.replaceAll('*', '')}
            }
        );
    }

    public strQueryToArray(queryValue: string): any[] {
        const names = split(queryValue, '\\');
        return names.filter((element, index) => {
            return names.indexOf(element) === index;
        });
    }

    public formatStringDate(item: string, format = NAIVE_DATE_FORMAT) {
        return this._adapter.parse(item, NAIVE_DATE_FORMAT).format(format);
    }

    public mapQuery(query: StudyQuery) {
        return chain(query)
            .cloneDeep()
            .assign({StudyDate: this._handleStudyDate(query)})
            .omitBy(isEmpty)
            .omit(['StudyDateEnd', 'StudyDateStart', 'RangeAction'])
            .mapValues((item, key) => {
                if (isArray(item)) return item.join('\\');
                else if (this._adapter.isDateInstance(item)) return item.format('YYYYMMDD');
                else if (key.includes('Date')) return item;
                else return isEmpty(item) ? '*' : `*${item}*`;
            })
            .value();
    }

    public getStudy(uid) {
        return forkJoin([
            this._http.get(this.getStudyIdUrl(uid)),
            this.canGetReports ? this._http.get(`${this.reportUrl}`) : of(null)
        ])
            .pipe(
                map((data) => this._studyItemMap(data[0] as any, data[1]))
            );
    }

    public getStudyPathologies(uid) {
        return this._http.get(`/api/study/pathology/?study_id=${uid}`);
    }

    public saveStudyPathologies(uid, studyPathologies) {
        return this._http.post(`/api/study/pathology/${!!studyPathologies.id ? `${studyPathologies.id}/` : ''}`, {
            study_id: uid,
            pathologies: studyPathologies.pathologies
        });
    }

    public openSplitMergeDialog(type: 'merge' | 'split', resources, title: string, splitType?: 'studies' | 'series'): Observable<any> {
        const dialogRef = this._dialog.open(MergeSplitDialogDialog, assign(SMALL_DIALOG, {
            data: {type, resources, title, splitType},
            disableClose: true
        }));

        return this.afterClosedDialog(dialogRef);
    }

    public mergeStudiesDialog(resources: StudyModel[]): Observable<any> {
        const subject = new Subject();
        return this.openSplitMergeDialog('merge', resources, 'explorer.tools.merge_studies_title').pipe(
            tap(() => this._generalPurpose.openProgressDialog(subject)),
            mergeMap(res => this.mergeStudies(res.mainResource, res.resources, res.keepSource)),
            mergeMap(res => this.publishChanges({study: res.TargetStudy})),
            tap(() => subject.complete()),
            catchError(err => this._catchThrowErr(err, subject))
        );
    }

    public splitStudiesDialog(study: string, resources: SeriesModel[]): Observable<any> {
        const subject = new Subject();
        return this.openSplitMergeDialog('split', resources, 'explorer.tools.split_studies_title', 'studies').pipe(
            tap(() => this._generalPurpose.openProgressDialog(subject)),
            mergeMap(res => this.splitStudies(study, res.resources, res.keepSource)),
            mergeMap(res => this.publishChanges({study})),
            tap(() => subject.complete()),
            catchError(err => this._catchThrowErr(err, subject))
        );
    }

    public mergeSeriesDialog(resources: SeriesModel[]): Observable<any> {
        const subject = new Subject();
        return this.openSplitMergeDialog('merge', resources, 'explorer.tools.merge_series_title').pipe(
            tap(() => this._generalPurpose.openProgressDialog(subject)),
            mergeMap(res => this.mergeSeries(res.mainResource, res.resources, res.keepSource)),
            mergeMap(res => this.publishChanges(res)),
            tap(() => subject.complete()),
            catchError(err => this._catchThrowErr(err, subject))
        );
    }

    public splitSeriesDialog(resources: InstanceModel[]): Observable<any> {
        const subject = new Subject();
        return this.openSplitMergeDialog('split', resources, 'explorer.tools.split_series_title', 'series').pipe(
            tap(() => this._generalPurpose.openProgressDialog(subject)),
            mergeMap(res => this.splitSeries(res.resources, res.keepSource)),
            mergeMap(res => this.publishChanges(res)),
            tap(() => subject.complete()),
            catchError(err => this._catchThrowErr(err, subject))
        );
    }

    // resourcesUid can be studies, series, and/or instances; in this case resourcesUid = a list of series of one study
    public mergeStudies(uid: string, resourcesUid: string[], keepSource: boolean): Observable<any> {
        return this._http.post(`${this.getStudyIdUrl(uid)}/merge`, {Resources: resourcesUid, KeepSource: keepSource});
    }

    // split a study by merging a list of series
    public splitStudies(uid: string, seriesUid: string[], keepSource: boolean): Observable<any> {
        return this._http.post(`${this.getStudyIdUrl(uid)}/split`, {Series: seriesUid, KeepSource: keepSource});
    }

    // resourcesUid can be series, and/or instances; in this case resourcesUid = a list of instances of one series
    // move resourcesUid to a principal series that has seriesInstanceUID
    public mergeSeries(seriesInstanceUID: string, resourcesUid: string[], keepSource: boolean): Observable<any> {
        return this._http.post(`/dcm/tools/bulk-modify`,
            {
                Resources: resourcesUid,
                KeepSource: keepSource,
                Level: 'Series',
                Replace: {
                    SeriesInstanceUID: seriesInstanceUID
                },
                Force: true
            }
        );
    }

    // resourcesUid is the list of instances (of one series) that will be split into a new series
    public splitSeries(resourcesUid: string[], keepSource: boolean): Observable<any> {
        return this._http.post(`/dcm/tools/bulk-modify`,
            {
                Resources: resourcesUid,
                KeepSource: keepSource,
                Level: 'Series'
            }
        );
    }

    public afterClosedDialog(dialogRef: MatDialogRef<any>): Observable<any> {
        return dialogRef.afterClosed()
            .pipe(
                mergeMap(value => value ? of(value) : throwError(value))
            );
    }

    public openSelectModalityDialog(modalities: any, resources: any): Observable<any> {
        const dialogRef = this._dialog.open(ModalitiesSelectionDialog, assign(SMALL_DIALOG, {
            disableClose: true,
            data: {modalities, resources}
        }));

        return this.afterClosedDialog(dialogRef);
    }

    public sendToModalitiesDialog(resources: string[]): Observable<any> {
        return this._settingService.getModalities().pipe(
            mergeMap(modalities => this.openSelectModalityDialog(modalities, resources)),
            mergeMap(res => this.sendResourcesToModalities(res.remoteModalities, resources))
        );
    }

    public sendResourcesToModalities(remoteModalities: any[], resourcesUid: string[]): Observable<any> {
        const observeGroup: Observable<any>[] = [];
        remoteModalities.map(modality => observeGroup.push(this.sendResourcesToOneModality(modality.AET, resourcesUid)));
        return forkJoin(observeGroup);
    }

    public sendResourcesToOneModality(remoteAet: any, resourcesUid: string[]): Observable<any> {
        const subject = new Subject();
        this.openProgressStoreDialog(subject, 'explorer.modality_tools.sentToModalityMessage', {title: remoteAet});
        return this.sendResourcesToModality(remoteAet, resourcesUid).pipe(
            tap(() => subject.complete()),
            catchError(err => {
                subject.complete();
                return of(err); // throwError(err);
            })
        );
    }

    public openProgressStoreDialog(request, msg: string, msgParams = {}) {
        this._dialog.open(ProgressStoreDialog, {
            data: {observable: request, message: msg, messageParams: msgParams},
            // width: '40%',
            // minWidth: '500px',
            disableClose: true
            // panelClass: 'ft-download-progress-dialog'
        });
    }

    public sendResourcesToModality(remoteAet: any, resourcesUid: string[]): Observable<any> {
        return this._http.post(`/dcm/modalities/${remoteAet}/store`,
            {
                Resources: resourcesUid,
                Timeout: 10
            }
        );
    }

    public studySeries(uid): Observable<SeriesModel[]> {
        return this._http.get(`${this.getStudyIdUrl(uid)}/series`)
            .pipe(
                map((data) => this._seriesMap(data) as any)
            );
    }

    public updateStudyTags(study: StudyModel, Replace: any) {
        return this._http.post(`${this.getStudyIdUrl(study.ID)}/modify`, {Replace, KeepPrivateTags: true})
            .pipe(
                mergeMap(() => this._deleteItemCall(study, 'studies'))
            );
    }

    // series related
    public seriesInstances(uid): Observable<InstanceModel[]> {
        return forkJoin([
            this._http.get(`/dcm/series/${uid}/instances`),
            this._http.get(`/dcm/series/${uid}/instances-tags?simplify`)
        ]).pipe(
            map((data) => this._instancesMap(data))
        );
    }

    public getSeries(uid): Observable<SeriesModel> {
        return this._http.get(`/dcm/series/${uid}`)
            .pipe(
                map((data) => this._seriesItemMap(data))
            );
    }

    // instance tags
    public instanceTags(uid) {
        const url = `/dcm/instances/${uid}/tags`;
        return this._http.get(url)
            .pipe(
                map((data) => this._tagsMap(data))
            );
    }

    public downloadMedia(study: StudyModel): Observable<any> {
        return this._downloadInstance(study, 'studies');
    }

    public anonymize(item, type, label, value?) {
        return this._generalPurpose.openConfirmDialog(label, value)
            .pipe(
                mergeMap(result => result ? this._anonymizeItem(item, type) : of(false))
            );
    }

    public deleteItem(item, type, label, value?) {
        return this._generalPurpose.openConfirmDialog(label, value)
            .pipe(
                mergeMap(result => result ? this._deleteItemCall(item, type) : of(false)),
                mergeMap(res => this.publishChanges(res))
            );
    }

    public deleteStudyReport(item) {
        return this._generalPurpose.openConfirmDialog('explorer.report.removeStudyReportMsg')
            .pipe(
                mergeMap(result => result ? this._deleteStudyReportItemCall(item) : of(false))
            );
    }

    // ws
    public publishChanges(data: any): Observable<any> {
        return this._generalPurpose.getByEvent('explorer.publish_changes', data);
    }

    public listenNotifyChanges(): Observable<any> {
        return this._ws.observe('explorer.changes_notify');
    }

    // burner related
    public sendToRobot(item: StudyModel) {
        return this._generalPurpose.getByHttp('/api/burner-robot/burner-config/')
            .pipe(
                mergeMap(result => result.length === 0 ? of(result[0]) : this._openRobotSelection(result, item)),
                mergeMap(result => result ? this._sendToSpecificRobot(item, result) : of(false))
            );
    }

    // print related stuff
    public printStudy(study: StudyModel) {
        this._previewDialog.open(PrintContainerComponent, DCM_PRINT_DIALOG_DATA, {study: study.ID, type: 'study'});
    }

    public printReportCups(reportId: any, options: PrintOption) {
        return this._http.post(`${this.reportUrl}${reportId}/print-report-cups/`,
            {
                printer: options.Printer.title,
                copy: options.NumberOfCopies
            }
        );
    }

    public printReport(reportId: any) {
        this._previewDialog.open(PrintContainerComponent, DCM_PRINT_DIALOG_DATA, {
            study: reportId,
            type: 'report',
            reportUrl: `${this.reportUrl}${reportId}/pdf-report/`
        });
    }

    public printBookletView(reportId: any, context: any) {
        this._previewDialog.open(PrintContainerComponent, DCM_PRINT_DIALOG_DATA, {
            study: reportId,
            type: 'study',
            isBooklet: true,
            reportUrl: `${this.reportUrl}${reportId}/pdf-report/`,
            context
        } as PrintConfig);
    }

    public downloadReport(studyID: string): Observable<any> {
        const reportUrl = `${this.reportUrl}${studyID}/download-report/`;
        return this._generalPurpose.download(reportUrl, `${studyID}.docx`);
    }

    // explorer search related
    public loadUiConfig(): Observable<boolean> {
        return this._generalConfig.getOwnerConfig('explorer_config')
            .pipe(
                tap(config => {
                    this.explorerConfig = assign({}, {
                        availableModalities: config?.availableModalities || [],
                        selectedModalities: config?.selectedModalities || [],
                        defaultDuration: config?.defaultDuration || null,
                        selectedInstitutions: config?.selectedInstitutions || []
                    });

                    // Nb: if user has no config; it return the config of root, so the user doesn't have the authorization of all sites
                    const institutions = this.explorerConfig.selectedInstitutions.map(inst => inst.key);
                    const authorizedSites = this._settingService.authorizedSites.map((site: any) => site.id).concat('');
                    const explorerPrefInstitutions = institutions.every(ele => authorizedSites.includes(ele));

                    this.explorerConfig.selectedInstitutions = explorerPrefInstitutions ? this.explorerConfig.selectedInstitutions : [];

                }),
                map(() => true)
            );
    }

    // tslint:disable-next-line:variable-name
    public saveUiConfig(explorerConfig: ExplorerConfig): Observable<boolean> {
        return this._generalConfig.setOwnerConfig({explorer_config: explorerConfig})
            .pipe(
                tap(() => {
                    this.explorerConfig = explorerConfig;
                    localStorage.removeItem(STUDY_QUERY_STORED);
                })
            );
    }

    public showStoreResponseSnackBar(response: any[]) {
        let resultMsg = '';
        response.map(
            value => {
                if (value.FailedInstancesCount === 0) {
                    resultMsg += `${value.RemoteAet} : ✓ ; `;
                } else {
                    const urlAet = trimEnd(value.url, '/store');
                    const aet = split(urlAet, 'modalities/')[1];
                    resultMsg += `${aet} : ✗ ; `;
                }
            }
        );

        this.showSnackBar(resultMsg);
    }

    public showSnackBar(msg: string) {
        this._snackBar.open(this._translateService.instant(msg));
    }

    public removeSelectedTableRowClass(table): void {
        loMap(table.innerTable.nativeElement.children[0].children,
            row => row.classList.contains('ft-row-selected') ? row.classList.remove('ft-row-selected') : null);
    }

    public handleCheckedTableRow(checkedRows, item: StudyModel | SeriesModel): void {
        if (!checkedRows.includes(item)) {
            checkedRows.push(item);
        } else {
            const i = checkedRows.indexOf(item);
            checkedRows.splice(i, 1);
        }
    }

    public getLastQueryStored(key: KEY_QUERY_STORED): any {
        const lastQuery = localStorage.getItem(key);
        return ((lastQuery ? JSON.parse(lastQuery) : {}) as StudyQuery);
    }

    public saveQuery(key: KEY_QUERY_STORED, query: StudyQuery) {
        const qr = JSON.stringify(query);
        localStorage.setItem(key, qr);
    }

    public handleSearchByLastStoredQuery(query: StudyQuery, institutions: any[]) {
        if (query.RangeAction) this.resetDateRange(query.RangeAction, query);

        if (isEmpty(query)) {
            this.resetDateRange(this.explorerConfig.defaultDuration, query);

            assign(institutions, this.initiateSelectedInstitutionSearchList(institutions, query));

        } else {
            const storedSiteIsAuthorized = query.InstitutionName?.every(
                ele => this._settingService.authorizedSitesNames.concat('\\').includes(ele));
            if (!storedSiteIsAuthorized) {
                assign(institutions, this.initiateSelectedInstitutionSearchList(institutions, query));
            } else {
                assign(institutions, query.InstitutionName);
            }
        }
    }

    public isDateSelected(action: DateAction, query: StudyQuery) {
        if (query.RangeAction) {
            return isEqual(action.meta, query.RangeAction.meta);
        }
    }

    public initiateSelectedInstitutionSearchList(institutions, query: StudyQuery): any[] {
        if (size(this.explorerConfig.selectedInstitutions) > 0) {
            institutions = this.explorerConfig.selectedInstitutions;
        } else {
            institutions = [];
            if (this._settingService.localSite) {
                institutions.push(
                    this.siteFilterOptions.find(site => site.label === this._settingService.localSite.name));
            } else {
                institutions = this.siteFilterOptions;
            }
        }
        this.handleSelectedInstitutionQuery(institutions, query);
        return institutions;
    }

    public handleSelectedInstitutionQuery(institutions, query: StudyQuery) {
        query.InstitutionName = loMap(institutions, institution => {
                return !isEmpty(`${institution.key}`) ? institution.label : institution.value;
            }
        );
    }

    // get all authorized institutions name;
    // because if we remove InstitutionName from the query then we will get all the studies that the user is authorized or not
    public resetInstitutionName() {
        return this._settingService.authorizedSites.map((site: any) => site.name).concat('\\');
    }

    public resetDateRange(action: DateAction, query: StudyQuery) {
        query.RangeAction = action;

        if (action && action.meta) {
            query.StudyDateEnd = this._adapter.today();
            query.StudyDateStart = this._adapter.today().add(action.meta);
        } else {
            query.StudyDateEnd = null;
            query.StudyDateStart = null;
        }
    }

    private _catchThrowErr(error, subject): Observable<any> {
        subject.complete();
        return throwError(error);
    }

    private _findSharedStudies(Query): Observable<any> {
        // when search includes other institution names, we concat search with shared studies
        // (Shared studies = all studies contain an unknown InstitutionName[Obtained from upload] +
        // studies whose InstitutionName is empty or does not exist)
        const url = '/dcm/tools/find';
        const qr1 = Object.assign({}, this._baseStudiesQuery, {Query});
        const qr2 = cloneDeep(qr1);
        delete qr1.Query.InstitutionName;  // or qr1.Query.InstitutionName = '';
        qr2.Query.InstitutionName = this._settingService.availableSites.map(site => site.name).join('\\');

        return forkJoin([
            this._http.post(url, qr1),
            this._http.post(url, qr2)
        ]).pipe(
            map(data => {
                const allStudies = data[0];
                const availableStudies = data[1];
                return differenceBy(allStudies, availableStudies, 'ID');
            })
        );
    }

    private _findStudies(Query): Observable<any> {
        if (Query.InstitutionName) {
            const institutionName = this.strQueryToArray(Query.InstitutionName);
            const searchedInstitution = compact(institutionName).join('\\');

            if (includes(institutionName, '')) {
                if (isEmpty(searchedInstitution)) {
                    return this._findSharedStudies(Query);
                } else {
                    const qr = cloneDeep(Query);
                    qr.InstitutionName = searchedInstitution;
                    return forkJoin([
                        this._http.post('/dcm/tools/find', Object.assign(this._baseStudiesQuery, {Query: qr})),
                        this._findSharedStudies(Query),
                    ]).pipe(
                        map(data => {
                            const searchSiteStudies = data[0];
                            const otherStudies = data[1];
                            return concat(searchSiteStudies, otherStudies);
                        })
                    );
                }
            } else {
                const qr = cloneDeep(Query);
                qr.InstitutionName = searchedInstitution;
                return this._http.post('/dcm/tools/find', Object.assign(this._baseStudiesQuery, {Query: qr}));
            }
        } else {
            const qr = cloneDeep(Query);
            qr.InstitutionName = this._settingService.authorizedSitesNames.join('\\');
            return forkJoin([
                this._findSharedStudies(Query),
                this._http.post('/dcm/tools/find', Object.assign(this._baseStudiesQuery, {Query: qr})),

            ]).pipe(
                map(data => {
                    return concat(data[0], data[1]);
                })
            );
        }
    }

    private _openRobotSelection(robots, study: StudyModel) {
        const selectConfig: SelectConfig = {key: 'title', autoSelect: true, observable: of(robots).pipe(delay(10))};

        const dialogRef = this._dialog.open(RobotSelectionDialog, assign(SMALL_DIALOG, {
            autoFocus: false,
            data: {selectConfig, study}
        }));

        return this.afterClosedDialog(dialogRef);
    }

    private _sendToSpecificRobot(study, data): Observable<any> {
        return this._http.post(`/api/burner-robot/burner-config/generate-job/`, {
            study: study.ID, robot: data.robot.id,
            series: loMap(data.series, 'ID')
        });
    }

    // general private api
    private _anonymizeItem(item, type) {
        const url = `/dcm/${type}/${item.ID}/anonymize`;
        return this._http.post(url, {});
    }

    private _deleteItemCall(item, type) {
        const url = `/dcm/${type}/${item.ID}`;
        return this._http.delete(url)
            .pipe(
                map(() => true)
            );
    }

    private _handleStudyDate(query: StudyQuery) {
        const StudyDateEnd = this._convertDate(query, 'StudyDateEnd');
        const StudyDateStart = this._convertDate(query, 'StudyDateStart');

        if (StudyDateEnd && StudyDateStart) {
            return `${StudyDateStart}-${StudyDateEnd}`;
        } else if (StudyDateEnd) {
            return `-${StudyDateEnd}`;
        } else if (StudyDateStart) {
            return `${StudyDateStart}-`;
        } else {
            return null;
        }
    }

    private _convertDate(query: StudyQuery, key: string) {
        const date = get(query, key, null);

        if (this._adapter.isDateInstance(date)) {
            return date.format('YYYYMMDD');
        } else if (isString(date)) {
            return this._adapter.parse(date, NAIVE_DATE_FORMAT).format('YYYYMMDD');
        } else {
            return false;
        }
    }

    private _deleteStudyReportItemCall(item) {
        return this._http.delete(`${this.reportUrl}${item.ID}/delete/`)
            .pipe(
                map(() => true)
            );
    }

    private _studiesMap(items, reports?) {
        return chain(items as any)
            .map(item => this._studyItemMap(item, reports))
            .orderBy('StudyDateTime', 'desc')
            .value();
    }

    private _patientItemMap(item) {
        const itemSex = get(item, 'MainDicomTags.PatientSex');
        const MediaName = `${get(item, 'MainDicomTags.PatientName')}.zip`;

        return chain(item)
            .get('MainDicomTags')
            .assign({ID: item.ID, PatientStudies: item.Studies.length, MediaName})
            .assign(itemSex === 'M' ? {PatientSexIcon: 'mdi-human-male'} : {})
            .assign(itemSex === 'F' ? {PatientSexIcon: 'mdi-human-female'} : {})
            .value();
    }

    private _studyItemMap(item, reports?) {
        const itemSex = get(item, 'PatientMainDicomTags.PatientSex');
        const studyDate = chain(item).get('MainDicomTags.StudyDate').thru((s) => isEmpty(s) ? 'Date' : s).value();
        const studyDescription = chain(item).get('MainDicomTags.StudyDescription').thru((s) => isEmpty(s) ? 'Description' : s).value();
        const MediaName = `${get(item, 'PatientMainDicomTags.PatientName')} - ${studyDescription} - ${studyDate}.zip`;
        const study = chain(item)
            .get('MainDicomTags')
            .assign(
                {
                    ID: item.ID,
                    StudySeries: item.Series.length,
                    ParentPatient: item.ParentPatient, MediaName
                },
                itemSex === 'M' ? {PatientSexIcon: 'mdi-human-male'} : {},
                itemSex === 'F' ? {PatientSexIcon: 'mdi-human-female'} : {},
                get(item, 'PatientMainDicomTags')
            )
            .mapValues((elem, key) => {
                if (key === 'StudyDate' && elem) {
                    return elem.split('.').join('');
                } else {
                    return elem;
                }
            })
            .thru((elem: any) => {
                const datetime = this._adapter.parse(`${elem.StudyDate}T${elem.StudyTime}`, 'YYYYMMDDTHHmm');
                return assign(elem, {StudyDateTime: datetime.isValid() ? datetime.valueOf() : -1});
            })
            .value();
        return reports ? this._studyReportItemMap(study, reports) : study;
    }

    private _seriesMap(items): SeriesModel {
        return chain(items as any)
            .map((item) => this._seriesItemMap(item))
            .orderBy('SeriesNumber')
            .value();
    }

    private _seriesItemMap(item): SeriesModel {
        const SeriesNumber = chain(item).get('MainDicomTags.SeriesNumber').parseInt().value();

        return chain(item)
            .get('MainDicomTags')
            .assign(
                pick(item, ['ID', 'ParentStudy', 'Status']),
                {SeriesInstances: item.Instances.length, SeriesNumber}
            )
            .mapValues((elem, key) => {
                if (key === 'SeriesDate' && elem) {
                    return elem.split('.').join('');
                } else {
                    return elem;
                }
            })
            .value();
    }

    /*private _modalitiesMap(items) {
        return chain(items[0] as any)
            .map(item => this._modalityItemMap(item, items[1])).value();
    }

    private _modalityItemMap(item, tags) {
        return chain(item)
            .get(tags, tags[item])
            .value();
    }*/

    private _instancesMap(items) {
        return chain(items[0] as any)
            .map(item => this._instanceItemMap(item, items[1]))
            .sortBy(item => parseInt(item.InstanceNumber, 0))
            .value();
    }

    private _instanceItemMap(item, tags) {
        return chain(item)
            .get('MainDicomTags')
            .assign(
                pick(item, ['ID', 'ParentSeries', 'IndexInSeries', 'FileSize']), {SOPClassUID: get(tags, `${item.ID}.SOPClassUID`)}
            )
            .value();
    }

    private _tagsMap(tags) {
        return chain(tags)
            .reduce((accumulator, item, key) => concat(accumulator, assign(item, {Tag: key})), [])
            .filter((item) => item.Type !== 'Sequence')
            .value();
    }


    // download related
    private _downloadInstance(instance: StudyModel | PatientModel, resource) {
        const subject = new Subject();
        this._generalPurpose.openProgressDialog(subject);

        return this._generalPurpose.getByEvent(
            'explorer.download_media', {pk: instance.ID, resource}
        ).pipe(
            tap(() => subject.complete()),
            map(path => `/download/settings/explorer/${btoa(path)}/download-media/`),
            mergeMap(url => this._generalPurpose.download(url, instance.MediaName))
        );
    }
}
