import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginChildComponent} from './components/login-child/login-child.component';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FtAuthModule, FtCoreModule, MaterialModule} from '@ft/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgBusyModule} from 'ng-busy';
import {StaffFormChildDialogComponent} from './dialogs/staff-form-child-dialog/staff-form-child-dialog.component';


@NgModule({
    declarations: [
        LoginChildComponent,
        StaffFormChildDialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        MaterialModule,
        FtCoreModule,
        NgBusyModule,
        TranslateModule.forChild(),
        FtAuthModule,
    ],
    exports: [
        LoginChildComponent,
        StaffFormChildDialogComponent,
    ],
})
export class AuthenticationModule extends FtAuthModule {
}
