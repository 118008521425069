import {animate, AnimationTriggerMetadata, keyframes, style, transition, trigger} from '@angular/animations';


export const UPLOAD_ENTER_STEPS = [
    style({transform: 'translate3d(0, 100%, 0)', opacity: '.5'}),
    animate(`400ms 450ms cubic-bezier(0.35, 0, 0.25, 1)`, keyframes([
        style({transform: 'translate3d(0, 100%, 0)', opacity: '.5', offset: 0}),
        style({transform: 'translate3d(0, 25%, 0)', opacity: '1', offset: .75}),
        style({transform: 'translate3d(0, 0, 0)', opacity: '1', offset: 1}),
    ]))
];

export const UPLOAD_LEAVE_STEPS = [
    style({transform: 'translate3d(0, 0, 0)', opacity: '1'}),
    animate(`400ms cubic-bezier(0.35, 0, 0.25, 1)`, keyframes([
        style({transform: 'translate3d(0, 0, 0)', opacity: '1', offset: 0}),
        style({transform: 'translate3d(0, -25%, 0)', opacity: '.5', offset: .75}),
        style({transform: 'translate3d(0, -100%, 0)', opacity: '0', offset: 1}),
    ]))
];

export const UPLOAD_ITEM_ANIMATION: AnimationTriggerMetadata = trigger(
    'uploadItemTransition',
    [
        transition(':enter', UPLOAD_ENTER_STEPS),
        transition(':leave', UPLOAD_LEAVE_STEPS)
    ]
);
