import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {get} from 'lodash';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SetupConfigService {
    public configs: any = {};

    constructor(private _http: HttpClient) {
    }

    public load(url: string): Observable<any> {
        return this._http.get(url).pipe(
            tap(data => this.configs = data)
        );
    }

    public get sites(): string[] {
        return get(this.configs, 'sites', []);
    }


}
